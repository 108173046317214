import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent, Typography, IconButton, Tooltip, Checkbox, Radio } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import { setScrollLocked, changeCurrentQuizIndex, incrementCurrentTopicScore, appendPrereqTestQuestion, incrementNumberCorrectQuestions, incrementNumberTotalQuestions, setNumberCorrectQuestions, setNumberTotalQuestions } from '../../../store';
import customLog from '../../../customLogger.js';
import FailedScreen from '../FailedScreen';
import PassedScreen from '../PassedScreen';
import MarkdownRenderer from '../../MarkdownRenderer';

const Quiz = ({ 
    quiz, 
    currentQuizIndex, 
    extendQuiz, 
    continueLesson, 
    streamingCards,
    themeData,
    continueButton
}) => {
    const dispatch = useDispatch();
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState(new Set());
    const [isAnswerChecked, setIsAnswerChecked] = useState(false);
    const [isCorrect, setIsCorrect] = useState(null);
    const [buttonState, setButtonState] = useState('check');
    const [userAnswers, setUserAnswers] = useState([]);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [quizPassed, setQuizPassed] = useState(null);

    const guidedLessonModeActive = useSelector((state) => state.chat.guidedLessonModeActive);
    const currentLessonID = useSelector((state) => state.chat.currentLessonID);
    const APIToken = useSelector((state) => state.chat.APIToken);
    const prereqTestModeActive = useSelector((state) => state.chat.prereqTestModeActive);

    const DjangoHost = process.env.REACT_APP_DJANGO_HOST;

    useEffect(() => {
        //dispatch(setScrollLocked(true));
    }, []); // Empty dependency array means this runs once on mount

    // Reset quiz state whenever quiz changes
    useEffect(() => {
        resetQuizState();
    }, [quiz]);

    // Check if multiple correct answers exist
    const hasMultipleCorrect = quiz.choices.filter(choice => choice.is_correct).length > 1;

    const handleAnswer = (answerIndex) => {
        if (!isAnswerChecked) {
            if (hasMultipleCorrect) {
                const newSelectedAnswers = new Set(selectedAnswers);
                if (newSelectedAnswers.has(answerIndex)) {
                    newSelectedAnswers.delete(answerIndex);
                } else {
                    newSelectedAnswers.add(answerIndex);
                }
                setSelectedAnswers(newSelectedAnswers);
            } else {
                setSelectedAnswer(answerIndex);
            }
        }
    };

    const checkAnswer = () => {
        if ((hasMultipleCorrect ? selectedAnswers.size > 0 : selectedAnswer !== null) && buttonState === 'check') {
            const currentQuestion = quiz;
            setIsAnswerChecked(true);
            
            if (hasMultipleCorrect) {
                const correctAnswers = quiz.choices
                    .map((choice, index) => ({ index, isCorrect: choice.is_correct }))
                    .filter(choice => choice.isCorrect)
                    .map(choice => choice.index);
                
                const selectedArray = Array.from(selectedAnswers);
                const isAllCorrect = correctAnswers.every(index => selectedArray.includes(index)) &&
                    selectedArray.every(index => correctAnswers.includes(index));
                
                setIsCorrect(isAllCorrect);
                setButtonState(isAllCorrect ? 'correct' : 'incorrect');
                if (isAllCorrect) {
                    dispatch(incrementNumberCorrectQuestions());
                }
                dispatch(incrementNumberTotalQuestions());
            } else {
                const selectedChoice = currentQuestion.choices[selectedAnswer];

                setIsCorrect(selectedChoice.is_correct);
                setButtonState(selectedChoice.is_correct ? 'correct' : 'incorrect');
                if (selectedChoice.is_correct) {
                    dispatch(incrementNumberCorrectQuestions());
                }
                dispatch(incrementNumberTotalQuestions());
            }
            
            if (guidedLessonModeActive) {
                dispatch(incrementCurrentTopicScore(5));
                if (hasMultipleCorrect) {
                    setUserAnswers(prevAnswers => [
                        ...prevAnswers,
                        ...Array.from(selectedAnswers).map(answerIndex => ({
                            question_id: currentQuestion.id,
                            answer_choice_id: currentQuestion.choices[answerIndex].id
                        }))
                    ]);
                } else {
                    setUserAnswers(prevAnswers => [
                        ...prevAnswers,
                        {
                            question_id: currentQuestion.id,
                            answer_choice_id: currentQuestion.choices[selectedAnswer].id
                        }
                    ]);
                }
            }

            if (prereqTestModeActive) {
                customLog('quiz rendered is ' + JSON.stringify(quiz));
                customLog('answer_choice_id is ' + quiz.choices[selectedAnswer].id);
                const prereqQuestion = {
                    id: quiz.id,
                    answer: quiz.choices[selectedAnswer].id
                };
                dispatch(appendPrereqTestQuestion(prereqQuestion));
            }
            //dispatch(setScrollLocked(false));
        }
    };

    const getAnswerStatus = (index) => {
        if (!isAnswerChecked) return null;
        
        if (hasMultipleCorrect) {
            const isSelected = selectedAnswers.has(index);
            const isCorrectChoice = quiz.choices[index].is_correct;

            if (isSelected && isCorrectChoice) return 'correct';
            if (isSelected && !isCorrectChoice) return 'incorrect';
            if (!isSelected && isCorrectChoice) return 'missed';
            return null;
        } else {
            if (index === selectedAnswer) {
                return quiz.choices[index].is_correct ? 'correct' : 'incorrect';
            }
            if (isAnswerChecked && quiz.choices[index].is_correct) {
                return 'correct';
            }
            return null;
        }
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'correct':
                return <CheckCircleIcon style={{ color: '#93D333', fontSize: '1rem' }} />;
            case 'incorrect':
                return <CancelIcon style={{ color: '#EE5555', fontSize: '1rem' }} />;
            case 'missed':
                return <CheckCircleIcon style={{ color: '#93D333', fontSize: '1rem' }} />;
            default:
                return null;
        }
    };

    const resetQuizState = () => {
        setSelectedAnswer(null);
        setSelectedAnswers(new Set());
        setIsAnswerChecked(false);
        setButtonState('check');
        setIsCorrect(null);
    };

    const navigateQuiz = async (direction) => {
        const newIndex = currentQuizIndex + direction;
        if (newIndex >= 0 && newIndex < quiz.quiz_questions.length) {
            dispatch(changeCurrentQuizIndex(direction));
            resetQuizState();
        } else if (newIndex === quiz.quiz_questions.length && guidedLessonModeActive) {
            //await gradeQuiz();
        }
    };

    const gradeQuiz = async () => {
        const testData = {
            test_id: quiz.id,
            test_type: 1,
            questions: userAnswers,
            lesson_id: currentLessonID
        };
        customLog('NAVIGATEQUIZ GRADEQUIZ USER ANSWERS IS ' + JSON.stringify(userAnswers));
        customLog('NAVIGATEQUIZ GRADEQUIZ TEST DATA IS ' + JSON.stringify(testData));
        try {
            const response = await fetch(DjangoHost + "/api/lessons/test/grade/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${APIToken}`,
                },
                body: JSON.stringify(testData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            const passed = result.passed;
            customLog('NAVIGATEQUIZ CONTINUELESSON PASSED IS ' + passed);
            setQuizCompleted(true);
            setQuizPassed(passed);
        } catch (error) {
            console.error("Error grading quiz:", error);
        }
    };

    const retryQuiz = () => {
        dispatch(changeCurrentQuizIndex(-currentQuizIndex));
        setQuizCompleted(false);
        setQuizPassed(null);
        setUserAnswers([]);
        resetQuizState();
    };

    const iconButtonStyle = {
        margin: '0 2px',
        color: themeData.color5,
        backgroundColor: themeData.color2,
        padding: '4px',
        minWidth: '28px',
        width: '28px',
        height: '28px',
    };

    const answerButtonsContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        marginTop: '5px',
        maxWidth: '100%',
        padding: '0 10px'
    };

    const answerButtonStyle = (index) => {
        const isSelected = hasMultipleCorrect ? selectedAnswers.has(index) : selectedAnswer === index;
        const status = getAnswerStatus(index);
        
        return {
            width: '100%',
            minHeight: '50px',
            background: isSelected ? '#202E36' : themeData.color11,
            color: isSelected ? '#3F85A7' : themeData.color5,
            margin: '3px 0',
            borderRadius: '12px',
            padding: '8px',
            textAlign: 'left',
            cursor: 'pointer',
            overflowY: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxShadow: isSelected 
                ? `0 2px 4px ${status === 'correct' ? '#93D33380' : status === 'incorrect' ? '#EE555580' : '#3F85A780'}`
                : 'none',
            transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
            fontSize: '0.9rem'
        };
    };

    const checkButtonStyle = {
        backgroundColor: 'transparent',
        color: themeData.color5,
        padding: '8px',
        borderRadius: '12px',
        cursor: (hasMultipleCorrect ? selectedAnswers.size === 0 : selectedAnswer === null) ? 'not-allowed' : 'pointer',
        opacity: (hasMultipleCorrect ? selectedAnswers.size === 0 : selectedAnswer === null) ? 0.5 : 1,
        border: '0.5px solid #737164',
        background: 'transparent',
        boxShadow: '0 1px 2px rgba(0,0,0,0.12)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        minWidth: '80px',
        marginLeft: 'auto',
        display: isAnswerChecked ? 'none' : 'block',
        fontSize: '0.8rem'
    };

    if (quizCompleted) {
        if (quizPassed) {
            return <PassedScreen onContinue={continueLesson} themeData={themeData} />;
        } else {
            return <FailedScreen onRetry={retryQuiz} themeData={themeData} />;
        }
    }

    return (
        <>
            <Card style={{
                cursor: 'default',
                width: '100%',
                minHeight: '100px',
                background: 'transparent',
                color: themeData.color5,
                borderRadius: '12px',
                marginBottom: '10px',
                marginTop: '10px',
                overflowY: 'auto',
                boxShadow: 'none',
                alignSelf: 'right',
                maxWidth: '100%',
                pointerEvents: 'none',
                padding: '0 10px'
            }}>
                <CardContent style={{ padding: '12px' }}>
                    <MarkdownRenderer content={"### " + quiz.question} />
                    {hasMultipleCorrect && (
                        <Typography variant="subtitle2" style={{color: themeData.color5, marginTop: '5px', fontSize: '0.8rem'}}>
                            (Select all that apply)
                        </Typography>
                    )}
                </CardContent>
            </Card>
            <div style={answerButtonsContainerStyle}>
                {quiz.choices.map((choice, index) => (
                    <button
                        key={index}
                        style={answerButtonStyle(index)}
                        onClick={() => handleAnswer(index)}
                        disabled={isAnswerChecked}
                    >
                        <div style={{display: 'flex', alignItems: 'center', gap: '5px', flex: 1}}>
                            {hasMultipleCorrect ? (
                                <Checkbox 
                                    checked={selectedAnswers.has(index)}
                                    disabled={isAnswerChecked}
                                    style={{color: themeData.color5, padding: '4px'}}
                                    size="small"
                                />
                            ) : (
                                <Radio
                                    checked={selectedAnswer === index}
                                    disabled={isAnswerChecked}
                                    style={{color: themeData.color5, padding: '4px'}}
                                    size="small"
                                />
                            )}
                            <MarkdownRenderer content={choice.body} />
                        </div>
                        {getStatusIcon(getAnswerStatus(index))}
                    </button>
                ))}
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '8px'}}>
                    {!guidedLessonModeActive && (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, marginLeft: '60px'}}>
                            <Tooltip title="Previous">
                                <IconButton 
                                    onClick={() => navigateQuiz(-1)} 
                                    disabled={currentQuizIndex === 0}
                                    style={iconButtonStyle}
                                    size="small"
                                >
                                    <ArrowLeftIcon style={{fontSize: '0.9rem'}} />
                                </IconButton>
                            </Tooltip>
                            <span style={{ margin: '0 5px', color: 'black', fontSize: '0.8rem' }}>
                                {currentQuizIndex + 1} / {quiz.quiz_questions.length}
                            </span>
                            {currentQuizIndex === quiz.quiz_questions.length - 1 && !streamingCards ? (
                                <Tooltip title="Generate 10 more questions">
                                    <IconButton 
                                        onClick={() => {
                                            extendQuiz({quizGroupID: quiz.id});
                                            resetQuizState();
                                        }}
                                        style={iconButtonStyle}
                                        size="small"
                                    >
                                        <span style={{ fontSize: '1.2em' }}>+</span>
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Next">
                                    <IconButton 
                                        onClick={() => navigateQuiz(1)} 
                                        disabled={currentQuizIndex === quiz.quiz_questions.length - 1}
                                        style={iconButtonStyle}
                                        size="small"
                                    >
                                        <ArrowRightIcon style={{fontSize: '0.9rem'}} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    )}
                    <button
                        onClick={checkAnswer}
                        disabled={(hasMultipleCorrect ? selectedAnswers.size === 0 : selectedAnswer === null)}
                        style={checkButtonStyle}
                    >
                        Check
                    </button>
                </div>
            </div>
        </>
    );
};

export default Quiz;
