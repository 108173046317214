import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent, Typography, IconButton, Tooltip, CircularProgress, Box, Button, Dialog, DialogContent } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { clearPreviousTopic, setScrollLocked, setPrereqTestModeActive, removeCurrentLessonGroup, changeCurrentQuizIndex, changeCurrentFlashcardIndex,
    incrementCurrentTopicScore, setSelectedCenterResourceType, setMessages, getTopicContent,
setCurrentTopicIndex, setPrereqTestOptionScreenOpen, setOnHomeScreen, setCenterPanelVisible, setTopicIndexOfLesson } from '../store';
import Quiz from './resources/Quiz';
import PreviousMessages from './ChatMessages/prevMessages';
import StreamingMessage from './ChatMessages/streamingMessage';
import InputForm from './InputForm/inputForm';
import customLog from '../customLogger';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LogoutIcon from '@mui/icons-material/Logout';
import CompleteScreen from './resources/CompleteScreen';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ExploreIcon from '@mui/icons-material/Explore';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SwipeableContentCard from './SwipeableContentCard/SwipeableContentCard.js';

const CenteredResourceView = ({
    sendOrCreateChatMessageStreaming, 
    extendQuiz, 
    extendFlashcards,
    lessonCreationContent,
    createLesson,
    createPrereqQuiz,
    continueLesson,
    previousPage,
    messages,
    streamedResponse,
    username,
    platoImage,
    stickman,
    onLinkClick,
    onLessonApproved,
    onLessonEdited,
    onLessonContinue,
    //isStreaming,
    handleSend,
    handleFileChange,
    handleAbort,
    isMobile
}) => {
    const dispatch = useDispatch();
    const themeData = useSelector((state) => state.chat.themeData);
    const currentFlashcardGroup = useSelector((state) => state.chat.currentFlashcardGroup);
    const currentQuizGroup = useSelector((state) => state.chat.currentQuizGroup);
    const currentFlashcardIndex = useSelector((state) => state.chat.currentFlashcardIndex);
    const currentQuizIndex = useSelector((state) => state.chat.currentQuizIndex);
    const streamingCards = useSelector((state) => state.chat.streamingCards);
    const contextFileLoading = useSelector((state) => state.chat.contextFileLoading);
    const selectedResourceType = useSelector((state) => state.chat.selectedResourceType);
    const selectedCenterResourceType = useSelector((state) => state.chat.selectedCenterResourceType);
    const guidedLessonModeActive = useSelector((state) => state.chat.guidedLessonModeActive);
    const currentLessonGroup = useSelector((state) => state.chat.currentLessonGroup);
    const [showAnswer, setShowAnswer] = useState(false);
    const centerPanelLoading = useSelector((state) => state.chat.centerPanelLoading);
    const currentTopicObject = useSelector((state) => state.chat.currentTopicObject);
    const currentTopicIndex = useSelector((state) => state.chat.currentTopicIndex);
    const currentDisplayedTopicContent = useSelector((state) => state.chat.currentDisplayedTopicContent);
    const topicContentLoading = useSelector((state) => state.chat.topicContentLoading);
    const prereqTestOptionScreenOpen = useSelector((state) => state.chat.prereqTestOptionScreenOpen);
    const isStreaming = useSelector((state) => state.chat.isStreaming);
    const APIToken = useSelector((state) => state.chat.APIToken);
    const DjangoHost = process.env.REACT_APP_DJANGO_HOST;
    customLog('CENTEREDRESOURCEVIEW RENDERING with streaming message of ' + streamedResponse);
    customLog('CENTEREDRESOURCEVIEW RENDERING with currentDisplayedTopicContent of ' + JSON.stringify(currentDisplayedTopicContent));

    const getStars = (topic) => {
        // Mock star rating - in real app would come from topic progress/score
        const rating = topic.stars; // 0-3 stars for demo
        return (
            <Box sx={{ 
                position: 'absolute',
                top: '-20px',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                justifyContent: 'center',
                gap: '2px',
                flexWrap: 'wrap',
                width: '90px', // Wider than the button to allow wrapping
                mb: 1
            }}>
                {[...Array(3)].map((_, i) => (
                    <Box key={i} sx={{
                        transform: i === 1 ? 'translateY(-5px)' : 'translateY(0)', // Middle star higher
                        transformOrigin: 'bottom center'
                    }}>
                        {i < rating ? (
                            <StarIcon sx={{ color: '#FFD700', fontSize: 20 }} />
                        ) : (
                            <StarBorderIcon sx={{ color: '#FFD700', fontSize: 20 }} />
                        )}
                    </Box>
                ))}
            </Box>
        );
    };
    const LessonBubble = ({ topic, isLast, index }) => {
        const shiftPattern = [0, 32, 64, 32, 0, -32, -64, -96, -64, -32, 0, 32, 64, 96, 64, 32];
        const shiftIndex = index % shiftPattern.length;
        const marginLeft = `${shiftPattern[shiftIndex]}px`;
        const marginRight = `${-shiftPattern[shiftIndex]}px`;
        return (
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft, marginRight, mt: '40px' }}>
                {getStars(topic)}
                <Button
                    onClick={() => handleTopicSelect(topic, index)}
                    disabled={topic.locked} 
                    sx={{
                        width: 64,
                        height: 64,
                        borderRadius: '50%',
                        backgroundColor: topic.locked ? '#ccc' : '#93D333',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: `0 4px 8px rgba(0,0,0,0.3), 
                                  inset 0 -4px 4px rgba(0,0,0,0.2),
                                  inset 0 4px 4px rgba(255,255,255,0.2)`,
                        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                        transform: 'perspective(1000px) rotateX(10deg)',
                        '&:hover': {
                            transform: topic.locked ? 
                                'perspective(1000px) rotateX(10deg)' : 
                                'perspective(1000px) rotateX(10deg) scale(1.05) translateY(-5px)',
                            backgroundColor: topic.locked ? '#ccc' : '#7fb82b',
                            boxShadow: `0 8px 16px rgba(0,0,0,0.3),
                                      inset 0 -4px 4px rgba(0,0,0,0.2),
                                      inset 0 4px 4px rgba(255,255,255,0.2)`
                        },
                        '&:active': {
                            transform: topic.locked ?
                                'perspective(1000px) rotateX(10deg)' :
                                'perspective(1000px) rotateX(10deg) scale(0.95) translateY(2px)',
                            boxShadow: `0 2px 4px rgba(0,0,0,0.3),
                                      inset 0 -2px 2px rgba(0,0,0,0.2),
                                      inset 0 2px 2px rgba(255,255,255,0.2)`
                        },
                        opacity: topic.locked ? 0.8 : 1,
                        cursor: topic.locked ? 'not-allowed' : 'pointer',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textShadow: '0 2px 4px rgba(0,0,0,0.2)'
                    }}
                >
                    {index + 1}
                </Button>
                <Typography variant="body2" sx={{ 
                    marginTop: '8px', 
                    color: topic.locked ? themeData.color5 : themeData.color1,
                    textShadow: '0 1px 2px rgba(0,0,0,0.1)',
                    maxWidth: '250px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>
                    {topic.name}
                </Typography>
            </Box>
        );
    };
    const handleTopicSelect = (topic, index) => {
        //dispatch(setSelectedCenterResourceType('messages'));
        dispatch(setCurrentTopicIndex(0));
        dispatch(clearPreviousTopic());
        dispatch(setTopicIndexOfLesson(index));
        dispatch(getTopicContent({topicId: topic.id, topicIndex: index, currentDisplayedTopicIndex: 0}));
        //customLog('HANDLETOPICSELECT MESSAGES BEING SET IS ' + topic.conversation.messages);
        // if (currentTopic && topic.conversation.messages) {
        //     customLog('HANDLETOPICSELECT MESSAGES BEING SET IS ' + topic.conversation.messages);
        //     dispatch(getTopicContent(topic.id));
        //     //dispatch(setMessages(topic.conversation.messages));
        // } else {
        //     dispatch(setMessages([]));
        // }
        //sendOrCreateChatMessageStreaming({lessonID: currentLessonGroup.id, message: '', guidedLessonModeActive: true});
        if (!topic.locked) {
            // Logic to start the topic
            console.log(`Starting topic: ${topic.name}`);
        }
    };

    const centerPanelStyle = {
        position: 'fixed',
        top: '80px', // Changed from 0px to 100px
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        height: 'calc(100% - 80px)', // Adjusted height to account for top spacing
        backgroundColor: 'transparent',
        borderRadius: '0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0px',
        zIndex: 1000,
        overflowY: 'auto',
    };

    const continueButtonStyle = {
        backgroundColor: '#93D333',
        color: 'white',
        padding: '10px',
        borderRadius: '18px',
        cursor: 'pointer',
        border: 'none',
        boxShadow: `0 4px 8px rgba(0,0,0,0.3), 
                    inset 0 -4px 4px rgba(0,0,0,0.2),
                    inset 0 4px 4px rgba(255,255,255,0.2)`,
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        transform: 'perspective(1000px) rotateX(10deg)',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        minWidth: '150px',
        marginTop: '20px',
        marginBottom: '50px',
        '&:hover': {
            backgroundColor: '#7fb82b',
            transform: 'perspective(1000px) rotateX(10deg) scale(1.05) translateY(-2px)',
            boxShadow: `0 8px 16px rgba(0,0,0,0.3),
                        inset 0 -4px 4px rgba(0,0,0,0.2),
                        inset 0 4px 4px rgba(255,255,255,0.2)`
        },
        '&:active': {
            transform: 'perspective(1000px) rotateX(10deg) scale(0.95) translateY(2px)',
            boxShadow: `0 2px 4px rgba(0,0,0,0.3),
                        inset 0 -2px 2px rgba(0,0,0,0.2),
                        inset 0 2px 2px rgba(255,255,255,0.2)`
        }
    };

    const contentStyle = {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: '0px'
    };

    const resourceContainerStyle = {
        width: '100%',
        maxWidth: '1000px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: 'auto',
    };

    const messageViewStyle = {
        width: '100%',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const inputFormStyle = {
        position: 'sticky',
        bottom: 0,
        width: '100%',
        maxWidth: '800px',
        padding: '10px',
        marginTop: 'auto',
    };

    const flashcardStyle = {
        cursor: 'pointer',
        width: '90%',
        minHeight: '200px',
        background: 'linear-gradient(to top, #F3F5EF, #F4F6F2)',
        borderRadius: '18px',
        marginBottom: '20px',
        marginTop: '20px',
        overflowY: 'auto',
        padding: '16px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        alignSelf: 'center',
    };

    const iconButtonStyle = {
        margin: '0 5px',
        color: themeData.color5,
        backgroundColor: themeData.color2,
        padding: '6px',
        minWidth: '36px',
        width: '36px',
        height: '36px',
    };


    const toggleShowAnswer = () => {
        setShowAnswer(!showAnswer);
    };

    const navigateFlashcard = (direction) => {
        const newIndex = currentFlashcardIndex + direction;
        if (currentFlashcardGroup && newIndex >= 0 && newIndex < currentFlashcardGroup.flashcards.length) {
            dispatch(changeCurrentFlashcardIndex(direction));
            setShowAnswer(false);
        }
    };

    const renderContent = () => {
        if (prereqTestOptionScreenOpen) {
            return (
                <Dialog
                    open={prereqTestOptionScreenOpen}
                    PaperProps={{
                        style: {
                            backgroundColor: themeData.color9,
                            borderRadius: '15px',
                            padding: '20px',
                            maxWidth: '500px',
                            width: '90%',
                            marginTop: '100px'
                        }
                    }}
                >
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Button
                                onClick={() => {
                                    createLesson({message: lessonCreationContent});
                                    dispatch(setPrereqTestOptionScreenOpen(false));
                                }}
                                variant="contained"
                                sx={{
                                    textAlign: 'left',
                                    padding: '16px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        boxShadow: '0 0 8px #3F85A7'
                                    }
                                }}
                            >
                                <Box sx={{ width: '40px', marginRight: '16px', display: 'flex', alignItems: 'flex-start' }}>
                                    <MenuBookIcon sx={{ fontSize: 40 }} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Typography variant="subtitle1" sx={{ color: themeData.color1 }}>Start from scratch</Typography>
                                    <Typography variant="caption" sx={{ color: themeData.color5 }}>
                                        Take the easiest lesson
                                    </Typography>
                                </Box>
                            </Button>

                            <Button
                                variant="contained"
                                onClick={() => {
                                    dispatch(setPrereqTestModeActive(true));
                                    dispatch(setPrereqTestOptionScreenOpen(false));
                                    createPrereqQuiz({
                                        APIToken,
                                        DjangoHost,
                                        level: 4,
                                        prompt: lessonCreationContent,
                                        file: null,
                                        fileId: null,
                                        dispatch,
                                        //setStreamingCards
                                    });
                                }}
                                sx={{
                                    textAlign: 'left',
                                    padding: '16px',
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        boxShadow: '0 0 8px #3F85A7'
                                    }
                                }}
                            >
                                <Box sx={{ width: '40px', marginRight: '16px', display: 'flex', alignItems: 'flex-start' }}>
                                    <ExploreIcon sx={{ fontSize: 40 }} />
                                </Box>
                                <Box sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'flex-start'
                                }}>
                                    <Typography variant="subtitle1" sx={{ color: themeData.color1 }}>Find my level</Typography>
                                    <Typography variant="caption" sx={{ color: themeData.color5 }}>
                                        Let Plato recommend where you should start learning
                                    </Typography>
                                </Box>
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            );
        }

        if (centerPanelLoading) {
            return (
                <div style={{ ...contentStyle, justifyContent: 'center' }}>
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginTop: '20px', color: themeData.color1 }}>Loading...</Typography>
                </div>
            );
        }

        if (topicContentLoading) {
            return (
                <div style={{ ...contentStyle, justifyContent: 'center' }}>
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginTop: '20px', color: themeData.color1 }}>Creating Personalized Content...</Typography>
                </div>
            );
        }
        customLog('RENDERCONTENT with CURRENTDISPLAYEDTOPICCONTENT ' + JSON.stringify(currentDisplayedTopicContent));
        customLog('RENDERCONTENT with currentLessonGroup && !currentDisplayedTopicContent of ' + currentLessonGroup && !currentDisplayedTopicContent);
        customLog('RENDERCONTENT with currentLessonGroup of ' + JSON.stringify(currentLessonGroup));
        customLog('RENDERCONTENT with !currentDisplayedTopicContent of ' + !currentDisplayedTopicContent);

        const backgroundImageStyle = currentLessonGroup?.image_url ? {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${currentLessonGroup.image_url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.1,
            zIndex: 0
        } : {};

        if (currentLessonGroup && Object.keys(currentDisplayedTopicContent || {}).length === 0) {
            return (
                <Box sx={{ 
                    marginTop: 0, 
                    paddingTop: '100px',
                    textAlign: 'center', 
                    position: 'relative',
                    minHeight: '100vh',
                    width: '100%',
                }}>
                    <Box sx={backgroundImageStyle} />
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                        position: 'relative',
                        zIndex: 1
                    }}>
                        {currentLessonGroup.topics.map((topic, index) => (
                            <LessonBubble
                                key={topic.id}
                                topic={topic}
                                isLast={index === currentLessonGroup.topics.length - 1}
                                index={index}
                            />
                        ))}
                    </Box>
                </Box>
            );
        }

        if (!currentDisplayedTopicContent) {
            customLog('RENDERCONTENT with !currentDisplayedTopicContent of ' + !currentDisplayedTopicContent);
            return null;
        }

        customLog('RIGHT BEFORE SWIPEABLECONTENTCARD WITH CURRENTDISPLAYEDTOPICCONTENT OF ' + JSON.stringify(currentDisplayedTopicContent));
        return currentDisplayedTopicContent && Object.keys(currentDisplayedTopicContent).length > 0 && (
            <SwipeableContentCard
                content={currentDisplayedTopicContent}
                currentIndex={currentTopicIndex}
                onSwipe={(direction) => {
                    if (direction === 'up') {
                        onLessonContinue();
                    } else if (direction === 'down') {
                        previousPage();
                    }
                }}
                themeData={themeData}
                isMobile={isMobile}
                renderContent={(content) => (
                    <div style={contentStyle}>
                        <div style={{...messageViewStyle, position: 'relative', zIndex: 1}}>
                            {content.type === 'text' && (
                                <>
                                    <PreviousMessages
                                        messages={content.content || content.messages}
                                        username={username}
                                        platoImage={platoImage}
                                        stickman={stickman}
                                        onLinkClick={onLinkClick}
                                        onLessonApproved={onLessonApproved}
                                        onLessonEdited={onLessonEdited}
                                        onLessonContinue={onLessonContinue}
                                    />
                                    {isStreaming && (
                                        <StreamingMessage
                                            message={{'user': 'Assistant', 'text': streamedResponse}}
                                            username={username}
                                            platoImage={platoImage}
                                            stickman={stickman}
                                            onLinkClick={onLinkClick}
                                            onLessonApproved={onLessonApproved}
                                            onLessonEdited={onLessonEdited}
                                            onLessonContinue={onLessonContinue}
                                        />
                                    )}
                                </>
                            )}
                            {content.type === 'quiz' && (
                                <Quiz 
                                    quiz={content.content}
                                    currentQuizIndex={currentQuizIndex}
                                    extendQuiz={extendQuiz}
                                    continueLesson={onLessonContinue}
                                    streamingCards={streamingCards}
                                    themeData={themeData}
                                />
                            )}
                            {content.type === 'flashcards' && renderFlashcards()}
                            {content.type === 'completed' && (
                                <CompleteScreen 
                                    onContinue={onLessonContinue}
                                    themeData={themeData}
                                    createLesson={createLesson}
                                    currentDisplayedTopicContent={currentDisplayedTopicContent}
                                    isMobile={isMobile}
                                />
                            )}
                        </div>
                    </div>
                )}
                onContinue={onLessonContinue}
                isStreaming={isStreaming}
            />
        );
    };

    const renderFlashcards = () => {
        if (!currentDisplayedTopicContent.content || !currentDisplayedTopicContent.content.flashcards || currentDisplayedTopicContent.content.flashcards.length === 0) {
            return <Typography>No flashcards available</Typography>;
        }

        const flashcards = currentDisplayedTopicContent.content.flashcards;

        return (
            <>
                <Card onClick={toggleShowAnswer} style={flashcardStyle}>
                    <CardContent>
                        <Typography variant="h5" component="div" style={{ color: themeData.color5, minHeight: '150px' }}>
                            {showAnswer ? flashcards[currentFlashcardIndex].answer : flashcards[currentFlashcardIndex].question}
                        </Typography>
                    </CardContent>
                </Card>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px' }}>
                    <Tooltip title="Previous">
                        <IconButton 
                            onClick={() => navigateFlashcard(-1)} 
                            disabled={currentFlashcardIndex === 0}
                            style={iconButtonStyle}
                            size="small"
                        >
                            <ArrowLeftIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <span style={{ display: 'flex', alignItems: 'center', margin: '0 10px', color: 'black' }}>
                        {currentFlashcardIndex + 1} / {flashcards.length}
                    </span>
                    {currentFlashcardIndex === flashcards.length - 1 && !streamingCards ? (
                        <Tooltip title="Generate 10 more cards">
                            <IconButton 
                                onClick={() => extendFlashcards({flashcardGroupID: currentDisplayedTopicContent.content.id})}
                                style={iconButtonStyle}
                                size="small"
                            >
                                <span style={{ fontSize: '1.5em' }}>+</span> 
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Next">
                            <IconButton 
                                onClick={() => navigateFlashcard(1)} 
                                disabled={currentFlashcardIndex === flashcards.length - 1}
                                style={iconButtonStyle}
                                size="small"
                            >
                                <ArrowRightIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </>
        );
    };

    return (
        <div style={centerPanelStyle}>
            {contextFileLoading ? <CircularProgress /> : renderContent()}
        </div>
    );
};

export default CenteredResourceView;
