import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
  Tooltip,
  IconButton
} from '@mui/material';
import { loginUserGoogleAPI, setGeneralSnackbarMessage, setGeneralSnackbarOpen, loginUserAPI, registerUserAPI, getInitialUserData, setFakeLoginSignupScreenOpen } from '../../store.js';
import { useNavigate, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { styled } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode';
import GoogleIcon from '@mui/icons-material/Google';
import customLog from '../../customLogger.js';
import googleIcon from '../../images/googleIcon.png'


const StyledTextField = styled(TextField)(({ theme, themeData }) => ({
  backgroundColor: themeData.color3,
  border: `1px solid ${themeData.color1}`,
  borderRadius: '10px',
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      // color: themeData.color4
    },
  },
  '& .MuiFormLabel-root': {
    color: themeData.color5, // Default text color
    '&.Mui-focused': {
      color: themeData.color5 // Text color when the input is focused
    }
  }

}));

const FakeLoginSignupDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['jwt']);
  const themeData = useSelector((state) => state.chat.themeData);
  const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);
  const fakeLoginSignupScreenOpen = useSelector((state) => state.chat.fakeLoginSignupScreenOpen);

  // const [activeTab, setActiveTab] = useState(1);
  const loginSignupTab = 0;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleTabChange = (event, newValue) => {
    // setActiveTab(newValue);
    dispatch(setFakeLoginSignupScreenOpen({fakeLoginSignupScreenOpen: true, loginSignupTab: newValue}));
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    return (
      password.length >= minLength &&
      hasLowercase &&
      hasUppercase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const result = await dispatch(loginUserAPI({userName: email, password, signup: false}));
    if (loginUserAPI.fulfilled.match(result)) {
      const { APIToken } = result.payload;
      setCookie('jwt', APIToken, {path: '/', secure: true, sameSite: 'strict'});
      dispatch(getInitialUserData({random: true}));
      dispatch(setFakeLoginSignupScreenOpen({fakeLoginSignupScreenOpen: false, loginSignupTab: 0}));
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    // Check if email is valid
    if (!validateEmail(email)) {
      dispatch(setGeneralSnackbarMessage("Please enter a valid email address."));
      dispatch(setGeneralSnackbarOpen(true));
      return;
    }

    if (!validatePassword(password)) {
      dispatch(setGeneralSnackbarMessage("Password must be at least 8 characters with an uppercase and lowercase letter, number, and special character."));
      dispatch(setGeneralSnackbarOpen(true));
      return;
    }

    const result = await dispatch(registerUserAPI({
      user_name: email,
      password,
      first_name: "FirstName",
      last_name: "lastName",
      email,
      is_student: true,
      is_individual: true
    }));
    if (registerUserAPI.fulfilled.match(result)) {
      dispatch(setFakeLoginSignupScreenOpen({fakeLoginSignupScreenOpen:false, loginSignupTab: 1}));
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      console.log('Credential response is ' + credentialResponse);
      console.log('Credential response JSON is ' + JSON.stringify(credentialResponse));
      const authorization_code = credentialResponse.credential;
      console.log('Authorization Code:', authorization_code);
  
      // Dispatch action to send authorization code to backend
      const result = await dispatch(loginUserGoogleAPI({ authorization_code, provider: 'google' }));
  
      if (loginUserGoogleAPI.fulfilled.match(result)) {
        const { APIToken } = result.payload;
        setCookie('jwt', APIToken, { path: '/', secure: true, sameSite: 'strict' });
        dispatch(getInitialUserData({ random: true }));
        dispatch(setFakeLoginSignupScreenOpen({ fakeLoginSignupScreenOpen: false, loginSignupTab: 0 }));
      }
    } catch (error) {
      console.error('Google Login Error:', error);
      dispatch(setGeneralSnackbarMessage("Google Login failed. Please try again."));
      dispatch(setGeneralSnackbarOpen(true));
    }
    customLog('handleGoogleSuccess');
  };

  // Define the error handler for Google Login
  const handleGoogleError = () => {
    // console.error('Google Login Failed');
    // dispatch(setGeneralSnackbarMessage("Google Login failed. Please try again."));
    // dispatch(setGeneralSnackbarOpen(true));
    customLog('handleGoogleError reached');
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleError,
    flow: 'auth-code',
    redirect_uri: 'https://myplato.ai/chat',
  });



  return (
    <Dialog
      open={fakeLoginSignupScreenOpen}
      onClose={() => dispatch(setFakeLoginSignupScreenOpen({fakeLoginSignupScreenOpen: false, loginSignupTab: 1}))}
      aria-labelledby="login-signup-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: themeData.color2,
          borderRadius: '20px',
          color: themeData.color5,
          border: '1px solid #000',
          width: '400px',
        },
      }}
    >
      <DialogTitle id="login-signup-dialog-title" sx={{ fontWeight: 'bold' }}>
        Welcome to MyPlato
        <IconButton
          aria-label="close"
          onClick={() => dispatch(setFakeLoginSignupScreenOpen({fakeLoginSignupScreenOpen: false, loginSignupTab: 1}))}
          sx={{ 
            position: 'absolute',
            right: 8,
            top: 8,
            color: themeData.color5 
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Tabs 
            value={loginSignupTab} 
            onChange={handleTabChange} 
            centered
            textColor={themeData.color4}
            indicatorColor={themeData.color4}
            TabIndicatorProps={{
                style: { backgroundColor: themeData.color4, color: themeData.color3 }, // Set indicator color
            }}
            sx={{
                '& .MuiTab-root': {
                    color: themeData.color4, // Default color
                    textTransform: 'none',
                },
                '& .Mui-selected': {
                    color: themeData.color4, // Selected tab color
                },
            }}
            >
          <Tab label="Login" />
          <Tab label="Sign Up" />
        </Tabs>
        <Box sx={{ p: 3 }}>
          {loginSignupTab === 0 && (
            <Box>
            <div className="google-login-container">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                  flow="auth-code"
                  redirectUri="https://myplato.ai/chat"
                  useOneTap
                  text="continue_with"
                  width="300"
                />
              </div>
            <div style={{
              display: 'flex', 
              alignItems: 'center', 
              margin: '20px 0', // Add vertical margin for spacing
            }}>
              <hr style={{ flexGrow: 1, border: 'none', borderTop: '1px solid #ccc' }} />
              <Typography 
                variant="body2" 
                align="center" 
                sx={{ margin: '0 10px' }}  // Spacing on the sides of "OR"
              >
                OR
              </Typography>
              <hr style={{ flexGrow: 1, border: 'none', borderTop: '1px solid #ccc' }} />
            </div>

            <form onSubmit={handleLogin}>
              <StyledTextField
                fullWidth
                margin="normal"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                themeData={themeData}
                InputProps={{
                    disableUnderline: true
                }}
                variant="filled"
              />
              <StyledTextField
                fullWidth
                margin="normal"
                label="Password"
                type="password"
                value={password}
                themeData={themeData}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <Tooltip title="All passwords must be at least 8 characters and contain 1 lowercase letter, 1 uppercase letter, a special character, and a number">
                        <IconButton>
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                }}
                variant="filled"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ 
                  mt: 3, 
                  mb: 2, 
                  backgroundColor: themeData.color3, 
                  color: themeData.color4,
                  border: `1px solid ${themeData.color1}`,
                  textTransform: 'none',
                  '&.Mui-focusVisible': { // Notice how the selector is directly applied
                    backgroundColor: themeData.color3, // This will be the background color on focus
                    color: themeData.color2, // And this will be the text color on focus
                  },
                  '&:hover': {
                    backgroundColor: themeData.color3,
                    color: themeData.color5,
                },
                }}
              >
                Login
              </Button>
              <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', color: themeData.color5 }}>
                By logging in, you accept MyPlato's{' '}
                <Link to="/legal/terms-of-use" style={{ color: themeData.color4, textDecoration: 'none' }}>
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to="/legal/privacy-policy" style={{ color: themeData.color4, textDecoration: 'none' }}>
                  Privacy Policy
                </Link>.
              </Typography>
            </form>
            </Box>
          )}
          {loginSignupTab === 1 && (
            <form onSubmit={handleSignUp}>
              <StyledTextField
                fullWidth
                margin="normal"
                label="Email"
                // type="email"
                value={email}
                themeData={themeData}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                    disableUnderline: true
                }}
                variant="filled"
              />
              <StyledTextField
                fullWidth
                margin="normal"
                label="Password"
                type="password"
                value={password}
                themeData={themeData}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <Tooltip title="All passwords must be at least 8 characters and contain 1 lowercase letter, 1 uppercase letter, a special character, and a number">
                        <IconButton>
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                }}
                variant="filled"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ 
                  mt: 3, 
                  mb: 2, 
                  backgroundColor: themeData.color3, 
                  color: themeData.color4,
                  border: `1px solid ${themeData.color1}`, 
                  textTransform: 'none',
                  '&.Mui-focusVisible': { 
                    backgroundColor: themeData.color3, // This will be the background color on focus
                    color: themeData.color2, // And this will be the text color on focus
                  },
                  '&:hover': {
                    backgroundColor: themeData.color3,
                    color: themeData.color5,
                },
                }}
              >
                Sign Up
              </Button>
              <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', color: themeData.color5 }}>
                By signing up, you accept MyPlato's{' '}
                <Link to="/legal/terms-of-use" style={{ color: themeData.color4, textDecoration: 'none' }}>
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to="/legal/privacy-policy" style={{ color: themeData.color4, textDecoration: 'none' }}>
                  Privacy Policy
                </Link>.
              </Typography>
            </form>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FakeLoginSignupDialog;