import React, { useRef, useState, useEffect } from 'react';
import { TextField, Button, Tooltip, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NorthIcon from '@mui/icons-material/North';
import StopIcon from '@mui/icons-material/Stop';
import { setDrawerOpen, setLoginSignupScreenOpen, setTimeLimit, setLevel } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import customLog from '../../customLogger';

const InputForm = ({
 handleSend, 
 handleFileChange, 
 handleAbort,

}) => {
  const dispatch = useDispatch();
    const [input, setInput] = useState('');
    const [rows, setRows] = useState(1);
    const [formHeight, setFormHeight] = useState(52);
    // const [timeLimit, setTimeLimit] = useState("15 minutes");
    // const [level, setLevel] = useState(4);
    const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);
    const guidedLessonModeActive = useSelector((state) => state.chat.guidedLessonModeActive);
    const themeData = useSelector((state) => state.chat.themeData);
    const timeLimit = useSelector((state) => state.chat.timeLimit);
    const level = useSelector((state) => state.chat.level);
    const isStreaming = useSelector((state) => state.chat.isStreaming);

    customLog('INPUTFORM LOADED WITH GUIDEDLESSONMODEACTIVE: ' + guidedLessonModeActive);
    
    const handleChange = (event) => {
        setInput(event.target.value);
        const newRows = Math.min(event.target.value.split('\n').length, 10);
        setRows(newRows);
      };

      useEffect(() => {
        const baseHeight = 52;
        const additionalHeight = Math.min((rows - 1) * 22, 198); // Max 9 additional rows (10 total)
        setFormHeight(baseHeight + additionalHeight);
      }, [rows]);

      const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          handleSubmit(event);
        }
      };

      const handleSubmit = (event) => {
        customLog('HANDLESUBMIT CALLED WITH INPUT: ' + input);
        event.preventDefault();
        if (input.trim() !== '') {
          handleSend(input);//event
          setInput('');
          setRows(1);
        }
      };

      const fileInputRef = useRef(null);

      const handleFileUploadClick = () => {
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
        } else {
            fileInputRef.current.click();
        }
    };

 return (
   <form onSubmit={handleSubmit} id="messageForm" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
     <div style={{ 
       display: 'flex', 
       flexDirection: 'column',
       width: '100%', 
       borderRadius: '25px', 
       background: themeData.color11,
     }}>
       {!guidedLessonModeActive && (
         <div style={{ display: 'flex', gap: '8px', padding: '8px 12px 0 12px' }}>
           <select 
             value={timeLimit}
             onChange={(e) => dispatch(setTimeLimit(e.target.value))}
             style={{
               padding: '4px 24px 4px 8px',
               fontSize: '12px',
               color: themeData.color1,
               backgroundColor: themeData.color9,
               borderRadius: '15px',
               cursor: 'pointer',
               appearance: 'none',
               backgroundImage: `url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22${encodeURIComponent(themeData.color5)}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")`,
               backgroundRepeat: 'no-repeat',
               backgroundPosition: 'right 6px top 50%',
               backgroundSize: '8px auto',
               border: 'none',
               minWidth: 'auto',
             }}
           >
             <option value="5 minutes">5 min</option>
             <option value="10 minutes">10 min</option>
             <option value="15 minutes">15 min</option>
             <option value="20 minutes">20 min</option>
           </select>
           <select
             value={level}
             onChange={(e) => dispatch(setLevel(parseInt(e.target.value)))}
             style={{
               padding: '4px 24px 4px 8px',
               fontSize: '12px',
               color: themeData.color1,
               backgroundColor: themeData.color9,
               borderRadius: '15px',
               cursor: 'pointer',
               appearance: 'none',
               backgroundImage: `url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22${encodeURIComponent(themeData.color5)}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")`,
               backgroundRepeat: 'no-repeat',
               backgroundPosition: 'right 6px top 50%',
               backgroundSize: '8px auto',
               border: 'none',
               minWidth: 'auto',
             }}
           >
             <option value={1}>Kindergarten</option>
             <option value={2}>Middle School</option>
             <option value={3}>High School</option>
             <option value={4}>Undergraduate</option>
             <option value={5}>Graduate</option>
           </select>
         </div>
       )}
       <div style={{ display: 'flex', alignItems: 'center', padding: '0 4px' }}>
         {!guidedLessonModeActive && (
           <Tooltip title="Create a course with a file">
             <IconButton
               aria-label="upload file"
               style={{
                 minWidth: '40px',
                 width: '40px',
                 height: '40px',
                 borderRadius: '50%',
                 padding: 0,
                 marginLeft: '4px',
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center',
                 background: 'transparent',
               }}
               onClick={handleFileUploadClick}
             >
               <AttachFileIcon style={{ color: themeData.color5 }} />
             </IconButton>
           </Tooltip>
         )}
         <input
           type="file"
           ref={fileInputRef}
           style={{ display: 'none' }}
           onChange={handleFileChange}
         />
         <TextField
           className="message-input"
           autoFocus
           value={input}
           onChange={handleChange}
           onKeyDown={handleKeyDown}
           placeholder={guidedLessonModeActive ? "Ask a question" : "Create a personalized crash course with a file or prompt in seconds"}
           InputProps={{
             style: { color: themeData.color1, paddingLeft: '8px', fontSize: '15.264px' },
             disableUnderline: true,
           }}
           variant="standard"
           fullWidth
           margin="normal"
           //disabled={loading}
           multiline={true}
           maxRows={10}
           sx={{ flexGrow: 1, marginRight: '8px', border: 'none' }}
         />
         <Tooltip 
           title={isStreaming ? "Stop Message" : guidedLessonModeActive ? "Send Message" : "Create Crash Course"}
         >
           <Button
             variant="contained"
             color={isStreaming ? "error" : "primary"}
             type={isStreaming ? "button" : "submit"}
             onClick={isStreaming ? handleAbort : undefined}
             //disabled={loading || (!isStreaming && input.trim().length === 0)}
             style={{
               minWidth: '40px',
               width: '40px',
               height: '40px',
               borderRadius: '50%',
               padding: 0,
               marginRight: '4px',
               backgroundColor: "transparent",
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               background: 'transparent',
             }}
           >
             {isStreaming ? <StopIcon style={{ color: themeData.color5 }} /> : <NorthIcon style={{ color: themeData.color5 }} />}
           </Button>
         </Tooltip>
       </div>
     </div>
   </form>
 );
};

export default InputForm;
