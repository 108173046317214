import React, { useState, useMemo } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, IconButton, Tooltip } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { BlockMath, InlineMath } from 'react-katex';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SendIcon from '@mui/icons-material/Send';
import customLog from '../customLogger';
import NorthIcon from '@mui/icons-material/North';
import { useSelector } from 'react-redux';


const MarkdownRenderer = ({ keyProp, totalMessages, content, onLinkClick, onLessonApproved, onLessonEdited, onLessonContinue }) => {
    //customLog('AT BEGINNING KEY IS ' + keyProp);
   const [isEditing, setIsEditing] = useState(false);
   const [editContent, setEditContent] = useState('');
   const themeData = useSelector((state) => state.chat.themeData);


   const renderTable = (tableContent) => {
       const rows = tableContent.map(row =>
           row.split('|').map(cell => cell.trim()).filter(cell => cell !== '')
       );
       const headers = rows[0];
       const body = rows.slice(2); // Skip the separator row


       return (
           <TableContainer component={Paper} style={{ marginBottom: '1em', backgroundColor: 'transparent' }}>
               <Table size="small">
                   <TableHead>
                       <TableRow>
                           {headers.map((header, index) => (
                               <TableCell key={index} style={{ fontWeight: 'bold', color: themeData.color1 }}>
                                   <ReactMarkdown>{header}</ReactMarkdown>
                               </TableCell>
                           ))}
                       </TableRow>
                   </TableHead>
                   <TableBody>
                       {body.map((row, rowIndex) => (
                           <TableRow key={rowIndex}>
                               {row.map((cell, cellIndex) => (
                                   <TableCell key={cellIndex} style={{ color: themeData.color1 }}>
                                       <ReactMarkdown>{cell}</ReactMarkdown>
                                   </TableCell>
                               ))}
                           </TableRow>
                       ))}
                   </TableBody>
               </Table>
           </TableContainer>
       );
   };


   const buttonStyle = {
       width: '120px',
       height: '36px',
       borderRadius: '20px',
       textTransform: 'none',
       marginRight: '10px',
   };


   const renderApproveButton = (lessonId) => (
       <Button
           variant="outlined"
           style={{
               ...buttonStyle,
               color: '#4D961D',
               border: '1px solid rgba(0, 0, 0, 0.1)',
           }}
           startIcon={<CheckCircleOutlineIcon />}
           onClick={() => onLessonApproved(lessonId)}
       >
           Approve
       </Button>
   );

   const renderEditButton = (lessonId) => (
       <Button
           variant="outlined"
           style={{
               ...buttonStyle,
               color: '#2173B5',
               border: '1px solid rgba(0, 0, 0, 0.1)',
           }}
           startIcon={<EditIcon />}
           onClick={() => setIsEditing(true)}
       >
           Edit
       </Button>
   );

   const renderContinueButton = (lessonId) => (
       <Button
           variant="outlined"
           style={{
               ...buttonStyle,
               color: '#4D961D',
               borderColor: '#4D961D',
           }}
           startIcon={<PlayArrowIcon />}
           onClick={() => onLessonContinue(lessonId)}
       >
           Continue
       </Button>
   );

   const renderEditForm = (lessonId) => {
       const handleSubmit = () => {
           onLessonEdited(lessonId, editContent);
           setIsEditing(false);
           setEditContent('');
       };

       return (
           <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
               <TextField
                   multiline
                   rows={1}
                   variant="outlined"
                   fullWidth
                   value={editContent}
                   onChange={(e) => setEditContent(e.target.value)}
                   placeholder="Suggest your edits..."
                   onKeyPress={(e) => {
                       if (e.key === 'Enter' && !e.shiftKey) {
                           e.preventDefault();
                           handleSubmit();
                       }
                   }}
                   style={{
                       marginRight: '10px',
                   }}
                   InputProps={{
                       style: {
                           borderRadius: '20px',
                           color: themeData.color5,
                           border: '1px solid rgba(0, 0, 0, 0.1)',
                           padding: '8px 14px',
                       },
                       endAdornment: (
                           <IconButton
                               onClick={handleSubmit}
                               style={{
                                   color: themeData.color5,
                               }}
                           >
                               <NorthIcon />
                           </IconButton>
                       ),
                   }}
               />
           </div>
       );
   };


   const renderMessageContent = useMemo(() => {
       const result = [];
       let isInCodeBlock = false;
       let currentLanguage = '';
       let codeBlockContent = '';
       let isInTable = false;
       let tableContent = [];
       let listStack = [];
       
       //customLog the value of isFirstLine with the line text


       const processLine = (line, index) => {
        const isFirstLine = index === 0;
        customLog('isFirstLine is ' + isFirstLine + ' for line ' + line);
           if (line.startsWith('```')) {
               if (isInCodeBlock) {
                   result.push(
                       <div key={index} style={{ overflowX: 'auto', maxWidth: '100%' }}>
                           <SyntaxHighlighter language={currentLanguage} style={dark}>
                               {codeBlockContent}
                           </SyntaxHighlighter>
                       </div>
                   );
                   isInCodeBlock = false;
                   currentLanguage = '';
                   codeBlockContent = '';
               } else {
                   isInCodeBlock = true;
                   currentLanguage = line.slice(3).trim() || 'text';
               }
           } else if (isInCodeBlock) {
               codeBlockContent += line + '\n';
           } else if (line.trim().startsWith('|') && line.trim().endsWith('|')) {
               if (!isInTable) {
                   isInTable = true;
                   tableContent = [];
               }
               tableContent.push(line);
           } else if (isInTable && line.trim() === '') {
               result.push(renderTable(tableContent));
               isInTable = false;
               tableContent = [];
           } else if (line.includes('<ApproveButton') || line.includes('<EditButton') || line.includes('<ContinueButton')) {
               const match = line.match(/lessonId="([^"]+)"/);
               if (match) {
                customLog('KEY IS ' + keyProp);
                   const lessonId = match[1];
                   if (line.includes('<ApproveButton')) {
                       customLog('APPROVEBUTTON SEEN');
                       result.push(renderApproveButton(lessonId));
                   } else if (line.includes('<EditButton')) {
                       result.push(isEditing ? renderEditForm(lessonId) : renderEditButton(lessonId));
                   } else if (line.includes('<ContinueButton') && keyProp === totalMessages - 1) {
                       // Check if keyProp is the last index
                       result.push(renderContinueButton(lessonId));
                   }
               }
           } else {
               const listMatch = line.match(/^(\s*)[-*+]\s/);
               if (listMatch) {
                   const indent = listMatch[1].length;
                   while (listStack.length > 0 && listStack[listStack.length - 1].indent >= indent) {
                       listStack.pop();
                   }
                   if (listStack.length === 0 || listStack[listStack.length - 1].indent < indent) {
                       const newList = { indent, items: [] };
                       if (listStack.length > 0) {
                           listStack[listStack.length - 1].items.push(newList);
                       } else {
                           result.push(newList);
                       }
                       listStack.push(newList);
                   }
                   listStack[listStack.length - 1].items.push(line.trim().slice(2));
               } else {
                   if (listStack.length > 0) {
                       listStack = [];
                   }
                   const parts = line.split(/(\$\$.*?\$\$)/g);
                   parts.forEach((part, partIndex) => {
                       if (part.startsWith('$$') && part.endsWith('$$')) {
                           result.push(
                               <BlockMath key={`${index}-${partIndex}`}>
                                   {part.slice(2, -2)}
                               </BlockMath>
                           );
                       } else {
                           result.push(
                               <ReactMarkdown
                                   key={`${index}-${partIndex}`}
                                   rehypePlugins={[rehypeRaw, rehypeKatex]}
                                   remarkPlugins={[remarkMath]}
                                   components={{
                                       p: ({node, ...props}) => <Typography variant="body1" style={{fontSize: '16px',  marginTop: 0, marginBottom: '0.5em', lineHeight: '1.6', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', color: themeData.color1}} {...props} />,
                                       h1: ({node, ...props}) => <Typography variant="h4" style={{marginTop: isFirstLine ? 0 : '1em', marginBottom: '0.5em', lineHeight: '1.1', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', color: themeData.color1, fontWeight: 'bold'}} {...props} />,
                                       h2: ({node, ...props}) => <Typography variant="h5" style={{marginTop: isFirstLine ? '0em' : '1em', marginBottom: '0.5em', lineHeight: '1.2', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', color: themeData.color1, fontWeight: 'bold'}} {...props} />,
                                       h3: ({node, ...props}) => <Typography variant="h6" style={{ marginTop: isFirstLine ? 0 : '1em', marginBottom: '0.25em', lineHeight: '1.3', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', color: themeData.color1, fontWeight: 'bold'}} {...props} />,
                                       ul: ({ node, ...props }) => (
                                           <ul style={{fontSize: '16px', marginTop: 0,marginBottom: '1em', lineHeight: '1.6', paddingLeft: '2em', listStyleType: 'disc', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', color: themeData.color1 }} {...props} />
                                       ),
                                       ol: ({ node, ...props }) => (
                                           <ol type="1" style={{fontSize: '16px', marginTop: 0,marginBottom: '1em', lineHeight: '1.6', listStyleType: 'decimal', listStylePosition: 'inside', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', color: themeData.color1 }} {...props} />
                                       ),
                                       li: ({ node, ...props }) => (
                                           <li style={{fontSize: '16px', marginTop: 0, marginBottom: 0, lineHeight: '1.6', listStyleType: 'inherit', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', color: themeData.color1 }} {...props} />
                                       ),
                                       a: ({ node, id, title, children, ...props }) => (
                                           <Tooltip title={title || ''}>
                                               <a
                                                   className="in-text-citation"
                                                   onClick={(e) => {
                                                       e.preventDefault();
                                                       if (onLinkClick) {
                                                           onLinkClick(id);
                                                       }
                                                   }}
                                                   style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }}
                                                   {...props}
                                               >
                                                   {children} 
                                               </a>
                                           </Tooltip>
                                       ),
                                       inlineMath: ({value}) => <InlineMath>{value}</InlineMath>
                                   }}>
                                   {part}
                               </ReactMarkdown>
                           );
                       }
                   });
               }
            //    isFirstLine = false;
           }

       };
       const renderList = (list, depth = 0) => {
           const listStyleTypes = ['disc', 'circle', 'square'];
           return (
               <ul style={{
                   fontSize: '16px',
                   marginBottom: depth === 0 ? '1em' : '0',
                   paddingLeft: depth === 0 ? '2em' : '1.5em',
                   listStyleType: listStyleTypes[depth % 3],
                   overflowWrap: 'break-word',
                   wordWrap: 'break-word',
                   hyphens: 'auto',
                   color: themeData.color1
               }}>
                   {list.items.map((item, index) => {
                       if (typeof item === 'string') {
                           return (
                               <li key={index} style={{
                                   fontSize: '16px',
                                   marginBottom: '0.5em',
                                   listStyleType: 'inherit',
                                   overflowWrap: 'break-word',
                                   wordWrap: 'break-word',
                                   hyphens: 'auto',
                                   color: themeData.color1
                               }}>
                                   <ReactMarkdown
                                       rehypePlugins={[rehypeRaw, rehypeKatex]}
                                       remarkPlugins={[remarkMath]}
                                       components={{
                                        p: ({node, ...props}) => <Typography variant="body1" style={{fontSize: '16px',  marginTop: 0, marginBottom: '0em', lineHeight: '1.6', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', color: themeData.color1}} {...props} />,
                                           strong: ({node, ...props}) => <strong style={{color: themeData.color1}} {...props} />,
                                           em: ({node, ...props}) => <em style={{color: themeData.color1}} {...props} />,
                                           inlineMath: ({value}) => <InlineMath>{value}</InlineMath>
                                       }}
                                   >
                                       {item}
                                   </ReactMarkdown>
                               </li>
                           );
                       } else {
                           return renderList(item, depth + 1);
                       }
                   })}
               </ul>
           );
       };

    //    let isFirstLine = true;
       content.split('\n').forEach(processLine);


       if (isInCodeBlock) {
           result.push(
               <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
                   <SyntaxHighlighter language={currentLanguage} style={dark}>
                       {codeBlockContent}
                   </SyntaxHighlighter>
               </div>
           );
       }


       if (isInTable) {
           result.push(renderTable(tableContent));
       }
       

       return result.map((item, index) =>
           typeof item === 'object' && item.indent !== undefined ? renderList(item) : item
       );

   }, [content, onLinkClick, isEditing, editContent, totalMessages]);


   return <>{renderMessageContent}</>;
};


export default MarkdownRenderer;
