import React from 'react';
import customLog from '../../customLogger.js';
import MessageComponent from './messageComponent.js';

const PreviousMessages = ({ messages, username, platoImage, stickman, onLinkClick, onLessonApproved, onLessonEdited, onLessonContinue }) => {
    customLog('PREVIOUSMESSAGES IS CALLED WITH messages of ' + JSON.stringify(messages));

    return (
        <>
            {messages.map((message, index) => {
                customLog('Rendering message at index: ' + index);
                return (
                    <MessageComponent
                        key={index}
                        message={message}
                        messageKey={index} // Pass the index as messageKey
                        totalMessages={messages.length} // Pass the total number of messages
                        username={username}
                        platoImage={platoImage}
                        stickman={stickman}
                        onLinkClick={onLinkClick}
                        onLessonApproved={onLessonApproved}
                        onLessonEdited={onLessonEdited}
                        onLessonContinue={onLessonContinue}
                    />
                );
            })}
        </>
    );
};

export default React.memo(PreviousMessages);
