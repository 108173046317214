import React from 'react';
import { Typography, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useDispatch, useSelector } from 'react-redux';
import customLog from '../../../customLogger';
import { updateStarRating, incrementCurrentNumberSubtopics, alertTopicDone, setPrereqTestModeActive, setNumberCorrectQuestions, setNumberTotalQuestions } from '../../../store';
import platoImage from '../../../images/plato.png';

const CompleteScreen = ({ onContinue, themeData, createLesson, currentDisplayedTopicContent, isMobile }) => {
    const dispatch = useDispatch();
    const currentTopicID = useSelector((state) => state.chat.currentTopicID);
    const prereqTestModeActive = useSelector((state) => state.chat.prereqTestModeActive);
    const prereqTestQuestions = useSelector((state) => state.chat.prereqTestQuestions);
    const numberCorrectQuestions = useSelector((state) => state.chat.numberCorrectQuestions);
    const numberTotalQuestions = useSelector((state) => state.chat.numberTotalQuestions);
    const topicIndexOfLesson = useSelector((state) => state.chat.topicIndexOfLesson);



    const ratio = numberCorrectQuestions / numberTotalQuestions;
    let earnedStars = 0;
    if (ratio >= 0.33 && ratio < 0.66) {
        earnedStars = 1;
    } else if (ratio >= 0.66 && ratio < 1) {
        earnedStars = 2;
    } else if (ratio === 1) {
        earnedStars = 3;
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            height: '100%',
            width: '100%'
        }}>
            <Typography variant="h5" sx={{ 
                color: themeData.color1,
                marginBottom: '24px',
                fontWeight: 'bold'
            }}>
                Level {topicIndexOfLesson + 1}
            </Typography>

            <Box sx={{
                width: '120px',
                height: '120px',
                borderRadius: '50%',
                overflow: 'hidden',
                border: `4px solid ${themeData.color1}`,
                marginBottom: '24px'
            }}>
                <img 
                    src={platoImage} 
                    alt="Plato"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}
                />
            </Box>

            {!prereqTestModeActive && (
                <Box sx={{ 
                    display: 'flex', 
                    gap: '8px', 
                    marginBottom: '24px'
                }}>
                    {[...Array(3)].map((_, index) => (
                        index < earnedStars ? (
                            <StarIcon 
                                key={index}
                                sx={{
                                    color: '#FFD700',
                                    fontSize: '32px'
                                }}
                            />
                        ) : (
                            <StarBorderIcon
                                key={index}
                                sx={{
                                    color: '#FFD700',
                                    fontSize: '32px'
                                }}
                            />
                        )
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default CompleteScreen;