import React, { useRef, useState, useCallback, useEffect } from 'react';
import {FormControlLabel, Checkbox, Button, Stack, Box, AppBar, Drawer, Toolbar, IconButton, Typography, Tooltip, LinearProgress, Menu, MenuItem} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import 'highlight.js/styles/default.css';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {setFakeLoginSignupScreenOpen, setLoginSignupScreenOpen, setDrawerOpen, uploadLongtermFileAPI, 
    uploadContextFileAPI, handleNewChat, setCoursePopover, setSnackbarShareChatOpen,
        setSnackbarRecordingOpen, setSnackbarRecordingMessage, setIsShortTermMemory, removeCurrentLessonGroup, 
        setSelectedCenterResourceType, setLibraryPageActive, getAllLessons,
        setIsLongTermMemory, setGeneralSnackbarMessage, setGeneralSnackbarOpen, signOut, setCenterPanelVisible,
        setConversationID, setFlashcardsScreenOpen, setQuizMeScreenOpen, incrementCurrentTopicScore, setSettingsOpen,
        setPersonalizationOpen, handleExitLesson, setOnHomeScreen} from '../../store.js';
import { useSelector, useDispatch } from 'react-redux';
import MemoryCheckbox from './MemoryCheckbox.js';
import customLog from '../../customLogger.js';
import './AppBar.css';
import platoImage from '../../images/plato.png'
import stickman from '../../images/noProfilePhotoNoBG.png';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';

const DjangoHost = process.env.REACT_APP_DJANGO_HOST;

const TopAppBar = ({isMobile}) => {
    //Initialize dispatch
    const dispatch = useDispatch();

    // Maximum file size
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    // selector for colors of selected theme
    const themeData = useSelector((state) => state.chat.themeData);
    const libraryPageActive = useSelector((state) => state.chat.libraryPageActive);

    // State for left drawer
    const drawerOpen = useSelector((state) => state.chat.drawerOpen);

    let profilePicture = useSelector((state) => state.chat.profilePicture);
    const currentLessonGroup = useSelector((state) => state.chat.currentLessonGroup);
    const selectedCenterResourceType = useSelector((state) => state.chat.selectedCenterResourceType);

    function clearAllCookies() {
        // Split the document.cookie string by "; " to get each individual cookie
        document.cookie.split(";").forEach((cookie) => {
          // Get the cookie name (the part before the '=' sign)
          const cookieName = cookie.split("=")[0];
          // Set the cookie's expiration date to a past date to delete it
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        });
      }

    // Menu state
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSettingsClick = () => {
        dispatch(setSettingsOpen(true));
        handleClose();
    };

    const handlePersonalizationClick = () => {
        dispatch(setPersonalizationOpen(true));
        handleClose();
    };

    const handleSignOut = () => {
        dispatch(signOut());
        clearAllCookies();

        handleClose();
    };

    const handleOpenLoginDialog = () => {
        dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 0}));
    };
    
    const handleOpenSignupDialog = () => {
        dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
    };

    const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);
    const signUpButtonStyle = {
        color: themeData.color7,
        backgroundColor: 'white',
        borderRadius: "14.51px",
        textTransform: 'none',
        padding: '4px 12px',
        minWidth: '80px',
        fontSize: '0.875rem',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
    };

    const handleSignUpClick = () => {
        dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
    };
    const guidedLessonModeActive = useSelector((state) => state.chat.guidedLessonModeActive);
    const currentLessonName = useSelector((state) => state.chat.currentLessonName);
    const currentTopicName = useSelector((state) => state.chat.currentSubtopicName);
    const currentTopicIndex = useSelector((state) => state.chat.currentTopicIndex);
    const currentTopicScore = useSelector((state) => state.chat.currentTopicScore);

    const [displayScore, setDisplayScore] = useState(0);
    const [scoreIncrease, setScoreIncrease] = useState(0);

    useEffect(() => {
        if (currentLessonGroup && currentLessonGroup && currentLessonGroup.topics && currentLessonGroup.topics[currentTopicIndex]) {
            let timer;
            if (currentTopicScore < displayScore) {
                // User switched lessons or topics
                setDisplayScore(currentTopicScore);
            } else if (displayScore < currentTopicScore) {
                timer = setInterval(() => {
                    setDisplayScore(prev => {
                        if (prev < currentTopicScore) {
                            return prev + 1;
                        } else {
                            clearInterval(timer);
                            return prev;
                        }
                    });
                }, 20);
            }
            return () => clearInterval(timer);
        }
    }, [currentLessonGroup, currentTopicIndex, currentTopicScore]);

    useEffect(() => {
        if (currentLessonGroup && currentLessonGroup && currentLessonGroup.topics && currentLessonGroup.topics[currentTopicIndex]) {
            setScoreIncrease(currentTopicScore - displayScore);
            const timer = setTimeout(() => {
                setScoreIncrease(0);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [currentLessonGroup, currentTopicIndex, currentTopicScore]);

    const handleIncrementScore = () => {
        dispatch(incrementCurrentTopicScore(5));
    };

    const handleExitLessonReact = () => {
        dispatch(handleExitLesson());
        dispatch(setSelectedCenterResourceType(null));
        dispatch(setOnHomeScreen(true));
        dispatch(setCenterPanelVisible(false));
    }
    const onHomeScreen = useSelector((state) => state.chat.onHomeScreen);
    return (
        <AppBar position="fixed" style={{ zIndex: 1100, backgroundColor: onHomeScreen ? themeData.color7 : 'transparent', margin: 0, boxShadow: 'none' }}>
            <Toolbar style={{ minHeight: '60px', padding: 10, justifyContent: 'space-between' }}>
                {currentLessonGroup && selectedCenterResourceType && (
                    <IconButton
                        onClick={() => {
                            dispatch(setSelectedCenterResourceType(null));
                        }}
                        // style={{
                        //     marginRight: '5px'
                        // }}
                    >
                        <ArrowBackIcon style={{ color: themeData.color5 }} />
                    </IconButton>
                )}
                {currentLessonGroup && !selectedCenterResourceType && (
                    <IconButton
                        onClick={() => handleExitLessonReact()}
                        style={{
                            transform: 'rotate(180deg)',
                            // marginRight: '5px'
                        }}
                    >
                        <LogoutIcon style={{ color: themeData.color5 }} />
                    </IconButton>
                )}
                {!currentLessonGroup && !isMobile &&(
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '8px'
                    }}>
                        <div style={{
                            fontSize: '40px',
                            fontWeight: 'bold',
                            color: themeData.color15,
                            textAlign: 'left',
                            position: 'relative',
                        }}>
                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                MyPlato
                            </span>
                            <span style={{
                                position: 'absolute',
                                top: '0px',
                                right: '-40px',
                                backgroundColor: themeData.color9,
                                color: themeData.color5,
                                padding: '2px 8px',
                                borderRadius: '12px',
                                fontSize: '14px',
                                fontWeight: 'normal',
                            }}>
                                Beta
                            </span>
                        </div>
                        <div style={{
                            display: 'flex',
                            gap: '0px',
                            marginLeft: '40px'
                        }}>
                            <Button 
                                onClick={() => {
                                    dispatch(setLibraryPageActive(false));
                                    dispatch(getAllLessons());
                                }}
                                style={{
                                    color: !libraryPageActive ? themeData.color1 :themeData.color5,
                                    textTransform: 'none',
                                    fontSize: '18px',
                                    backgroundColor: !libraryPageActive ? themeData.color9 : 'transparent',
                                    borderRadius: '30px',
                                    padding: '8px'
                            }}>
                                Home
                            </Button>
                            <Button 
                                onClick={() => {
                                    dispatch(setLibraryPageActive(true));
                                    dispatch(getAllLessons());
                                }}
                                style={{
                                    color: libraryPageActive ? themeData.color1 :themeData.color5,
                                    textTransform: 'none',
                                    fontSize: '18px',
                                    backgroundColor: libraryPageActive ? themeData.color9 : 'transparent',
                                    borderRadius: '30px',
                                    padding: '8px'
                                }}>
                                Library
                            </Button>
                        </div>
                    </div>
                )}

                {guidedLessonModeActive && currentLessonGroup && currentLessonGroup.name && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <Typography variant="h6" style={{ color: themeData.color5, textAlign: 'center' }}>
                             {currentLessonGroup.name}
                        </Typography>
                        {/* <div style={{ position: 'relative', width: '300px', height: '30px', backgroundColor: themeData.color2, borderRadius: '15px', overflow: 'hidden' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    height: '100%',
                                    width: `${displayScore}%`,
                                    backgroundColor: themeData.color18,
                                    borderRadius: '15px',
                                    transition: 'width 0.5s ease-in-out',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                            </div>
                        </div> */}
                        {/* <div style={{ width: '50px', marginLeft: '10px' }}>
                            {scoreIncrease > 0 && (
                                <Typography 
                                    variant="body2" 
                                    style={{ 
                                        color: '#08AA46', 
                                        animation: 'fadeInOut 2s ease-in-out'
                                    }}
                                >
                                    +{scoreIncrease}
                                </Typography>
                            )}
                        </div> */}
                    </div>
                )}

                <div style={{ flexGrow: 1 }} />
                {
                    isLoggedIn ? (
                        <>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <img 
                                    src={profilePicture || stickman} 
                                    alt="Profile" 
                                    style={{ 
                                        height: '50px', 
                                        width: '50px', 
                                        borderRadius: '50%'
                                    }} 
                                />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        backgroundColor: themeData.color9,
                                        '& .MuiMenuItem-root': {
                                            color: themeData.color1,
                                            '&:hover': {
                                                backgroundColor: themeData.color8,
                                            },
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={handleSettingsClick}>
                                    <SettingsIcon sx={{ mr: 2, color: themeData.color1 }} />
                                    Settings
                                </MenuItem>
                                <MenuItem onClick={handlePersonalizationClick}>
                                    <PersonIcon sx={{ mr: 2, color: themeData.color1 }} />
                                    Personalization
                                </MenuItem>
                                <MenuItem onClick={handleSignOut}>
                                    <LogoutIcon sx={{ mr: 2, color: themeData.color1 }} />
                                    Sign out
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <div>
                            <Button 
                                style={{
                                    backgroundColor: themeData.color1,
                                    color: themeData.color7,
                                    borderRadius: "14.51px",
                                    textTransform: 'none', 
                                    padding: '4px 12px',
                                    minWidth: '80px',
                                    fontSize: '0.875rem',
                                    marginRight: '10px',
                                    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
                                }}
                                onClick={handleOpenLoginDialog}
                            >
                                Login
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: 'transparent',
                                    color: themeData.color1,
                                    border: '1px solid rgba(255,255,255,0.1)',
                                    borderRadius: "14.51px", 
                                    textTransform: 'none',
                                    padding: '4px 12px',
                                    minWidth: '80px',
                                    fontSize: '0.875rem',
                                    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
                                }}
                                onClick={handleOpenSignupDialog}
                            >
                                Sign Up
                            </Button>
                        </div>
                    )
                }
            </Toolbar>
        </AppBar>
    );
};

export default TopAppBar;
