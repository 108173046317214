import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { changeCurrentDisplayedExploreIndex } from '../../store';
import customLog from '../../customLogger';

const SwipeableCard = ({ recommendation, onSwipeUp, onSwipeDown, onClick, isDragging, index }) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [dragY, setDragY] = useState(0);
  const themeData = useSelector((state) => state.chat.themeData);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    setTouchEnd(e.touches[0].clientY);
    const currentY = e.touches[0].clientY;
    const deltaY = touchStart - currentY;
    setDragY(deltaY);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const swipeDistance = touchStart - touchEnd;
    const minSwipeDistance = 50;

    if (swipeDistance > minSwipeDistance) {
      onSwipeUp(recommendation);
    } else if (swipeDistance < -minSwipeDistance) {
      onSwipeDown(recommendation);
    }

    setTouchStart(null);
    setTouchEnd(null);
    setDragY(0);
  };

  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -100, opacity: 0 }}
      transition={{ duration: 0.3 }}
      style={{
        top: '90px',
        width: '100%',
        height: 'calc(100% - 90px)',
        position: 'absolute',
        transform: `translateY(${-dragY}px)`,
        zIndex: 2,
        touchAction: 'none',
        willChange: 'transform'
      }}
    >
      <Box
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onClick={() => onClick(recommendation)}
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: themeData.color9,
          borderRadius: '20px',
          overflow: 'hidden',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          cursor: 'pointer',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            width: '100%',
            flex: 1,
            overflow: 'hidden',
            position: 'relative'
          }}
        >
          <img
            src={recommendation.picture_url || 'https://storage.googleapis.com/myplato-public/lessons_images/933ee9c0-4b39-460c-9f1e-6fa95b31168f/IntroductiontoReact.png'}
            alt={recommendation.name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: themeData.color1,
              textAlign: 'center',
              fontSize: '1.1rem',
              fontWeight: 500
            }}
          >
            {recommendation.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: themeData.color5,
              textAlign: 'center',
              mt: 1
            }}
          >
            Press to start learning, Swipe to see more
          </Typography>
        </Box>
      </Box>
    </motion.div>
  );
};

const ExploreCardsView = ({createLesson}) => {
  const themeData = useSelector((state) => state.chat.themeData);
  const exploresArray = useSelector((state) => state.chat.exploresArray);
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const handleSwipeUp = (explore) => {
    customLog('HANDLESWIPEUP WITH EXPLORE OF ' + JSON.stringify(explore));
    setCurrentIndex(prev => prev + 1);
    dispatch(changeCurrentDisplayedExploreIndex(-1));
  };

  const handleSwipeDown = (explore) => {
    customLog('HANDLESWIPEDOWN WITH EXPLORE OF ' + JSON.stringify(explore));
    setCurrentIndex(prev => Math.max(0, prev - 1));
    dispatch(changeCurrentDisplayedExploreIndex(1));
  };

  const handleCardClick = (explore) => {
    createLesson({ message: explore.prompt, exploreID: explore.id });
  };

  if (!exploresArray || currentIndex >= exploresArray.length) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2
        }}
      >
        <Typography variant="h6" sx={{ color: themeData.color1, textAlign: 'center' }}>
          No more recommendations available!
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <AnimatePresence mode="wait">
        <SwipeableCard
          key={exploresArray[currentIndex].id}
          recommendation={exploresArray[currentIndex]}
          onSwipeUp={handleSwipeUp}
          onSwipeDown={handleSwipeDown}
          onClick={handleCardClick}
          index={0}
        />
      </AnimatePresence>
    </Box>
  );
};

export default ExploreCardsView;