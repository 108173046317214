import React from 'react';
import { Typography, Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

const FailedScreen = ({ onRetry, themeData }) => {
    const buttonStyle = {
        width: '120px',
        height: '36px',
        borderRadius: '20px',
        textTransform: 'none',
        marginRight: '10px',
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            backgroundColor: 'transparent',
            borderRadius: '20px',
            padding: '20px',
        }}>
            <Typography variant="h4" style={{ color: themeData.color5, marginBottom: '20px' }}>
                Quiz Failed
            </Typography>
            <Button
                variant="outlined"
                style={{
                    ...buttonStyle,
                    color: '#FF5252',
                    borderColor: '#FF5252',
                }}
                startIcon={<ReplayIcon />}
                onClick={onRetry}
            >
                Retry
            </Button>
        </div>
    );
};

export default FailedScreen;
