import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { AppBar, Divider, TextField, Button, List, ListItem, ListItemText,
        Toolbar, IconButton, Typography, 
        Tooltip, Snackbar } from '@mui/material';
import platoImage from './images/plato.png'
import platoCircle from './images/plato-modified.png';
import platoCircleSmaller from './images/plato-modified-smaller.png';
import platoCircleSmallest from './images/plato-modified-smallest.png';
import platoCircleSmallestNoBG from './images/plato-modified-smallest-nobg.png';
import attentionIsAllYouNeed from './images/attentionOpaque.png';
import attention from './images/attention.png';
import lobster from './images/lobster.png';
import sandwich from './images/sandwich.png';
import electoral from './images/electoral.png';
//import attentionIsAllYouNeed from './images/AttentionIsAllYouNeed.png';
import { useNavigate } from 'react-router-dom';
import considerTheLobster from './images/ConsiderTheLobster.png';
import electoralCollege from './images/ElectoralCollege.png';
//mport stickman from './images/stickman-nobg.png';
import stickman from './images/noProfilePhotoNoBG.png';
import XLogo from './images/xLogo.png';
import discordBlack from './images/discord-black.png';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ShortTextIcon from '@mui/icons-material/ShortText';
import './chat.css';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { setCenterPanelLoading, setCurrentLessonID, setCurrentTopicIndex, setContextFileLoading, getFileURLFromUUID, removeResources,  setGeneralSnackbarOpen, setLoginSignupScreenOpen, setFlashcardPrompt, setMemoryScreenOpen,
    setResources, setMostRecentUserPrompt, addMessage, createChatTitle, setQuizPrompt, appendFlashcardToGroup,
    setSnackbarRecordingOpen, setAbortion, setStreamingCards, addPresetPrompt, setCurrentQuizID, setCurrentTopicScore,
    setSnackbarShareChatOpen, setSettingsOpen, setDrawerOpen, handleNewChat, setCurrentFlashcardGroupID, uploadResumeAPI,
    setSelectedResource, setConversationID, setFlashcardQuizObject, setFlashcards, setQuiz, setCurrentFlashcardGroup,
    setChatTitle, setMessages, changeFlashcardQuizIndex, setFlashcardQuizIndex, appendQuizQuestion, setOnHomeScreen,
    addStreamedMessage, searchLongtermFilesAPI, addNewChat, addFlashcard, addQuizQuestion, editFlashcardGroupName,
    getChatTitleAPI, setGeneralSnackbarMessage, setIframeDragged, appendFlashcard, prependFlashcardGroup, setIsStreaming,
    setFlashcardsScreenOpen, setQuizMeScreenOpen, uploadContextFileAPI, uploadLongtermFileAPI, removeChatAPI,
    setCurrentFlashcardIndex, setSelectedResourceType, setCurrentQuizGroup, changeCurrentFlashcardIndex, setCenterPanelVisible,
    getAppendFlashcardToGroup, setCurrentQuizIndex, prependQuizGroup, appendQuizQuestionToGroup, displayInTextFile, setLessonsScreenOpen, 
    approveLessonAPI, getAllLessons, setCurrentLessonIndex, setCurrentLessonGroup, setResumeScreenOpen,
    setGuidedLessonModeActive, setSelectedCenterResourceType, setTimeLimit, setLevel, changeCurrentDisplayedTopicIndex,
setLibraryPageActive, setCurrentDisplayedTopicContent, getTopicContent, setPrereqTestOptionScreenOpen, setPrereqTestModeActive,
incrementCurrentNumberSubtopics, updateStarRating, alertTopicDone, setNumberCorrectQuestions, setNumberTotalQuestions } from './store.js';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';
import ShareIcon from '@mui/icons-material/IosShare';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {Document, Page, pdfjs} from 'react-pdf';
import TopAppBar from './components/AppBar';
import BottomLeftDrawer from './components/LeftDrawer/bottom';
import TopLeftDrawer from './components/LeftDrawer/top';
import OptionSelectionDialog from './components/OptionsPopup/optionsPopup.js';
import {debounce} from 'lodash';
import ResourcePanel from './components/ResourcePanel';
import TopResourcePanel from './components/ResourcePanel/top/topResource.js';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import customLog from './customLogger';
import QuizMeScreen from './components/QuizMeScreen/index.js';
import LoginSignupDialogComponent from './components/LoginSignupDialog/LoginSignupDialogComponent.js';
import FakeLoginSignupDialogComponent from './components/fakeLoginSignupDialog/FakeLoginSignupDialogComponent.js';
import SettingsPopup from './components/SettingsPopup/SettingsPopup.js';    
import SplitPane from 'react-split-pane';
import 'katex/dist/katex.min.css';
import CreateOrLibrary from './components/FlashcardsQuizMeDialog/createOrLibrary.js';
import StreamingMessage from './components/ChatMessages/streamingMessage.js';
import PreviousMessages from './components/ChatMessages/prevMessages.js';
import InputForm from './components/InputForm/inputForm.js';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CenteredResourceView from './components/CenteredResourceView';
import { handleTopicSubmit, createPrereqQuiz, continueLesson, approveLesson, retrieveLesson, handleLesson, handleSend, sendOrCreateChatMessageStreaming, processQuizData, processFlashcardData } from './chatUtils';
import CloseIcon from '@mui/icons-material/Close';
import PersonalizationPopup from './components/PersonalizationPopup/PersonalizationPopup.js';
const DjangoHost = process.env.REACT_APP_DJANGO_HOST;


const Chat = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;//todo: look more into this

    const dispatch = useDispatch();

    const navigate = useNavigate();

    /* DRAWER */
    // State to obtain if drawer open
    const drawerOpen = useSelector((state) => state.chat.drawerOpen);
    const [drawerWidth, setDrawerWidth] = useState(240);
    const [minPanelSize, setMinPanelSize] = useState(1050);

    // UseEffect to update drawer width when drawerOpen state changes
    useEffect(() => {
        if (drawerOpen) {
            setDrawerWidth(240);
            setMinPanelSize(1050);
        } else {
            setDrawerWidth(0);
            setMinPanelSize(800);
        }
        document.documentElement.style.setProperty('--drawer-width', `${drawerWidth}px`);
        customLog('minPanelSize is ' + minPanelSize);
    }, [drawerOpen, drawerWidth]);

    // Add this effect to handle drawer state changes
    useEffect(() => {
        if (drawerOpen) {
            document.body.classList.remove('drawer-closed');
        } else {
            document.body.classList.add('drawer-closed');
        }
    }, [drawerOpen]);


    /* SETTINGS */
    const settingsOpen = useSelector((state) => state.chat.settingsOpen);

    /* TOP APP BAR */
    const snackbarShareChatOpen = useSelector((state) => state.chat.snackbarShareChatOpen);
    const snackbarRecordingOpen = useSelector((state) => state.chat.snackbarRecordingOpen);
    const snackbarRecordingMessage = useSelector((state) => state.chat.snackbarRecordingMessage);
    const generalSnackbarOpen = useSelector((state) => state.chat.generalSnackbarOpen);
    const generalSnackbarMessage = useSelector((state) => state.chat.generalSnackbarMessage);

    // Resource
    const selectedResourceType = useSelector((state) => state.chat.selectedResourceType);

    //Useeffect that executes whenever value of generalSnackbarMessage changes
    useEffect(() => {
        if (generalSnackbarMessage) {
            customLog('generalSnackbarMessage is ' + generalSnackbarMessage);
        }
    }, [generalSnackbarMessage]);

    //TODO: Temp variable for studentCourses. Need to integrate with redux
    const studentCourses = useSelector((state) => state.chat.courses);

    // const [currentArrayIndex, setCurrentIndex] = useState(0);

    const libraryPageActive = useSelector((state) => state.chat.libraryPageActive);
    const allLessonGroups = useSelector((state) => state.chat.allLessonGroups);
    

    //Toolbar height variables
    const toolbarRef = useRef(null);
    const [toolbarHeight, setToolbarHeight] = useState(0);

    //AppBar height variables
    const appBarRef = useRef(null);
    const [appBarHeight, setAppBarHeight] = useState(0);

    // height for plato no chat image
    // const [platoImageBottom, setPlatoImageBottom] = useState(0);

    // Obtain username
    let username = useSelector((state) => state.chat.username);

    // Obtain firstName
    let firstName = useSelector((state) => state.chat.firstName);

    // Obtain profilePicture
    let profilePicture = useSelector((state) => state.chat.profilePicture);

    // Obtain isLoggedIn
    let isLoggedIn = useSelector((state) => state.chat.isLoggedIn);


    useEffect(() => {
        if (isLoggedIn) {
            setReactChatMessages([]);
        } else {
            setReactChatMessages([{
                user: 'Assistant',
                text: `**Hey! Plato here, nice to meet you :)**

I'm your personal tutor! I can teach you anything, anytime, anywhere

I can make you flashcards or quizzes over any prompt or file and I can read any file you upload so we can talk about it. But I think you're going to love the guided lesson. Just tell me what you want to learn, and I'll teach it to you in a fun efficient way`
            }]);
        }
    }, [isLoggedIn]);
    
    // Obtain chatMessages
    let chatMessages = useSelector((state) => state.chat.messages);

    let [reactChatMessages, setReactChatMessages] = useState([{
        user: 'Assistant',
        text: `**Hey! Plato here, nice to meet you :)**

I'm here to be your personal tutor! I can teach you any subject, from history to physics.

I can make you flashcards or quizzes over any prompt or file. I can read any file you upload so we can talk about it. But I think you're going to love the guided lesson. Just tell me what you want to learn, and I'll teach it to you in a fun efficient way`
    }]);

    // Obtain resources
    let resources = useSelector((state) => state.chat.resources);

    // Obtain context resource selected
    let contextResourceSelection = useSelector((state) => state.chat.contextResourceSelection);

    // Obtain selectedResource
    let selectedResourceRedux = useSelector((state) => state.chat.selectedResource);

    // const selectedResourceRedux = 10;

    // Obtain user email
    let userEmail = useSelector((state) => state.chat.email);

    // Variable for dragging
    const [isDragging, setIsDragging] = useState(false);

    // State for iframe
    const [iframeLoaded, setIframeLoaded] = useState(false);

    const handleIframeLoad = useCallback(() => {
        customLog('useCallback of handleIFrameLoad');
        setIframeLoaded(true);
      }, []);

    // const selectedResource = useSelector((state) => state.chat.selectedResource);
    const selectedResourceID = useSelector((state) => state.chat.selectedResourceID);
    const selectedResource = useMemo(() => {
        customLog('inside selectedResource useMemo with selectedResourceRedux as ' + selectedResourceRedux);
        customLog('inside selectedResource with resources as ' + JSON.stringify(resources) + ' and selectedResourceID as ' + selectedResourceID);

        return selectedResourceRedux;
      }, [resources, selectedResourceID]);

      // useEffect of selectedResourceRedux
        useEffect(() => {
            customLog('useEffect of selectedResourceRedux called with selectedResourceRedux as ' + selectedResourceRedux);
            if (selectedResourceRedux) {
                customLog('useEffect of selectedResourceRedux selectedResourceID is ' + selectedResourceID);
                dispatch(setDrawerOpen(false));
                setIsPaneExpanded(true);
            }
        }, [selectedResourceRedux]);



    // selected theme
    const themeData = useSelector((state) => state.chat.themeData);

    const handleSelectResource = (resource) => {
        dispatch(setSelectedResource(resource));
    };

    //Obtain curently selected course ID
    const selectedCourseId = useSelector((state) => state.chat.selectedCourse);
    const realSelectedCourseId = useSelector((state) => state.chat.selectedCourse);
    const selectedCourseTitle = studentCourses[selectedCourseId];

    // Obtain messageToSend
    let messageToSend = useSelector((state) => state.chat.messageToSend);

    // Obtain IDToConversation from state
    const IDToConversation = useSelector((state) => state.chat.IDToConversation);
    const currentLessonGroup = useSelector((state) => state.chat.currentLessonGroup);
    const top4LessonGroups = useSelector((state) => state.chat.top4LessonGroups);

    // Maximum file size
    const MAX_FILE_SIZE = 50 * 1024 * 1024;

    const fileInputRef = useRef(null);

    const [showUploadFileDialog, setShowUploadFileDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);

    const handleFileChange = async (event) => {
        customLog("HANDLEFILECHANGE called");
        dispatch(setDrawerOpen(false));
        setIsPaneExpanded(true);
        const file = event.target.files[0];
        if (file) {
            customLog("IF (FILE) in handlefilechange");
            // Log file information
            customLog(`File name: ${file.name}`);
            customLog(`File size: ${file.size} bytes`);
            customLog(`File type: ${file.type}`);
            if (file.size > MAX_FILE_SIZE) {
                dispatch(setGeneralSnackbarMessage("File size exceeds 50MB limit"));
                dispatch(setGeneralSnackbarOpen(true));
                return;
            }
            // setSelectedFile(file);
            // setFilePreview(URL.createObjectURL(file));
            // setShowUploadFileDialog(true);
            dispatch(setGeneralSnackbarMessage("Plato's reading your file!"));
            dispatch(setGeneralSnackbarOpen(true));

            // if (!currentConversationID) {
            //     customLog('shortTermMemory, no conversationID - one being created');
            //     try { //TODO: Replicated code from chat.js, story in central place & use
            //         const conversationResponse = await fetch(DjangoHost+"/api/conversation/", {
            //             method: "POST",
            //             headers: { "Content-Type": "application/json",
            //                         Authorization:  `Bearer ${APIToken}`},
            //             body: JSON.stringify({course_id: selectedCourseId})                     
            //             });
            //         const data = await conversationResponse.json();
            //         if (conversationResponse.ok) {
            //             // console.log(data);
            //             customLog("HANDLEFILECHANGE conversation ID successfully made");
            //             let conversation_id = data.conversation_id;
            //             dispatch(setConversationID(conversation_id));

            //             // dispatch(addNewChat(conversation_id));
            //             // console.log('conversation_id right before createChatTitle dispatch is ' + conversation_id);
            //         } else {
            //             // console.log('error');
            //             customLog("HANDLEFILECHANGE conversation ID failed");
            //         }
                    
            //         } catch (error) {
            //         console.error("Error:", error);
            //         alert("Error", "Failed to register");
            //     }
            // }

            // // dispatch(uploadLongtermFileAPI(file));

            // // Assuming uploadContextFileAPI expects the file as its argument
            // dispatch(uploadContextFileAPI(file))
            //     .unwrap()
            //     .then(() => {
            //         // console.log("File uploaded successfully");
            //         // Handle success, maybe close the dialog or show a success message
            //     })
            //     .catch((error) => {
            //         console.error("Failed to upload file: ", error);
            //         // Handle error, maybe show an error message
            //     });
            // Call createLesson with the file
            //createLesson({file: file});
            setLessonCreationContent(file);
        }
    };

    const handleFileUploadClick = () => {
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
        } else {
            fileInputRef.current.click();
        }
    };

    const handleLinkClick = (uuid) => {
        customLog(`Link clicked : ${uuid}`);
        // dispatch(displayInTextFile({fileURL: href}));
        dispatch(getFileURLFromUUID({fileUUID: uuid}));
    }

    // Obtain chatTitle
    let selectedChatTitle = useSelector((state) => state.chat.selectedChatTitle);

    // Obtain selectedCourse
    let selectedCourse = useSelector((state) => state.chat.selectedCourse);

    //Obtain current conversation ID
    let currentConversationID = useSelector((state) => state.chat.currentConversationID);
    let currentTopicIndex = useSelector((state) => state.chat.currentTopicIndex);
    let guidedLessonModeActive = useSelector((state) => state.chat.guidedLessonModeActive);
    let currentLessonID = useSelector((state) => state.chat.currentLessonID);
    const resumeScreenOpen = useSelector((state) => state.chat.resumeScreenOpen);
    const resumeExploreRecommendations = useSelector((state) => state.chat.resumeExploreRecommendations);
    
    const [showSettingsDialog, setShowSettingsDialog] = useState(false);

    // Obtain streamedMessages from redux state
    let streamedMessages = useSelector((state) => state.chat.streamedMessages);
    
    // State for if course is being created
    const creatingCourse = useSelector((state) => state.chat.creatingCourse);

    // State for if flashcardsScreen is open
    const flashcardsScreenOpen = useSelector((state) => state.chat.flashcardsScreenOpen);

    // State for if memoryScreenOpen
    const memoryScreenOpen = useSelector((state) => state.chat.memoryScreenOpen);

    // State for if in code block
    const [inCodeBlock, setInCodeBlock] = useState(false);

    // State for current langugage for code block
    const [currentLanguage, setCurrentLanguage] = useState('text');

    // State for if quizMeScreen is open
    const quizMeScreenOpen = useSelector((state) => state.chat.quizMeScreenOpen);

    // State for flashcards & QuizMe dialog
    const [showFlashcardsDialog, setShowFlashcardsDialog] = useState(false);
    // const [showQuizMeDialog, setShowQuizMeDialog] = useState(false);


    // Add a state for tracking window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //rows
    // const [rows, setRows] = useState(1);
    // const textFieldRowHeight = 22; // Height of one row in the textfield TODO: Find better values for these
    // const textFieldPadding = 30; // Total vertical padding inside the textfield

    // const calculateTextFieldHeight = (rows) => {
    //     return rows * textFieldRowHeight + textFieldPadding;
    // };

    // const [textFieldHeight, setTextFieldHeight] = useState(calculateTextFieldHeight(rows));

    // const handleChange = (event) => {
        
    //     // setInput(event.target.value);
    //     // inputRef.current = event.target.value;
    //     if (event.key === 'Enter') {
    //         //event.preventDefault(); // Prevent the default action to avoid adding a new line
    //         //document.getElementById('messageForm').dispatchEvent(new Event('submit', {cancelable: true})); // Manually trigger form submission
    //         // handleSend;
    //     }
    // };

    // const [messages, setMessages] = useState([]);
    // const [input, setInput] = useState("");
    // const inputRef = useRef('');
    const [storedInput, setStoredInput] = useState("");
    const [loading, setLoading] = useState(false);

    //State for menus
    const [coursePopover, setCoursePopover] = useState(null);
    const [userPopover, setUserPopover] = useState(null);


    // //Effect for setting no chat plato image bottom height
    // useEffect(() => {
    //     // Calculate the bottom position of the no chat Plato image to align it with the top of the form-style input textfield
    //     // This example assumes you want to place the bottom of the image exactly at the top of the textfield,
    //     // adjust the calculation based on your actual layout, margins, and paddings
    //     const newPlatoImageBottom = textFieldHeight;/* your calculation here, based on textFieldHeight and any other relevant layout measurements */;
    //     setPlatoImageBottom(newPlatoImageBottom);
    // }, [textFieldHeight]);


    



    const handleUserClick = (event) => {
        setUserPopover(event.currentTarget);
    };

    const handleUserClose = () => {
        setUserPopover(null);
    };

    const [resourcePanelWidth, setResourcePanelWidth] = useState('30%');

    const [splitPanePosition, setSplitPanePosition] = useState(window.innerWidth * 0.7);

    // Function to get the initial split pane position
    const getInitialSplitPanePosition = () => window.innerWidth * 0.7;



    const handleDragFinished = useCallback(debounce((newSize) => {
        const newWidth = `${newSize}px`;

    }, 200), []);



    //Helper function to detect language
    const detectLanguage = (code) => {
        const result = hljs.highlightAuto(code);
        return result.language;
    };

    const presetPrompt = useSelector((state) => state.chat.presetPrompt);
    
    // UseEffect where if messageToSend changes, sendOrCreateChatMessageStreaming called
    useEffect(() => {
        if (messageToSend) {
            customLog('useEffect messageToSend called with presetPrompt of ' + presetPrompt);

            // responseRef.current = '';
            setStreamedResponse('');
            sendOrCreateChatMessageStreamingWrapper({presetPrompt: presetPrompt })
        }
    }, [messageToSend]);

    // state variable for presetPrompt streamed response
    const [presetPromptStreamedResponse, setPresetPromptStreamedResponse] = useState("");
    const newMessageRef = useRef(true);
    const sendPresetPrompt = async ({presetPrompt}) => {
        dispatch(setIsStreaming(true));
        newMessageRef.current = true;

        abortControllerRef.current = new AbortController();
        let signal = abortControllerRef.current.signal;
        customLog('SENDpresetPrompt beginning');
        try {
            customLog('SYSTEMPROMPT is ' + systemPrompt);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${APIToken}`);
            var raw;
            customLog('sendpresetPrompt presetPrompt mode true ');
            raw = JSON.stringify({
                "id": currentFlashcardQuiz.id,
                "type": currentFlashcardQuiz.answer ? 1 : 2
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            let accumulatedData = "";
            let endpoint;
            let prefix = '';
            if (presetPrompt === 'hint') {
                endpoint = '/api/message/hint/'
                prefix = '**Hint:** ';
                accumulatedData = '**Hint:** ';
            } else if (presetPrompt === 'explain') {
                endpoint = '/api/message/explain/'
                prefix = '**Explanation:** ';
                accumulatedData = '**Explanation:** ';
            } else if (presetPrompt === 'summary') {
                endpoint = '/api/message/summary/';
                prefix = '**Summary:** ';
                accumulatedData = '**Summary:** ';
            } else if (presetPrompt === 'question') {
                endpoint = '/api/message/question/';
                prefix = '**Question:** ';
                accumulatedData = '**Question:** ';
            } else if (presetPrompt === 'teach') {
                endpoint = 'api/message/teach/';
            }
            fetch(DjangoHost + endpoint, 
                {
                    ...requestOptions,
                    signal
                }
            )
                .then(response => {
                    const reader = response.body.getReader();
                    return new ReadableStream({
                        async start(controller) {
                            let isPrefixAdded = false; // Ensure prefix is only added once
                            while (true) {
                                let { done, value } = await reader.read();
                                if (done) {
                                    customLog('streamedResponse is ' + accumulatedData);
                                    reactChatMessages.forEach((item) =>
                                        customLog('REACTCHATMESSAGES IS ' + JSON.stringify(item))
                                    );
                                    // dispatch(addStreamedMessage({ streamedResponse: accumulatedData }));
                                    dispatch(addPresetPrompt({message: accumulatedData}))
                                    dispatch(setIsStreaming(false));
                                    const newMessage = { user: "Assistant", text: accumulatedData };
                                    customLog('NEWMESSAGE presetPrompt BEFORE reactChatMessages is ' + JSON.stringify(reactChatMessages));
                                    setStreamedResponse('');
                                    setReactChatMessages((prevReactChatMessages) => {
                                        return [...prevReactChatMessages, newMessage];
                                    });
                                    break;
                                }
                                if (cancelStream) {
                                    customLog("CANCELSTREAM is true");
                                    reader.cancel();
                                    break;
                                    setCancelStream(false);
                                }
                                // Add prefix only once at the beginning
                                if (!isPrefixAdded) {
                                    setStreamedResponse((r) => r + prefix);
                                    isPrefixAdded = true;  // Ensure the prefix isn't added again
                                }
                                value = new TextDecoder().decode(value);
                                customLog('presetPromptvalue is ' + value);
                                accumulatedData += value;
                                setStreamedResponse((r) => r + value);
                                // responseRef.current = responseRef.current + value;

                                controller.enqueue(value);
                            }
                            controller.close();
                            reader.releaseLock();
                        }
                    })
                })
                .catch(error => console.log('error', error));
        } catch (error) {
            console.error("Error in sendpresetPrompt:", error);
        }
    };

    // updates chat messages when streamedResponse updates
    useEffect(() => {
        customLog('USEEFFECT presetPromptSTREAMEDRESPONSE');
        if (presetPromptStreamedResponse && presetPromptStreamedResponse.length) {
            setReactChatMessages((prevReactChatMessages) => {
                if (prevReactChatMessages.length === 0) {
                    return [{user: "Assistant", text: presetPromptStreamedResponse}];
                } else {
                    return prevReactChatMessages.map((item, index) => 
                        index === prevReactChatMessages.length - 1
                        ? { user: 'Assistant', text: presetPromptStreamedResponse}
                        : item
                    )
                }
            })
        }

    }, [presetPromptStreamedResponse]);


    
    const handleSettingsClick = () => {
        setShowSettingsDialog(true);
    };
    let ongoingResponse = "";
    // state variable for streamed response
    const [streamedResponse, setStreamedResponse] = useState("");

    const [conversationJustCreated, setConversationJustCreated] = useState(false);

    const conversationJustCreatedRef = useRef(null);

    // useSelector for system prompt
    const systemPrompt = useSelector((state) => state.chat.systemPrompt);

    // ref for bots response
    // const responseRef = useRef('');

    const [cancelStream, setCancelStream] = useState(false);
    let abortion = useSelector((state) => state.chat.abortion);

    // Use effect for if abortion changes, check if isStreaming and if so, call handleAbort
    useEffect(() => {
        if (abortion) {
            customLog('useEffect abortion called with abortion of ' + abortion);
            if (isStreaming) {
                customLog('useEffect abortion isStreaming is true');
                handleAbort();
            }
        }
        dispatch(setAbortion(false));
    }, [abortion]);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    let APIToken = useSelector((state) => state.chat.APIToken);

    const abortControllerRef = useRef(null);
    // const [isStreaming, setIsStreaming] = useState(false);
    const isStreaming = useSelector((state) => state.chat.isStreaming);
    // let abortController = new AbortController();
    // let signal = abortController.signal;
    const handleAbort = () => {
        abortControllerRef.current.abort();
        customLog('HANDLEABORT called');
        // setCancelStream(true);
        dispatch(setIsStreaming(false));
        setTooltipOpen(false);
        customLog('CONVERSATIONJUSTCREATEDREF.CURRENT is ' + conversationJustCreatedRef.current);
        let conversationID = conversationJustCreatedRef.current;
        if (conversationJustCreatedRef.current) {
            dispatch(removeChatAPI({conversationID}));
            // dispatch(handleNewChat());

        }
      };

    // useSelector for quizPrompt
    const quizPrompt = useSelector((state) => state.chat.quizPrompt);


    const [uploadQuizFile, setUploadQuizFile] = useState(null);


    const quizFileUpload = (file) => {  
        setUploadQuizFile(file);
    };

    // useEffect that if quizPrompt changes, it calls createQuiz
    useEffect(() => {
        customLog('useEffect of quizPrompt called with quizPrompt of ' + quizPrompt);
        if (quizPrompt) {
            customLog('flashcard prompt changed and now is ' + quizPrompt);
            createQuiz({message: quizPrompt});
        } else if (uploadQuizFile) {
            if (uploadQuizFile) {
                customLog('uploadedFile is ' + uploadQuizFile);
                createQuiz({file: uploadQuizFile});
        }
    }
    }, [quizPrompt, uploadQuizFile]);

        const createQuiz = async ({message, file}) => {
            customLog('createQuiz called with message ' + message);
            // dispatch(setGeneralSnackbarMessage("Plato's making your quiz!"));
            // dispatch(setGeneralSnackbarOpen(true));
            // dispatch(setCenterPanelLoading(true));
            dispatch(setStreamingCards(true));

            dispatch(setCurrentQuizIndex(0));
            dispatch(setCurrentQuizGroup(null));
            dispatch(setSelectedResourceType('quiz'));

            let firstQuiz = false;
    
            try {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${APIToken}`);
                var raw;
                if (message) {
                    myHeaders.append("Content-Type", "application/json");
                    raw = JSON.stringify({
                        "message": message,
                    });
                } else {
                    raw = new FormData();
                    raw.append('file', file);
                }
                let quizGroup = {
                    name: '',
                    id: '',   
                    quiz_questions: [],
                };
    
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
    
                let accumulatedData = "";
                fetch(DjangoHost + "/api/quiz/", requestOptions)
                    .then(response => {
                        const reader = response.body.getReader();
                        return new ReadableStream({
                            async start(controller) {
                                while (true) {
                                    let { done, value } = await reader.read();
                                    if (done) {
                                        customLog('streamedQuiz is ' + accumulatedData);
                                        dispatch(setStreamingCards(false));
                                        break;
                                    }
                                    accumulatedData = "";
                                    value = new TextDecoder().decode(value);
                                    customLog('value is ' + value);
                                    accumulatedData += value;
                                    customLog('accumulatedData is ' + accumulatedData);

                                    // Parse and process the accumulated data
                                    while (value.length > 0) {
                                        customLog('WHILE ACCUMULATEDDATA beginning');
                                        try {
                                            customLog('BEFORE PARSING')
                                            const parsedData = JSON.parse(value);
                                            processQuizData(parsedData, quizGroup, dispatch);
                                            
                                            // Clear the processed data
                                            value = "";
                                        } catch (error) {
                                            // If parsing fails, it might be an incomplete JSON object
                                            // We'll wait for more data
                                            break;
                                        }
                                    }
                                }
                                controller.close();
                                reader.releaseLock();
                            }
                        })
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error("Error in createQuiz:", error);
            }
        };


        const currentQuizID = useSelector((state) => state.chat.currentQuizID);


        const extendQuiz = async ({quizGroupID}) => {
            customLog('extendQuiz called with quizGroupID ' + quizGroupID);

            dispatch(setGeneralSnackbarMessage("Plato's making more!"));
            dispatch(setGeneralSnackbarOpen(true));
            dispatch(setStreamingCards(true));

            let firstQuiz = true;
    
            try {
                var myHeaders = new Headers();
                // myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${APIToken}`);
                var raw;
                myHeaders.append("Content-Type", "application/json");
                raw = JSON.stringify({
                    "quiz_id": quizGroupID,
                })
    
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
    
                let accumulatedData = "";
                fetch(DjangoHost + "/api/quiz/extend/", 
                    {
                        ...requestOptions
                    }
                )
                    .then(response => {
                        const reader = response.body.getReader();
                        return new ReadableStream({
                            async start(controller) {
                                while (true) {
                                    let { done, value } = await reader.read();
                                    if (done) {
                                        customLog('streamedQuiz is ' + accumulatedData);
                                        dispatch(setStreamingCards(false));
                                        break;
                                    }
                                    accumulatedData = "";
                                    value = new TextDecoder().decode(value);
                                    customLog('value is ' + value);
                                    accumulatedData += value;
                                    customLog('accumulatedData is ' + accumulatedData);

                                    // Parse and process the accumulated data
                                    while (value.length > 0) {
                                        customLog('WHILE ACCUMULATEDDATA beginning');
                                        try {
                                            customLog('BEFORE PARSING')
                                            const parsedData = JSON.parse(value);
                                            customLog('QUIZSTREAM parsedData is ');
                                            
                                            if (parsedData.question && parsedData.choices) {
                                                customLog('QUIZSTREAM make formatted question');

                                                customLog('PARSEDDATA FROM EXTENDQUIZ IS ' + JSON.stringify(parsedData));
                                                dispatch(appendQuizQuestionToGroup({parsedData, incrementCard: firstQuiz}));

                                                if (firstQuiz) {
                                                    // dispatch(changeFlashcardQuizIndex(1));
                                                    firstQuiz = false;
                                                }
                                            }
                                            
                                            // Clear the processed data
                                            value = "";
                                        } catch (error) {
                                            // If parsing fails, it might be an incomplete JSON object
                                            // We'll wait for more data
                                            break;
                                        }
                                    }
                                }
                                controller.close();
                                reader.releaseLock();
                            }
                        })
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error("Error in createQuiz:", error);
            }
        };

      // --------------------------------------------------------------------
    
      let flashcardText = '';

    // Flashcard UseSelectors
    const flashcardPrompt = useSelector((state) => state.chat.flashcardPrompt);
    const currentFlashcardGroup = useSelector((state) => state.chat.currentFlashcardGroup);
    
    // Quiz UseSelectors
    const currentQuizGroup = useSelector((state) => state.chat.currentQuizGroup);
    // const flashcardArray = useSelector((state) => state.chat.flashcardArray);

    const flashcards = useSelector((state) => state.chat.flashcards);
    useEffect(() => {
        customLog('flashcards now is ' + flashcards);
    }, [flashcards]);
    const quiz = useSelector((state) => state.chat.quiz);

    // State to hold the uploaded file
    const [uploadedFlashcardFile, setUploadedFlashcardFile] = useState(null);

    const currentFlashcardQuizIndex = useSelector((state) => state.chat.currentFlashcardQuizIndex);
    const currentFlashcardQuiz = useSelector((state) => state.chat.currentFlashcardQuiz);

    // useEffect that if flashcardPrompt changes, it calls createFlashcards
    useEffect(() => {
        customLog('useEffect of flashcardPrompt called with flashcardPrompt of ' + flashcardPrompt);
        if (flashcardPrompt) {
            customLog('flashcard prompt changed and now is ' + flashcardPrompt);
            createFlashcards({message: flashcardPrompt});
        } else if (uploadedFlashcardFile){
            if (uploadedFlashcardFile) {
                customLog('uploadedFile is ' + uploadedFlashcardFile);
                createFlashcards({file: uploadedFlashcardFile});
        }
    }
    }, [flashcardPrompt, uploadedFlashcardFile]);

        // Function to handle file update from child
        const flashcardFileUpload = (file) => {
            setUploadedFlashcardFile(file);
            // You can also perform any additional processing here
            console.log('File uploaded:', file);
        };

        const [extendFlashcardGroup, setExtendFlashcardGroup] = useState(false);
        const currentFlashcardGroupID = useSelector((state) => state.chat.currentFlashcardGroupID);
        useEffect(() => {
            if (extendFlashcardGroup) {
                customLog('USEEFFECT EXTENDFLASHCARDGROUP with group id of ' + currentFlashcardGroupID);
                extendFlashcards({flashcardGroupID: currentFlashcardGroupID});
                setExtendFlashcardGroup(false);
            }
        }, [extendFlashcardGroup]);


        useEffect(() => {
            customLog('USEEFFECT CURRENTFLASHCARDGROUP forcing rerender');

        }, [currentFlashcardGroup]);
        



        const extendFlashcards = async ({flashcardGroupID}) => {
            customLog('extendFlashcards called with group ID ' + flashcardGroupID);
            dispatch(setGeneralSnackbarMessage("Plato's making more!"));
            dispatch(setGeneralSnackbarOpen(true));
            dispatch(setStreamingCards(true));
            let firstCard = true;
    
            try {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${APIToken}`);
                var raw;
                myHeaders.append("Content-Type", "application/json");
                raw = JSON.stringify({
                    "flashcard_group_id": flashcardGroupID,
                })
                

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
    
                let accumulatedData = "";
                fetch(DjangoHost + "/api/flashcards/extend/", 
                    {
                        ...requestOptions
                    }
                )
                    .then(response => {
                        const reader = response.body.getReader();
                        return new ReadableStream({
                            async start(controller) {
                                while (true) {
                                    let { done, value } = await reader.read();
                                    if (done) {
                                        customLog('streamedFlashcard is ' + accumulatedData);
                                        dispatch(setStreamingCards(false));
                                        break;
                                    }
                                    value = new TextDecoder().decode(value);
                                    customLog('value is ' + value);
                                    accumulatedData += value;

                                    // Parse and process the accumulated data
                                    let startIndex = 0;
                                    while (true) {
                                        const endIndex = accumulatedData.indexOf('}', startIndex);
                                        if (endIndex === -1) break;

                                        const jsonStr = accumulatedData.substring(startIndex, endIndex + 1);
                                        try {
                                            const flashcard = JSON.parse(jsonStr);
                                            if (!flashcard.owner_id) {
                                                // dispatch(appendFlashcard(flashcard));
                                                customLog('EXTENDFLASHCARDS being appended to group is ' + JSON.stringify(flashcard));
                                                dispatch(getAppendFlashcardToGroup({flashcard, incrementCard: firstCard}));
                                            }
                                            if (firstCard) {
                                                // dispatch(changeCurrentFlashcardIndex(1));
                                                firstCard = false;
                                            }
                                            startIndex = endIndex + 1;
                                        } catch (error) {
                                            // If parsing fails, it might be an incomplete JSON object
                                            break;
                                        }
                                    }


                                    // Remove processed data from accumulatedData
                                    accumulatedData = accumulatedData.slice(startIndex);

                                    controller.enqueue(value);
                                }
                                controller.close();
                                reader.releaseLock();
                            }
                        })
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error("Error in extendFlashcards:", error);
            }
        };

        const retrieveFlashcards = async ({flashcardGroup}) => {
            customLog('retrieveFlashcards called with flashcardGroup ' + flashcardGroup);


            dispatch(setCurrentFlashcardIndex(0));
            dispatch(setCurrentFlashcardGroup(null));
            dispatch(setSelectedResourceType('flashcards'));
            dispatch(setCurrentQuizGroup(null));

            dispatch(setCurrentFlashcardGroupID(flashcardGroup.id));
            dispatch(setCurrentFlashcardGroup(flashcardGroup));
            dispatch(setSelectedResource({id: 'flashcard', type: 'flashcards', title: 'flashy', url: 'na'}));
            // dispatch(setStreamingCards(false));

            };

        const retrieveQuiz = async ({quizGroup}) => {
            customLog('retrieveQuiz called with quizGroup ' + quizGroup);

            dispatch(setCurrentQuizIndex(0));
            dispatch(setCurrentQuizGroup(null));
            dispatch(setSelectedResourceType('quiz'));
            dispatch(setCurrentFlashcardGroup(null));

            dispatch(setCurrentQuizID(quizGroup.id));
            dispatch(setCurrentQuizGroup(quizGroup));
            dispatch(setSelectedResource({id: 'quiz', type: 'quizzes', title: 'flashy', url: 'na'}));
            // dispatch(setStreamingCards(false));

            };


        const createFlashcards = async ({message, file}) => {
            customLog('createFlashcards called with message ' + message);

            dispatch(setGeneralSnackbarMessage("Plato's making your flashcards!"));
            dispatch(setGeneralSnackbarOpen(true));
            dispatch(setStreamingCards(true));

            dispatch(setCurrentFlashcardIndex(0));
            dispatch(setCurrentFlashcardGroup(null));
            dispatch(setSelectedResourceType('flashcards'));
            dispatch(setCurrentQuizGroup(null));

    
            try {
                var myHeaders = new Headers();
                // myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${APIToken}`);
                var raw;
                if (message) {
                    myHeaders.append("Content-Type", "application/json");
                    raw = JSON.stringify({
                        "message": message,
                    });
                } else {
                    raw = new FormData();
                    raw.append('file', file);
                }

                let firstCard = true;

    
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
    
                let accumulatedData = "";
                fetch(DjangoHost + "/api/flashcards/", 
                    {
                        ...requestOptions
                        // signal
                    }
                )
                    .then(response => {
                        const reader = response.body.getReader();
                        return new ReadableStream({
                            async start(controller) {
                                while (true) {
                                    let { done, value } = await reader.read();
                                    if (done) {
                                        customLog('streamedFlashcard is ' + accumulatedData);

                                        dispatch(setStreamingCards(false));
                                        break;
                                    }
                                    value = new TextDecoder().decode(value);
                                    customLog('value is ' + value);
                                    accumulatedData += value;

                                    // Parse and process the accumulated data
                                    let startIndex = 0;
                                    while (true) {
                                        const endIndex = accumulatedData.indexOf('}', startIndex);
                                        if (endIndex === -1) break;

                                        const jsonStr = accumulatedData.substring(startIndex, endIndex + 1);
                                        try {
                                            const flashcard = JSON.parse(jsonStr);
                                            if (flashcard.question) {
                                                customLog('!FLASHCARD.OWNER_ID within createFlashcards');
                                                // dispatch(addFlashcard(flashcard));
                                                dispatch(getAppendFlashcardToGroup({flashcard, incrementCard: false}));
                                            } else if (flashcard.id) {
                                                customLog('CREATEFLASHCARDS ELSE with group id of ' + flashcard.id);
                                                
                                                dispatch(setCurrentFlashcardGroupID(flashcard.id));
                                                const newGroup = {
                                                    name: flashcard.name || 'Placeholder',
                                                    id: flashcard.id,
                                                    flashcards: [],
                                                };
                                                // dispatch(prependFlashcardGroup(newGroup));
                                                dispatch(setCurrentFlashcardGroup(newGroup));
                                                dispatch(setSelectedResource({id: 'flashcard', type: 'flashcards', title: 'flashy', url: 'na'}));
                                            } else if (flashcard.name) {
                                                dispatch(editFlashcardGroupName(flashcard.name));
                                            }

                                            startIndex = endIndex + 1;
                                        } catch (error) {
                                            // If parsing fails, it might be an incomplete JSON object
                                            break;
                                        }
                                    }
                                    // Remove processed data from accumulatedData
                                    accumulatedData = accumulatedData.slice(startIndex);

                                    controller.enqueue(value);
                                }
                                controller.close();
                                reader.releaseLock();
                            }
                        })
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error("Error in createFlashacrds:", error);
            }
        };

    const sendOrCreateChatMessageStreamingWrapper = ({selectedCourse, message, presetPrompt, currentID, lessonID, guidedLessonModeActive}) => {
        return sendOrCreateChatMessageStreaming({
            selectedCourse,
            message, 
            presetPrompt,
            currentID,
            lessonID,
            guidedLessonModeActive,
            props: {
                APIToken,
                systemPrompt,
                selectedCourseId,
                currentConversationID,
                conversationJustCreatedRef,
                cancelStream,
                setCancelStream,
                // responseRef,
                reactChatMessages,
                setReactChatMessages,
                setStreamedResponse,
                selectedResourceType,
                currentLessonGroup,
                currentTopicIndex,
                DjangoHost,
                abortControllerRef
            },
            dispatch
        });
    };


    // useState variable for onHomeScreen
    // const [onHomeScreen, setOnHomeScreen] = useState(true);
    const onHomeScreen = useSelector((state) => state.chat.onHomeScreen);

    // const [timeLimit, setTimeLimit] = useState('15 minutes');
    // const [level, setLevel] = useState(4);
    const timeLimit = useSelector((state) => state.chat.timeLimit);
    const level = useSelector((state) => state.chat.level);

    // Wrapper function that provides necessary props to handleLesson
    const handleLessonWrapper = ({message, file, editedLessonId, editedContent, exploreID, prereqResults}) => {
        // Pass required state and functions to handleLesson
        return handleLesson({
            message,
            file, 
            editedLessonId,
            editedContent,
            exploreID,
            prereqResults,
            props: {
                APIToken,
                timeLimit,
                level,
                setReactChatMessages,
                setStreamedResponse,
                DjangoHost
            },
            dispatch
        });
    };



    // Wrapper functions to maintain the existing API
    const createLesson = ({message, file, exploreID, prereqResults}) => handleLessonWrapper({message, file, exploreID, prereqResults});
    const editLesson = (lessonId, editContent) => handleLessonWrapper({editedLessonId: lessonId, editedContent: editContent});

    //const [isLessonApproved, setIsLessonApproved] = useState(true);



    // Wrapper function that provides setReactChatMessages
    const approveLessonWrapper = () => {
        return approveLesson({setReactChatMessages, dispatch, currentLessonID, setStreamedResponse});
    };

    const currentTopicID = useSelector((state) => state.chat.currentTopicID);
    const currentDisplayedTopicIndex = useSelector((state) => state.chat.currentDisplayedTopicIndex);
    const prereqTestModeActive = useSelector((state) => state.chat.prereqTestModeActive);
    const currentTopicObject = useSelector((state) => state.chat.currentTopicObject);
    const currentDisplayedTopicContent = useSelector((state) => state.chat.currentDisplayedTopicContent);
    // Wrapper function that provides required params to continueLesson
    const continueLessonWrapper = async () => {
        customLog('CONTINUELESSONWRAPPER CALLED with prereqTestModeActive of ' + prereqTestModeActive);
        if (currentTopicObject.conversation.length === currentDisplayedTopicIndex + 1) {
            handleTopicSubmitWrapper();
        } else {
            await continueLesson({
                currentTopicObject,
                currentDisplayedTopicIndex,
                prereqTestModeActive,
                currentTopicID,
                currentTopicIndex,
                dispatch
            });
        }

    };

    const handleTopicSubmitWrapper = () => {
        if (prereqTestModeActive) {
            dispatch(setPrereqTestModeActive(false));
            customLog('HANDLETOPICSUBMIT CURRENTDISPLAYEDTOPICCONTENT IS ' + JSON.stringify(currentDisplayedTopicContent)); 
            createLesson({prereqResults: {
                prerequisite_assessment: {
                    id: currentDisplayedTopicContent.test_id,
                    questions: prereqTestQuestions,
                },
                time_limit: 15,
                level: 4
            }});
        } else {
            handleTopicSubmit({
                currentTopicID,
                dispatch,
                numberCorrectQuestions,
                numberTotalQuestions,
            });
        }
    }
    const numberCorrectQuestions = useSelector((state) => state.chat.numberCorrectQuestions);
    const numberTotalQuestions = useSelector((state) => state.chat.numberTotalQuestions);
    const prereqTestQuestions = useSelector((state) => state.chat.prereqTestQuestions);



    const previousPageWrapper = () => {
        if (currentDisplayedTopicIndex > 0) {
            dispatch(changeCurrentDisplayedTopicIndex(-1));
        }
    };


    const handleStopStreaming = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            dispatch(setIsStreaming(false));
        }
    };

    let streamedResponseObject = {'user': 'Assistant', 'text': streamedResponse};


    useEffect(() => {
        if (isLoggedIn) {
            customLog('useEffect currentConversationID called with currentConversationID of ' + currentConversationID);
            let newMessages;
            if (currentTopicIndex !== null && guidedLessonModeActive) {
                customLog('useEffect currentConversationID currentTopicIndex is ' + currentTopicIndex);
                newMessages = currentLessonGroup.topics[currentTopicIndex]?.conversation?.messages || [];
            } else {
                newMessages = currentConversationID == null ? [] : IDToConversation[selectedCourse]?.[currentConversationID];
            }
            if (newMessages) {
                customLog('useEffect currentConversationID new messages being set is ' + newMessages);
                setReactChatMessages(newMessages);
            }
        }

    }, [currentConversationID, currentTopicIndex]);

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter' && !event.shiftKey) {
    //         event.preventDefault();
    //         handleSendWrapper(event);
    //     }
    // };

    // Wrapper function to provide local state for handleSend
    const handleSendWrapper = async (event, inputMessage) => {
        // Pass through to handleSend with local state/props nee
        customLog('HANDLESENDWRAPPER CALLED WITH INPUTMESSAGE of ' + inputMessage);
        await handleSend({
            event,
            dispatch,
            createLesson,
            sendOrCreateChatMessageStreamingWrapper, 
            inputMessage,
            props: {
                isLoggedIn,
                // responseRef,
                setStreamedResponse,
                guidedLessonModeActive,
                currentLessonID,
                selectedCourseId,
                setReactChatMessages
            }
    });
    };



    //Effect for dynamically updating GUI on toolbar height
    useEffect(() => {
        // Update the toolbar height when the component mounts or the window resizes
        const handleResize = () => {
            if (toolbarRef.current) { // Checks if toolbar is mounted
                setToolbarHeight(toolbarRef.current.clientHeight); //Obtains height of toolbar
            }
        };

        // Call handleResize initially and whenever the window resizes
        window.addEventListener('resize', handleResize);
        handleResize();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //Effect for dynamically updating GUI on appBar height
    useEffect(() => {
        // Update the appBar height when the component mounts or the window resizes
        const handleResize = () => {
            if (appBarRef.current) { // Checks if toolbar is mounted
                setAppBarHeight(appBarRef.current.clientHeight); //Obtains height of toolbar
            }
        };

        // Call handleResize initially and whenever the window resizes
        window.addEventListener('resize', handleResize);
        handleResize();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    //useEffect where on selectedResourceType changing it setDrawerOpen to false
    useEffect(() => {
        customLog('USEEFFECT SELECTEDRESOURCETYPE');
        if (selectedResourceType) {
            dispatch(setDrawerOpen(false));
            setIsPaneExpanded(true);
        }
        
    }, [selectedResourceType])


    const resourcePanelMarginRight = selectedResourceRedux ? '30%' : '0px'; //TODO: changed to redux

    const [dialogOpen, setDialogOpen] = useState(true);

    const handleOptionSelect = (selectedOption) => {
      // Handle the selected option here
      console.log('User selected:', selectedOption);
      //setDialogOpen(false); // Close the dialog after selection
    };
    

    // // Generic Dialog for dialogs on chat interface
    // const GenericDialog = ({ open, handleClose, title, children }) => {
    //     return (
    //       <Dialog open={open} onClose={handleClose}>
    //         <DialogTitle>{title}</DialogTitle>
    //         <DialogContent>
    //           {children}
    //         </DialogContent>
    //         <DialogActions>
    //           <Button onClick={handleClose}>Close</Button>
    //         </DialogActions>
    //       </Dialog>
    //     );
    //   };

    // // UI component for displaying resources
    // const ResourceItem = ({ resource, onSelect, className }) => (
    //     <Tooltip title={resource.title}>
    //         <IconButton onClick={() => onSelect(resource)} className={className} style={{ padding: 0, marginRight: 'none' }}>
    //             <img src={resource.previewImageUrl} alt={resource.title} style={{ width: '150px', height: '150px' }} />
    //         </IconButton>
    //     </Tooltip>
    //   );

    const [isPaneExpanded, setIsPaneExpanded] = useState(true);

    // const togglePane = () => {
    //     setIsPaneExpanded(!isPaneExpanded);
    // };

    // const handleUploadClick = () => {
    //     fileInputRef.current.click();
    // };

    // Add this to your existing useSelectors
    const lessonsScreenOpen = useSelector((state) => state.chat.lessonsScreenOpen);

    // Add this function to handle lesson retrieval
    // const retrieveLesson = async ({lessonGroup}) => {
    //     customLog('RETRIEVELESSON CALLED for lessonGroup ' + JSON.stringify(lessonGroup));
    //     dispatch(setCurrentLessonIndex(0));
    //     dispatch(setCurrentTopicIndex(0));
    //     dispatch(setCurrentLessonID(lessonGroup.id));
    //     dispatch(setOnHomeScreen(false));
    //     dispatch(setCenterPanelVisible(true));
        
    //     dispatch(setCurrentLessonGroup(lessonGroup));
    //     dispatch(setGuidedLessonModeActive(true));
    //     dispatch(setCurrentTopicScore(0));
    // };

    const retrieveLessonChat = async ({lessonGroup}) => {
        customLog('RETRIEVELESSONCHAT CALLED for lessonGroup ' + JSON.stringify(lessonGroup));
        await retrieveLesson({lessonGroup, dispatch});
    };

    // const retrieveFlashcards = async ({flashcardGroup}) => {
    //     customLog('retrieveFlashcards called with flashcardGroup ' + flashcardGroup);


    //     dispatch(setCurrentFlashcardIndex(0));
    //     dispatch(setCurrentFlashcardGroup(null));
    //     dispatch(setSelectedResourceType('flashcards'));
    //     dispatch(setCurrentQuizGroup(null);

    //     dispatch(setCurrentFlashcardGroupID(flashcardGroup.id));
    //     dispatch(setCurrentFlashcardGroup(flashcardGroup));
    //     dispatch(setSelectedResource({id: 'flashcard', type: 'flashcards', title: 'flashy', url: 'na'}));
    //     // dispatch(setStreamingCards(false));

    //     };
    // const continueButtonStyle = {
    //     backgroundColor: 'transparent',
    //     color: "#131F24",
    //     padding: '10px',
    //     borderRadius: '18px',
    //     cursor: 'pointer',
    //     border: '0.5px solid #737164',
    //     background: 'linear-gradient(to top, #93D333, #93D333)',
    //     boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    //     transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    //     fontWeight: 'bold',
    //     textTransform: 'uppercase',
    //     letterSpacing: '1px',
    //     minWidth: '150px',
    //     marginTop: '20px',
    //     marginBottom: '50px'
    // };
    // {!isStreaming && currentDisplayedTopicContent.type === 'text' && (
    //     <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
    //         <Button
    //             onClick={onLessonContinue}
    //             style={continueButtonStyle}
    //         >
    //             Continue
    //         </Button>
    //     </div>
    // )}
    
    const handleResumeUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            dispatch(uploadResumeAPI(file));
        }
    };

    const handleLibraryLessonClick = async (lesson) => {
        customLog('handleLibraryLessonClick called with lesson ' + JSON.stringify(lesson));
        await dispatch(setCurrentLessonGroup({lesson}));
        dispatch(setLibraryPageActive(false));
        setReactChatMessages([]);
        dispatch(setIsStreaming(false));
        dispatch(setCurrentDisplayedTopicContent({}));
        dispatch(setCurrentTopicIndex(0));
        dispatch(setOnHomeScreen(false));
        dispatch(setCenterPanelVisible(true));
    };

    const [lessonCreationContent, setLessonCreationContent] = useState(null);
    const handleLessonCreation = (content) => {
        setLessonCreationContent(content);
        dispatch(setOnHomeScreen(false));
        dispatch(setCenterPanelVisible(true));
        dispatch(setPrereqTestOptionScreenOpen(true));
    }

    //const [centerPanelVisible, setCenterPanelVisible] = useState(false);
    //const selectedResourceType = useSelector((state) => state.chat.selectedResourceType);
    const centerPanelVisible = useSelector((state) => state.chat.centerPanelVisible);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflowY: 'auto' }}>
        
            <TopAppBar 
              isMobile={false}
            />
            {/* <Tooltip title="Share Chat">
                <IconButton style={{ position: 'fixed', top: 10, right: 10, zIndex: 1000 }}>
                    <ShareIcon />
                </IconButton>
            </Tooltip> */}
            {/* <TopLeftDrawer
                setIsPaneExpanded={setIsPaneExpanded}
            /> */}
            {/* {!centerPanelVisible && <BottomLeftDrawer
                setIsPaneExpanded={setIsPaneExpanded}
                retrieveLesson={retrieveLessonChat}
            />} */}
            <LoginSignupDialogComponent/>
            <FakeLoginSignupDialogComponent/>
            {/* <OptionSelectionDialog 
                open={dialogOpen} 
                setDialogOpen={setDialogOpen} 
                onOptionSelect={handleOptionSelect} 
                onUploadClick={handleUploadClick}
            /> */}
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            {flashcardsScreenOpen && (
                <CreateOrLibrary
                    open={flashcardsScreenOpen}
                    onClose={() => dispatch(setFlashcardsScreenOpen(false))}
                    type="flashcards"
                    onFlashcardFileUpload={flashcardFileUpload}
                    createFlashcards={createFlashcards}
                    retrieveFlashcards={retrieveFlashcards}
                    setIsPaneExpanded={setIsPaneExpanded}
                />
            )}
            {quizMeScreenOpen && (
                <CreateOrLibrary
                    open={quizMeScreenOpen}
                    onClose={() => dispatch(setQuizMeScreenOpen(false))}
                    type="quizme"
                    onQuizFileUpload={quizFileUpload}
                    createQuiz={createQuiz}
                    retrieveQuiz={retrieveQuiz}
                    setIsPaneExpanded={setIsPaneExpanded}
                />
            )}
            {memoryScreenOpen && (
                <CreateOrLibrary
                    open={memoryScreenOpen}
                    onClose={() => dispatch(setMemoryScreenOpen(false))}
                    type="memory"
                    retrieveMemory={handleLinkClick}
                    setIsPaneExpanded={setIsPaneExpanded}
                />
            )}
            {lessonsScreenOpen && (
                <CreateOrLibrary
                    open={lessonsScreenOpen}
                    onClose={() => dispatch(setLessonsScreenOpen(false))}
                    type="lessons"
                    createLesson={createLesson}
                    retrieveLesson={retrieveLessonChat}
                    setIsPaneExpanded={setIsPaneExpanded}
                />
            )}
            {centerPanelVisible ? (
                <CenteredResourceView
                    sendOrCreateChatMessageStreaming={sendOrCreateChatMessageStreamingWrapper}
                    //resource={selectedResourceRedux}
                    extendQuiz={extendQuiz}
                    extendFlashcards={extendFlashcards}
                    lessonCreationContent={lessonCreationContent}
                    createLesson={createLesson}
                    createPrereqQuiz={createPrereqQuiz}
                    // quiz={quiz}
                    // currentFlashcardQuizIndex={currentFlashcardQuizIndex}
                    // currentFlashcardQuiz={currentFlashcardQuiz}
                    continueLesson={continueLessonWrapper}
                    previousPage={previousPageWrapper}
                    messages={reactChatMessages}
                    streamedResponse={streamedResponse}
                    username={firstName || username}
                    platoImage={platoImage}
                    stickman={profilePicture || stickman}
                    onLinkClick={handleLinkClick}
                    onLessonApproved={approveLessonWrapper}
                    onLessonEdited={editLesson}
                    onLessonContinue={continueLessonWrapper}
                    isStreaming={isStreaming}
                    handleSend={handleSendWrapper}
                    handleFileChange={handleFileChange}
                    handleAbort={handleAbort}
                    isMobile={false}
                />
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                    {resumeScreenOpen && (
                        <Dialog
                            open={resumeScreenOpen}
                            onClose={() => dispatch(setResumeScreenOpen(false))}
                            PaperProps={{
                                style: {
                                    backgroundColor: themeData.color9,
                                    borderRadius: '15px',
                                    padding: '20px',
                                    maxWidth: '500px',
                                    width: '90%'
                                }
                            }}
                        >
                            <DialogTitle style={{ position: 'relative' }}>
                                <IconButton
                                    onClick={() => dispatch(setResumeScreenOpen(false))}
                                    style={{
                                        position: 'absolute',
                                        right: '-10px',
                                        top: '-10px',
                                        color: themeData.color1
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <div style={{
                                    color: themeData.color1,
                                    fontSize: '24px',
                                    fontWeight: '500',
                                    textAlign: 'center'
                                }}>
                                    Personalize Plato to you
                                </div>
                            </DialogTitle>
                            <DialogContent style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '20px'
                            }}>
                                <input
                                    type="file"
                                    id="resumeUpload"
                                    style={{ display: 'none' }}
                                    onChange={handleResumeUpload}
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => document.getElementById('resumeUpload').click()}
                                    style={{
                                        backgroundColor: themeData.color1,
                                        color: themeData.color9,
                                        padding: '15px 30px',
                                        borderRadius: '25px',
                                        textTransform: 'none',
                                        fontSize: '16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <FileUploadIcon style={{ fontSize: '24px' }} />
                                    Upload Resume
                                </Button>
                            </DialogContent>
                        </Dialog>
                    )},
                    {libraryPageActive ? (
                        <div style={{ maxWidth: '1200px', margin: '100px auto 0', padding: '0px', position: 'relative', zIndex: 1, height: '100%', marginTop: '200px' }}>
                            <div style={{ 
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                                gap: '16px',
                                padding: '0 0px',
                                maxWidth: '1200px',
                                margin: '0 auto'
                            }}>
                                {allLessonGroups.map((lesson, index) => (
                                    <div 
                                        key={index}
                                        onClick={() => retrieveLessonChat({lessonGroup: lesson})}
                                        style={{
                                            position: 'relative',
                                            height: '180px',
                                            borderRadius: '15px',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                                        }}>
                                        <img 
                                            src={lesson.image_url || [attention, electoral, sandwich, lobster][index % 4]}
                                            alt={lesson.name}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                opacity: '0.75',
                                                transition: 'transform 0.3s ease',
                                            }}
                                        />
                                        <div style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: 'linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.6))',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            padding: '20px'
                                        }}>
                                            <span style={{ 
                                                color: '#fff',
                                                fontSize: '16px',
                                                fontWeight: '500',
                                                textAlign: 'left',
                                                textShadow: '1px 1px 3px rgba(0,0,0,0.3)'
                                            }}>{lesson.name}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : onHomeScreen ? (
                        <>
                        <div className="centered-plato-container">
                            <div style={{
                                fontSize: '31.415px',
                                fontWeight: '400',
                                color: themeData.color1,
                                marginBottom: '16px',
                                textAlign: 'center',
                                fontFamily: "'Roboto', sans-serif",
                                lineHeight: '1.5',
                                background: 'transparent',
                                transition: 'all 0.3s ease',
                            }}>
                                Learn anything fast
                            </div>
                            <img src={platoImage} alt="Plato" className="centered-plato" />
                            <div className="form-style">
                                <InputForm
                                    // handleSend={handleSendWrapper}
                                    handleSend={handleLessonCreation}
                                    handleFileChange={handleFileChange}
                                    handleAbort={handleAbort}
                                />
                            </div>
                            <div style={{ textAlign: 'center', maxWidth: '900px', margin: '0 auto' }}>
                                <div style={{ maxWidth: '100%', margin: '0 auto' }}>
                                    {resumeExploreRecommendations && resumeExploreRecommendations.length > 0 ? (
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '16px', margin: '0 auto' }}>
                                            {resumeExploreRecommendations.slice(0, 4).map((item, index) => (
                                                <button 
                                                    key={index} 
                                                    onClick={() => createLesson({exploreID: item.id})}
                                                    style={{
                                                        //padding: '10px',
                                                        fontSize: '15.264px',
                                                        color: themeData.color1,
                                                        backgroundColor: themeData.color9,
                                                        border: 'none',
                                                        borderRadius: '25px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, box-shadow 0.3s',
                                                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.05)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        height: '60px',
                                                        width: '100%',
                                                    }}>
                                                    <PictureAsPdfIcon style={{ marginRight: '6px', fontSize: '18px', flexShrink: 0 }} />
                                                    <span style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name}</span>
                                                </button>
                                            ))}
                                        </div>
                                    ) : (
                                        <div style={{ 
                                            display: 'flex',
                                            gap: '16px', 
                                            margin: '0 auto',
                                            padding: '0 0px',
                                            overflowX: 'auto'
                                        }}>
                                            {[
                                                { text: 'Attention Is All You Need', image: attention, exploreId: 1 },
                                                { text: 'Electoral College System', image: electoral, exploreId: 2 },
                                                { text: 'History of Sandwiches', image: sandwich, exploreId: 3 },
                                                { text: 'Consider the Lobster', image: lobster, exploreId: 4 }
                                            ].map(({ text, image, exploreId }, index) => (
                                                <div 
                                                    key={index} 
                                                    onClick={() => createLesson({exploreID: exploreId})}
                                                    style={{
                                                        position: 'relative',
                                                        height: '180px',
                                                        width: '175px',
                                                        flexShrink: 0,
                                                        borderRadius: '15px',
                                                        cursor: 'pointer',
                                                        overflow: 'hidden',
                                                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                                                    }}>
                                                    <img 
                                                        src={image} 
                                                        alt={text}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            opacity: '0.75',
                                                            transition: 'transform 0.3s ease',
                                                        }}
                                                    />
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        background: 'linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.6))',
                                                        display: 'flex',
                                                        alignItems: 'flex-end',
                                                        padding: '16px'//multi-layer perceptrons, recurrent neural networks
                                                    }}>
                                                        <span style={{ 
                                                            color: '#fff',
                                                            fontSize: '16px',
                                                            fontWeight: '500',
                                                            textAlign: 'left',
                                                            textShadow: '1px 1px 3px rgba(0,0,0,0.3)'
                                                        }}>{text}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{ 
                            position: 'fixed', 
                            bottom: '10px', 
                            left: '10px', 
                            right: '10px', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '12px',
                            color: themeData.color5,
                            padding: '5px 10px',
                            zIndex: 1000,
                            gap: '5px'
                        }}>
                            <span style={{ fontSize: '12px' }}>©</span>
                            <span style={{ fontSize: '12px' }}>MyPlatoAI, Company</span>
                            <span>•</span>
                            <Button 
                                onClick={() => navigate('/legal/terms-of-use')}
                                style={{ 
                                    fontSize: '12px',
                                    color: 'inherit',
                                    textTransform: 'none',
                                    padding: 0,
                                    minWidth: 'auto'
                                }}
                            >
                                Terms of Service
                            </Button>
                            <span>•</span>
                            <Button 
                                onClick={() => navigate('/legal/privacy-policy')}
                                style={{ 
                                    fontSize: '12px',
                                    color: 'inherit',
                                    textTransform: 'none',
                                    padding: 0,
                                    minWidth: 'auto'
                                }}
                            >
                                Privacy Policy
                            </Button>
                            <span>•</span>
                            <div style={{ position: 'relative' }}>
                                <Button 
                                    onClick={() => {
                                        const dialog = document.getElementById('contact-dialog');
                                        dialog.style.display = dialog.style.display === 'none' ? 'block' : 'none';
                                    }}
                                    style={{ 
                                        fontSize: '12px',
                                        color: 'inherit',
                                        textTransform: 'none',
                                        padding: 0,
                                        minWidth: 'auto'
                                    }}
                                >
                                    Contact Us
                                </Button>
                                <div 
                                    id="contact-dialog" 
                                    style={{
                                        display: 'none',
                                        position: 'absolute',
                                        bottom: '100%',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'white',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                        zIndex: 1000
                                    }}
                                >
                                    Email: steven@myplato.ai
                                </div>
                            </div>
                            <span>•</span>
                            <IconButton href="https://x.com/myplatoai" target="_blank" rel="noopener noreferrer" size="small">
                                <img src={XLogo} alt="X Logo" style={{ width: '12px', height: '12px' }} />
                            </IconButton>
                        </div>
                        </>
                    ) : (
                        <SplitPane 
                            split="vertical"
                            allowResize={true}
                            minSize={400}
                            maxSize={-450}
                            defaultSize={600}
                            onChange={(size) => {
                                customLog('size is ' + size);
                                customLog('windowWidth is ' + windowWidth);
                                customLog('drawerWidth is ' + drawerWidth);
                                if ((size - 700)/2 < 180) {
                                    dispatch(setDrawerOpen(false));
                                }
                            }}
                            size={!selectedResourceType ? '100%' : (isPaneExpanded ? '60%' : '100%')}
                            onDragStarted={() => dispatch(setIframeDragged(true))}
                            onDragFinished={(newSize) => {
                                dispatch(setIframeDragged(false));
                            }}
                            pane1Style={{overflow: 'none'}}
                            className={!selectedResourceType ? 'hide-resizer' : ''}
                        >
                            <main 
                                className="main" 
                                style={{ backgroundColor: 'transparent' }}
                            > 
                                {reactChatMessages.length === 0  && (//&& isLessonApproved
                                    <div className="no-chat-plato-container">
                                        <img src={platoImage} alt="Plato" className="no-chat-plato"/> 
                                    </div>
                                )}
                                <div className="chat-container">
                                    <div className="chat-area">
                                        <PreviousMessages 
                                            messages={reactChatMessages}
                                            username={firstName || username}
                                            platoImage={platoImage}
                                            stickman={profilePicture || stickman}
                                            onLinkClick={handleLinkClick}
                                            onLessonApproved={approveLessonWrapper}
                                            onLessonEdited={editLesson}
                                            onLessonContinue={continueLessonWrapper}
                                        />
                                        {streamedResponseObject && (
                                            <StreamingMessage 
                                                message={streamedResponseObject}
                                                username={firstName || username}
                                                platoImage={platoImage}
                                                stickman={profilePicture || stickman}
                                                onLinkClick={handleLinkClick}
                                                onLessonApproved={approveLessonWrapper}
                                                onLessonEdited={editLesson}
                                                onLessonContinue={continueLessonWrapper}
                                            />
                                        )}
                                    </div>
                                    <div className="form-style">
                                        {//loading ? (
                                           // <CircularProgress />
                                        //) : 
                                        (
                                             (//(!isLessonApproved) ? null :
                                                <InputForm
                                                    handleSend={handleSendWrapper}
                                                    themeData={themeData}
                                                    loading={loading}
                                                    isStreaming={isStreaming}
                                                    handleFileUploadClick={handleFileUploadClick}
                                                    handleFileChange={handleFileChange}
                                                    handleAbort={handleAbort}
                                                    //guidedLessonModeActive={guidedLessonModeActive}
                                                />
                                            )
                                        )}
                                    </div>
                                </div>
                                {selectedResourceType && !isPaneExpanded && (
                                    <IconButton 
                                        onClick={() => {
                                            setIsPaneExpanded(true);
                                            dispatch(setDrawerOpen(false));
                                        }}
                                        style={{
                                            position: 'absolute',
                                            right: '0px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            zIndex: 1000
                                        }}
                                    >
                                        <ExpandLessIcon style={{ transform: 'rotate(-90deg)' }} />
                                    </IconButton>
                                )}
                            </main>
                            {selectedResourceType && isPaneExpanded && (
                                <div className="resource-panel" style={{ position: 'relative' }}>
                                    <TopResourcePanel
                                        sendOrCreateChatMessageStreaming={sendOrCreateChatMessageStreamingWrapper}
                                    />
                                    <ResourcePanel 
                                        resource={selectedResourceRedux}
                                        continueLesson={continueLessonWrapper}
                                        quiz={quiz}
                                        currentFlashcardQuizIndex={currentFlashcardQuizIndex}
                                        currentFlashcardQuiz={currentFlashcardQuiz}
                                        selectedResource={selectedResourceRedux}
                                        extendFlashcards={extendFlashcards}
                                        extendQuiz={extendQuiz}
                                        currentFlashcardGroup={currentFlashcardGroup}
                                    />
                                    <IconButton 
                                        onClick={() => setIsPaneExpanded(false)}
                                        style={{
                                            position: 'absolute',
                                            left: '-8px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            zIndex: 1000
                                        }}
                                    >
                                        <ExpandLessIcon style={{ transform: 'rotate(90deg)' }} />
                                    </IconButton>
                                </div>
                            )}
                        </SplitPane>
                    )}
                </div>
            )}
            <SettingsPopup 
                open={settingsOpen} 
                onClose={() => dispatch(setSettingsOpen(false))}
                themeData={themeData}
                userEmail={userEmail} 
            />
            <PersonalizationPopup />
            <Snackbar
                open={snackbarShareChatOpen}
                autoHideDuration={3000}
                message={<span style={{ color: themeData.color5 }}>Link copied to clipboard!</span>}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => dispatch(setSnackbarShareChatOpen(false))}
                ContentProps={{
                    style: { backgroundColor: themeData.color3, justifyContent: 'center', padding: '2px' }
                }}
            />
            <Snackbar
                open={snackbarRecordingOpen}
                autoHideDuration={3000}
                message={<span style={{ color: themeData.color5 }}>{snackbarRecordingMessage}</span>}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                    dispatch(setSnackbarRecordingOpen(false));
                }}
                ContentProps={{
                    style: { backgroundColor: themeData.color3, justifyContent: 'center', padding: '2px' }
                }}
            />
            <Snackbar
                open={generalSnackbarOpen}
                autoHideDuration={4000}
                message={
                    <div 
                        style={{ 
                            color: themeData.color5,
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            textAlign: 'center',
                            padding: '2px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            boxSizing: 'border-box',
                            maxWidth: '300px',
                        }}
                    >
                        {generalSnackbarMessage}
                    </div>
                }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                    dispatch(setGeneralSnackbarOpen(false));
                }}
                ContentProps={{
                    style: { 
                        backgroundColor: themeData.color10,
                        justifyContent: 'center', 
                        padding: '0px',
                        display: 'flex',
                        alignItems: 'center'
                    }
                }}
            />
        </div>
    );
}

export default Chat;