import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Typography } from '@mui/material';
import customLog from '../../customLogger.js';
import { useSelector } from 'react-redux';

const SwipeableContentCard = ({ 
  content,
  currentIndex,
  onSwipe,
  themeData,
  renderContent,
  isStreaming,
  isMobile,
  //scrollLocked
}) => {

  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [exitDirection, setExitDirection] = useState(null);
  const [lastScrollTime, setLastScrollTime] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const scrollLocked = useSelector((state) => state.chat.scrollLocked);

  useEffect(() => {
    if (!isMobile && !scrollLocked) {
      const handleScroll = (e) => {
        const now = Date.now();
        if (now - lastScrollTime > 500) { // Throttle scroll events
          if (e.deltaY > 50) { // Scrolling down
            customLog('SCROLL DOWN DETECTED');
            setExitDirection('up');
            onSwipe('up');
            setLastScrollTime(now);
          } else if (e.deltaY < -50) { // Scrolling up
            customLog('SCROLL UP DETECTED');
            setExitDirection('down');
            onSwipe('down');
            setLastScrollTime(now);
          }
          
          // Update scroll position for parallax effect
          setScrollY(prev => {
            const newY = prev + e.deltaY * 0.5; // Adjust multiplier for scroll sensitivity
            // Limit scroll range
            return Math.min(Math.max(newY, -200), 200);
          });

          // Prevent default scroll behavior
          e.preventDefault();
          e.stopPropagation();
        }
      };

      // Add the event listener to the card container instead of window
      const cardContainer = document.querySelector('.swipeable-card-container');
      if (cardContainer) {
        cardContainer.addEventListener('wheel', handleScroll, { passive: false });
        return () => cardContainer.removeEventListener('wheel', handleScroll);
      }
    }
  }, [isMobile, onSwipe, lastScrollTime, scrollLocked]);
  
  const handleDragStart = (event, info) => {
    customLog('HANDLEDRAGSTART CALLED with info ' + JSON.stringify(info));
    setDragStart({ x: info.point.x, y: info.point.y });
    setDragging(true);
  };

  const handleDragEnd = (event, info) => {
    customLog('HANDLEDRAGEND CALLED with info ' + JSON.stringify(info));
    const dragDistance = {
      x: info.point.x - dragStart.x,
      y: info.point.y - dragStart.y
    };
    
    const swipeThreshold = 10;
    customLog('DRAGDISTANCE Y IS ' + dragDistance.y);
    // if (Math.abs(dragDistance.x) > swipeThreshold) {
    //   setExitDirection(dragDistance.x > 0 ? 'right' : 'left');
    //   onSwipe(dragDistance.x > 0 ? 'right' : 'left');
     if (dragDistance.y < -swipeThreshold && !scrollLocked) {
      customLog('SWIPPPED CALLING WITH UP');
      setExitDirection('up');
      onSwipe('up');
    } else if (dragDistance.y > swipeThreshold && !scrollLocked) {
      customLog('SWIPPPED CALLING WITH DOWN');
      setExitDirection('down'); 
      onSwipe('down');
    }
    
    setDragging(false);
  };

  const variants = {
    enter: { 
      y: "100%",
      opacity: 0
    },
    center: { 
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        delay: 0.3 // Delay entrance until previous card exits
      }
    },
    exit: (direction) => ({
      y: direction === 'up' ? "-100%" : direction === 'down' ? "100%" : 0,
      x: direction === 'left' ? "-100%" : direction === 'right' ? "100%" : 0,
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        duration: 0.3
      }
    })
  };

  return (
    <AnimatePresence mode="wait" initial={false}>
      <motion.div
        key={currentIndex}
        initial="enter"
        animate="center"
        exit="exit"
        variants={variants}
        custom={exitDirection}
        drag={isMobile}
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        dragElastic={0.8}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        className="swipeable-card-container"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transform: `translateY(${scrollY}px)`,
          transition: 'transform 0.1s ease-out'
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '90%',
            maxWidth: '500px',
            minHeight: '400px',
            backgroundColor: 'transparent',
            borderRadius: '20px',
            boxShadow: `0 4px 8px rgba(0,0,0,0.3), 
                      inset 0 -4px 4px rgba(0,0,0,0.2),
                      inset 0 4px 4px rgba(255,255,255,0.2)`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            backdropFilter: 'blur(10px)',
            background: 'rgba(255, 255, 255, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            perspective: '1000px',
            transform: dragging ? `scale(1.02)` : 'scale(1)',
            transition: 'transform 0.2s ease-out',
            overflow: 'auto'
          }}
        >
          {renderContent(content)}

          {!scrollLocked && (
            <Typography
              variant="caption"
              sx={{
                position: 'absolute',
                bottom: '8px',
                left: '50%',
                transform: 'translateX(-50%)',
                color: themeData.color5,
                opacity: 0.7
              }}
            >
              {isMobile ? 'Swipe up/down to navigate' : 'Scroll up/down to navigate'}
            </Typography>
          )}
        </Box>
      </motion.div>
    </AnimatePresence>
  );
};

export default SwipeableContentCard;