import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, IconButton, TableSortLabel, Box, Tooltip, Menu, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Close as CloseIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { setDrawerOpen, setCurrentQuizGroup, setSelectedResourceType, setCurrentFlashcardIndex, setCurrentFlashcardGroup, setCurrentFlashcardGroupID, setCurrentQuizID, getQuizLibrary, setFlashcards, setQuiz, setSelectedResource, getFlashcardsLibrary, deleteFileAPI } from '../../store';
import customLog from '../../customLogger';

const PreviousItemsDialog = ({ setIsPaneExpanded, open, onClose, type, retrieveFlashcards, retrieveQuiz, retrieveMemory, retrieveLesson }) => {
  const themeData = useSelector((state) => state.chat.themeData);
  const dispatch = useDispatch();
  const [dialogWidth, setDialogWidth] = useState('500px');

    // Get flashcardGroups and quizzes from Redux store
    const flashcardGroups = useSelector((state) => state.chat.allFlashcardGroups);
    const quizzes = useSelector((state) => state.chat.allQuizGroups);
    const allRAGFiles = useSelector((state) => state.chat.allRAGFiles);
    const allLessonGroups = useSelector((state) => state.chat.allLessonGroups); 

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  let previousItems = []; 
  if (type === 'flashcards') {
    previousItems = flashcardGroups.map((group) => ({
      name: group.name || 'Untitled',
      createdFrom: group.generating_text || 'N/A',
      lastUsed: formatDate(group.last_used),
      id: group.id,
      flashcards: group.flashcards,
    }));
  } else if (type === 'quizme') {
    previousItems = quizzes.map((quiz) => ({
      name: quiz.name || 'Untitled',
      createdFrom: quiz.generating_text || 'N/A',
      lastUsed: formatDate(quiz.last_used),
      id: quiz.id,
      quiz_questions: quiz.questions,
    }));
  } else if (type === 'memory') {
    previousItems = allRAGFiles.map((file) => ({
      name: file.name || 'Untitled',
      lastUsed: formatDate(file.last_used) || 'N/A',
      id: file.id,
      dateUploaded: formatDate(file.date_uploaded) || 'N/A',
    }));
  } else if (type === 'lessons') {
    customLog('ALL LESSON GROUPS IS ' + JSON.stringify(allLessonGroups));
    previousItems = allLessonGroups.lesson.map((lessonGroup, index) => ({
      name: lessonGroup.name || 'Untitled',
      createdFrom: 'N/A',
      lastUsed: 'N/A',
      id: lessonGroup.id,
      topics: lessonGroup.topics,
      index: index,
    }));
  }

  // Sorting logic
  const [orderDirection, setOrderDirection] = useState('desc');
  const [orderBy, setOrderBy] = useState('lastUsed'); // Default sorting by lastUsed

  const handleSort = (column) => {
    const isAsc = orderBy === column && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  const sortedItems = [...previousItems].sort((a, b) => {
    if (a[orderBy] === null || a[orderBy] === undefined) return 1;
    if (b[orderBy] === null || b[orderBy] === undefined) return -1;
    
    if (orderBy === 'name' || (orderBy === 'createdFrom' && type !== 'memory')) {
      return orderDirection === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    } else if (orderBy === 'lastUsed' || orderBy === 'dateUploaded') {
      const dateA = new Date(a[orderBy]);
      const dateB = new Date(b[orderBy]);
      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) return 0;
      if (isNaN(dateA.getTime())) return orderDirection === 'asc' ? 1 : -1;
      if (isNaN(dateB.getTime())) return orderDirection === 'asc' ? -1 : 1;
      return orderDirection === 'asc' ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  const handleRowClick = (item) => {
    dispatch(setCurrentQuizGroup(null));
    dispatch(setDrawerOpen(false));
    setIsPaneExpanded(true);
    dispatch(setCurrentFlashcardGroup(null));
    dispatch(setCurrentFlashcardIndex(0));
    if (type === 'flashcards') {
      retrieveFlashcards({flashcardGroup: item});
    } else if (type === 'quizme') {
      retrieveQuiz({quizGroup: item});
    } else if (type === 'memory') {
      retrieveMemory(item.id);
    } else if (type === 'lessons') {
      retrieveLesson({lessonGroup: item});
    }
    onClose();
  };

  useEffect(() => {
    const calculateDialogWidth = () => {
      const windowWidth = window.innerWidth;
      const maxWidth = Math.min(windowWidth * 0.75, 800); // 75% of window width, max 800px
      setDialogWidth(`${maxWidth}px`);
    };

    calculateDialogWidth();
    window.addEventListener('resize', calculateDialogWidth);

    return () => window.removeEventListener('resize', calculateDialogWidth);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleMoreClick = (event, itemId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedItemId(itemId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };

  const handleDeleteFile = () => {
    if (selectedItemId) {
      dispatch(deleteFileAPI(selectedItemId));
    }
    handleMenuClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          background: `linear-gradient(to top, ${themeData.color9}, ${themeData.color10})`,
          color: themeData.color5,
          borderRadius: '10px',
          width: dialogWidth,
          maxHeight: '75vh',
        },
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {type === 'flashcards' ? 'Previous Flashcards' : 
         type === 'quizme' ? 'Previous Quizzes' : 
         type === 'memory' ? 'Uploaded Files' : 'Previous Lessons'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: themeData.color5,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ overflowX: 'auto' }}> {/* Allow horizontal scroll if needed */}
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell 
                  sx={{
                    backgroundColor: 'transparent',
                    color: themeData.color5,
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    padding: '12px',
                    borderBottom: `1px solid ${themeData.color5}`,
                  }}
                >
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? orderDirection : 'asc'}
                    onClick={() => handleSort('name')}
                    sx={{
                      '& .MuiTableSortLabel-icon': {
                        color: `${themeData.color5} !important`,
                      },
                      '&.Mui-active': {
                        color: themeData.color15,
                      },
                      '&:hover': {
                        color: themeData.color15,
                      },
                    }}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                {type !== 'memory' && (
                  <TableCell 
                    sx={{
                      backgroundColor: 'transparent',
                      color: themeData.color5,
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      padding: '12px',
                      borderBottom: `1px solid ${themeData.color5}`,
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'createdFrom'}
                      direction={orderBy === 'createdFrom' ? orderDirection : 'asc'}
                      onClick={() => handleSort('createdFrom')}
                      sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: `${themeData.color5} !important`,
                        },
                        '&.Mui-active': {
                          color: themeData.color15,
                        },
                        '&:hover': {
                          color: themeData.color15,
                        },
                      }}
                    >
                      Created From
                    </TableSortLabel>
                  </TableCell>
                )}
                <TableCell 
                  sx={{
                    backgroundColor: 'transparent',
                    color: themeData.color5,
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    padding: '12px',
                    borderBottom: `1px solid ${themeData.color5}`,
                  }}
                >
                  <TableSortLabel
                    active={orderBy === 'lastUsed'}
                    direction={orderBy === 'lastUsed' ? orderDirection : 'asc'}
                    onClick={() => handleSort('lastUsed')}
                    sx={{
                      '& .MuiTableSortLabel-icon': {
                        color: `${themeData.color5} !important`,
                      },
                      '&.Mui-active': {
                        color: themeData.color15,
                      },
                      '&:hover': {
                        color: themeData.color15,
                      },
                    }}
                  >
                    Last Used
                  </TableSortLabel>
                </TableCell>
                {type === 'memory' && (
                  <TableCell 
                    sx={{
                      backgroundColor: 'transparent',
                      color: themeData.color5,
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      padding: '12px',
                      borderBottom: `1px solid ${themeData.color5}`,
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'dateUploaded'}
                      direction={orderBy === 'dateUploaded' ? orderDirection : 'asc'}
                      onClick={() => handleSort('dateUploaded')}
                      sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: `${themeData.color5} !important`,
                        },
                        '&.Mui-active': {
                          color: themeData.color15,
                        },
                        '&:hover': {
                          color: themeData.color15,
                        },
                      }}
                    >
                      Uploaded On
                    </TableSortLabel>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedItems.map((item, index) => (
                <TableRow 
                    key={index}
                    onClick={() => handleRowClick(item)}
                    style={{ cursor: 'pointer' }}
                    sx={{ 
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                >
                  <TableCell 
                    style={{ 
                      color: themeData.color5, 
                      maxWidth: '150px', 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis', 
                      whiteSpace: 'nowrap',
                      borderBottom: 'none'
                    }}
                  >
                    <Tooltip title={item.name} placement="top">
                      <span>
                        {item.name.length > 20 ? `${item.name.substring(0, 20)}...` : item.name}
                      </span>
                    </Tooltip>
                  </TableCell>
                  {type !== 'memory' && (
                      <>
                          <TableCell 
                            style={{ 
                              color: themeData.color5, 
                              maxWidth: '150px', 
                              overflow: 'hidden', 
                              textOverflow: 'ellipsis', 
                              whiteSpace: 'nowrap',
                              borderBottom: 'none'
                            }}
                          >
                            {item.createdFrom.length > 15 ? (
                              <Tooltip title={item.createdFrom} placement="top">
                                <span>
                                  {`${item.createdFrom.substring(0, 15)}...`}
                                </span>
                              </Tooltip>
                            ) : (
                              <span>{item.createdFrom}</span>
                            )}
                          </TableCell>
                      </>
                  )}
                  <TableCell style={{ color: themeData.color5, borderBottom: 'none' }}>{item.lastUsed}</TableCell>
                  {type === 'memory' && (
                    <TableCell style={{ color: themeData.color5, borderBottom: 'none' }}>
                      {item.dateUploaded}
                      <IconButton
                        onClick={(e) => handleMoreClick(e, item.id)}
                        sx={{
                          color: themeData.color5,
                          padding: '4px',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          },
                        }}
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            backgroundColor: themeData.color10,
            color: themeData.color5,
          },
        }}
      >
        <MenuItem onClick={handleDeleteFile}>Delete File</MenuItem>
      </Menu>
    </Dialog>
  );
};

export default PreviousItemsDialog;