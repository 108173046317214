// store.js
import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import axios from 'axios';
// import { DjangoHost } from './hosts.js';
// import abstractClassesPDF from './resources/comp215_abstract_classes.pdf';
import abstractClassPreview from './images/abstractClassPreview.png'; //TODO: not for prod. line uses it for all files returned from rag
import ragPreview from './images/RAG-Preview.png';
// import abstractClassesVideo from './resources/abstractVid.mp4';
import abstractVideoPreview from './images/videoPreview.png';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import customLog from './customLogger.js';
import { flatMap, lte, truncate } from 'lodash';
import {themes} from './colors.js';
import { SettingsPowerOutlined } from '@mui/icons-material';
// import { Alert } from 'react-native';
const { Configuration, OpenAIApi } = require("openai");
const { GoogleGenerativeAI } = require("@google/generative-ai");


const DjangoHost = process.env.REACT_APP_DJANGO_HOST;

const initialState = {
  iframeDragged: false,
  selectedTheme: 'dark',
  themeData: themes['dark'],
  quizMeScreenOpen: false,
  quiz: [],
  flashcardsScreenOpen: false,
  currentFlashcardPDF: null,
  // flashcards: [],
  currentFlashcardQuizIndex: 0,
  allCoursesID: false,
  drawerOpen: true,
  IDToConversation: {},
  IDToChatTitle: {},
  courseIDsToRecentChatIDs: {},
  currentOrderedChatIDs: [],
  conversationIDCounter: 0,
  selectedChatTitle: "New Chat",
  messages: [],
  input: "",
  loading: false,
  coursePopover: null,
  userPopover: null,
  selectedCourse: null, //TODO: Figure out how to automatically set a selectedCourse
  selectedCourseTitle: "CS 101", //TODO: Figure out how to set automatically
  courses: {},
  courseIdCounter : 5,
  editingChatTitle: null,
  editedChatTitle: "",
  currentConversationID: null,
  resources: [],
  selectedResource: null,
  selectedResourceID: null,
  selectedResourceType: null,
  systemPrompt: "normal",
  settingsOpen: false,
  snackbarShareChatOpen: false,
  snackbarRecordingOpen: false,
  snackbarRecordingMessage: "",
  generalSnackbarOpen: false,
  generalSnackbarMessage: "",
  streamedMessages: "",
  APIToken: null,
  refreshToken: null,
  selectedCourseId: "",
  mostRecentUserPrompt: "",
  creatingCourse: false,
  isShortTermMemory: false,
  isLongTermMemory: false,
  contextResourceSelection: false,
  isLoggedIn: false,
  firstName: "Guest",
  profilePicture: null,
  username: "Guest",
  messageToSend: "",
  storedCourseName: "Error",
  storedChatName: "Error",
  previousRemoveChatData: {},
  abortion: false,
  loginSignupScreenOpen: false,
  fakeLoginSignupScreenOpen: false,
  flashcardPrompt: null,
  flashcardArray: [],
  currentFlashcardQuiz: null,
  quizPrompt: null,
  loginSignupTab: 1,
  presetPrompt: null,
  allFlashcardGroups: [],
  allQuizGroups: [],
  allRAGFiles: [],
  currentFlashcardGroupID: null,
  currentQuizID: null,
  currentFlashcardGroup: null,
  currentQuizGroup: null,
  currentFlashcardIndex: 0,
  currentQuizIndex: 0,
  streamingCards: false,
  memoryScreenOpen: false,
  contextFileLoading: false,
  lessonsScreenOpen: false,
  allLessonGroups: [],
  top4LessonGroups: [],
  currentLessonGroup: null,
  currentLessonID: null,
  currentLessonIndex: null,
  guidedLessonModeActive: false,
  currentLessonName: "Linear Algebra",
  currentSubtopicName: "1.1 Vector Spaces",
  currentTopicScore: 0,
  currentTopicIndex: 0,
  currentTopicID: null,
  selectedCenterResourceType: null,
  centerPanelLoading: false,
  currentNumberSubtopics: 0,
  currentSubtopicIndex: 0,
  currentTopicObject: {},
  currentDisplayedTopicContent: {},
  currentDisplayedTopicIndex: 0,
  onHomeScreen: true,
  centerPanelVisible: false,
  isStreaming: false,
  timeLimit: '15 minutes',
  level: 4,
  resumeScreenOpen: false,
  resumeExploreRecommendations: [],
  lessonPlanStreaming: false,
  libraryPageActive: false,
  topicContentLoading: false,
  personalizationScreenOpen: false,
  prereqTestOptionScreenOpen: false,
  prereqTestModeActive: false,
  prereqTestQuestions: [],
  numberCorrectQuestions: 0,
  numberTotalQuestions: 0,
  topicIndexOfLesson: null,
  scrollLocked: false,
  exploresArray: [],
  currentDisplayedExploreIndex: null,
  lastAPIExploreIndexCalled: 0,
}

// Define the initial state and create slices for each piece of state
const chatSlice = createSlice({
  name: 'chat',
  initialState,
  // initialState: {
  //   iframeDragged: false,
  //   selectedTheme: 'default',
  //   themeData: themes['default'],
  //   quizMeScreenOpen: false,
  //   quiz: [],
  //   flashcardsScreenOpen: false,
  //   currentFlashcardPDF: null,
  //   flashcards: [],
  //   currentFlashcardQuizIndex: 0,
  //   allCoursesID: false,
  //   drawerOpen: true,
  //   IDToConversation: {},
  //   IDToChatTitle: {},
  //   courseIDsToRecentChatIDs: {},
  //   currentOrderedChatIDs: [],
  //   conversationIDCounter: 0,
  //   selectedChatTitle: "New Chat",
  //   messages: [],
  //   input: "",
  //   loading: false,
  //   coursePopover: null,
  //   userPopover: null,
  //   selectedCourse: null, //TODO: Figure out how to automatically set a selectedCourse
  //   selectedCourseTitle: "CS 101", //TODO: Figure out how to set automatically
  //   courses: {},
  //   courseIdCounter : 5,
  //   editingChatTitle: null,
  //   editedChatTitle: "",
  //   currentConversationID: null,
  //   resources: [],
  //   selectedResource: null,
  //   selectedResourceID: null,
  //   selectedResourceType: null,
  //   systemPrompt: "normal",
  //   settingsOpen: false,
  //   snackbarShareChatOpen: false,
  //   snackbarRecordingOpen: false,
  //   snackbarRecordingMessage: "",
  //   generalSnackbarOpen: false,
  //   generalSnackbarMessage: "",
  //   streamedMessages: "",
  //   APIToken: null,
  //   refreshToken: null,
  //   selectedCourseId: "",
  //   mostRecentUserPrompt: "",
  //   creatingCourse: false,
  //   isShortTermMemory: false,
  //   isLongTermMemory: false,
  //   contextResourceSelection: false,
  //   isLoggedIn: false,
  //   firstName: "Guest",
  //   username: "Guest",
  //   messageToSend: "",
  //   storedCourseName: "Error",
  //   storedChatName: "Error",
  //   previousRemoveChatData: {},
  //   abortion: false,
  //   loginSignupScreenOpen: false,
  //   flashcardPrompt: null,
  //   flashcardArray: [],
  //   currentFlashcardQuiz: null,
  //   quizPrompt: null,
  //   streamingCards: false,
  //   loginSignupTab: 1,
  //   presetPrompt: null,
  // },
  reducers: {
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setIDToConversation: (state, action) => {
      state.IDToConversation = action.payload;
    },
    setChatTitle: (state, action) => {
      state.selectedChatTitle = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setInput: (state, action) => {
      state.input = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCoursePopover: (state, action) => {
      state.coursePopover = action.payload;
    },
    setUserPopover: (state, action) => {
      state.userPopover = action.payload;
    },
    handleLoadChatRedux: (state, action) => {
        const {selectedCourse } = state;
        const conversationId = action.payload;
        state.currentConversationID = conversationId;
        if (state.IDToConversation[selectedCourse] && state.IDToConversation[selectedCourse][conversationId]) {
          state.messages = state.IDToConversation[selectedCourse][conversationId];
          state.selectedChatTitle = state.IDToChatTitle[conversationId] || "New Chat";
        }
        // if (state.savedChats[selectedCourse] && state.savedChats[selectedCourse][action.payload]) {
        //   state.messages = state.savedChats[selectedCourse][action.payload];
        //   state.chatTitle = action.payload;
        // };
    },
    setSelectedCourse: (state, action) => {
        state.selectedCourse = action.payload;
        // Update currentOrderedChatIDs to reflect the new selectedCourse
        if (state.courseIDsToRecentChatIDs[action.payload]) {
          state.currentOrderedChatIDs = state.courseIDsToRecentChatIDs[action.payload];
        } else {
          state.currentOrderedChatIDs = [];
        }
        customLog('currentOrderedChatIDs in setSelectedCourse is ' + state.currentOrderedChatIDs);
    },
    setCourses: (state, action) => {
        state.courses = action.payload;
    },
    editCourseName: (state, action) => {
      const { courseId, courseName } = action.payload;
      if (state.courses.hasOwnProperty(courseId)) {
        state.courses[courseId] = courseName;
      }
    },
    removeCourse: (state, action) => {
      const { courseId } = action.payload;
      delete state.courses[courseId];
    },
    // setEditingChatTitle: (state, action) => {
    //   state.editingChatTitle = action.payload;
    // },
    setEditedChatTitleRedux: (state, action) => { //TODO: Get better name
      const { conversationID, newTitle } = action.payload;
      if (state.IDToChatTitle[state.selectedCourse].hasOwnProperty(conversationID)) {
        state.IDToChatTitle[state.selectedCourse][conversationID] = newTitle;
      }
    },
    setConversationID: (state, action) => {
      state.currentConversationID = action.payload;
    },
    removeChat: (state, action) => {
      const { conversationID } = action.payload;
      delete state.IDToConversation[state.selectedCourse][conversationID];
      delete state.IDToChatTitle[state.selectedCourse][conversationID];
      
      if (state.currentConversationID === conversationID) { //TODO: can combine this with handleLoadChat 
        // Automatically select a new chatTitle
        // console.log("Current chat being deleted");
        const remainingChatTitles = Object.keys(state.IDToChatTitle[state.selectedCourse]);
        if (remainingChatTitles.length > 0) {
          // Assuming the first item is the newest after sorting
          const newSelectedChatTitle = remainingChatTitles[0];
          state.selectedChatTitle = state.IDToChatTitle[state.selectedCourse][newSelectedChatTitle];
          state.messages = state.IDToConversation[state.selectedCourse][newSelectedChatTitle];
          state.currentConversationID = newSelectedChatTitle;
        } else {
          state.selectedChatTitle = "New Chat";
          state.currentConversationID = null;
          state.messages = [];
        }
      }

    // Other reducers...
  },
  setResources: (state, action) => {
    const mockResources = [
      // { id: 1, type: 'file', title: 'AbstractClasses.pdf', url: abstractClassesPDF, previewImageUrl: abstractClassPreview, locations: [5, 12, 50] },
      // { id: 2, type: 'video', title: 'Abstract Classes Video', url: abstractClassesVideo, previewImageUrl: abstractVideoPreview, locations: [35, 105, 160] },
      // { id: 3, type: 'video', title: 'Abstract Classes Video', url: abstractClassesVideo, previewImageUrl: abstractVideoPreview, locations: [30, 60, 90] },
      //{ id: 2, type: 'video', title: 'Lecture.mp4', url: '/path/to/lecture.mp4' },
      // ... up to 3 resources
    ];
    state.resources = mockResources; //TODO: Replace with actual resources
    // state.resources = action.payload;
  },
  removeResources: (state, action) => {
    state.resources = [];
    
    // state.selectedResource = null; //TODO: Think if this is best user design - probs not if the select resource is short term memory. Why not leave on for long-term memory anyway
  },
  removeSelectedResource: (state, action) => {
    state.selectedResource = null;
    state.selectedResourceID = null;
    state.selectedResourceType = null;
  },
  setSelectedResource: (state, action) => {
    customLog('SETSELECTEDRESOURCE CALLED WITH action.payload of ' + JSON.stringify(action.payload));
    state.selectedResource = action.payload;
    state.selectedResourceID = 'action.payload.id';
    customLog('SETSELECTEDRESOURCE state.selectedResourceID is now ' + state.selectedResourceID);
  },
  handleNewChat: (state, action) => {
  //   if (!selectedCourse) {
  //     return; //TODO: Figure out what to do if new chat made but no course selected
  // }
    state.selectedChatTitle = "New Chat";
    state.messages = [];
    state.currentConversationID = null;
    // state.resources = [];
    // state.selectedResource = null;  
  },
  handleExitLesson: (state, action) => {
    state.guidedLessonModeActive = false;
    state.messages = [];
    state.currentTopicIndex = null;
    state.currentLessonIndex = null;
    state.currentLessonGroup = null;
    state.currentLessonID = null;
    state.currentTopicScore = 0;
    state.currentDisplayedTopicContent = {};
    state.currentDisplayedTopicIndex = 0;
  },
  setSelectedCourseTitle: (state, action) => {
    state.selectedCourseTitle = action.payload;
  },
  setSystemPrompt: (state, action) => {
    state.systemPrompt = action.payload;
  },
  setSettingsOpen: (state, action) => {
    state.settingsOpen = action.payload;
  },
  setSnackbarShareChatOpen: (state, action) => {
    state.snackbarShareChatOpen = action.payload;
  },
  setSnackbarRecordingOpen: (state, action) => {
    state.snackbarRecordingOpen = action.payload;
  },
  setSnackbarRecordingMessage: (state, action) => {
    state.snackbarRecordingMessage = action.payload;
  },
  addStreamingMessage: (state, action) => {
    const { message, conversationID } = action.payload;
    state.messages.push(message);
    state.IDToConversation[state.selectedCourse][conversationID] = state.messages;
  },
  addStreamedMessage: (state, action) => {
    customLog('ADDSTREAMEDMESSAGE CALLED WITH action.payload of ' + JSON.stringify(action.payload));
    // append action.payload to state.streamedMessages
    // Update the IDToConversation mapping
    // get streamedResponse from action.payload
    const { streamedResponse, guidedLessonModeActive } = action.payload;
    // console.log('in addStreamedMessage with streamedResponse of ' + streamedResponse);
    const { selectedCourse, selectedChatTitle: chatTitle, messages, currentConversationID, currentTopicIndex, currentLessonIndex } = state;
    // if (!state.IDToConversation[selectedCourse]) {
    //     state.IDToConversation[selectedCourse] = {};
    // }dispatch(addStreamedMessage(streamedResponse));

    // Add streamed response to messages as assistant message
    const assistantMessage = {
      user: "Assistant",
      text: streamedResponse,
    };
    state.messages.push(assistantMessage);
    customLog('ADDSTREAMEDMESSAGE before guidedLessonModeActive');
    if (guidedLessonModeActive) {
      const currentTopic = state.currentLessonGroup.topics[currentTopicIndex];
      if (!currentTopic.conversation) {
      customLog('ADDSTREAMEDMESSAGE currentTopic.conversation is null');
        currentTopic.conversation = { messages: [] };
      } else if (!currentTopic.conversation.messages) {
        customLog('ADDSTREAMEDMESSAGE currentTopic.conversation.messages is null');
        currentTopic.conversation.messages = [];
      }
      customLog('ADDSTREAMEDMESSAGE currentTopic.conversation.messages is ' + currentTopic.conversation.messages);
      currentTopic.conversation.messages.push(assistantMessage);
    } else {
      state.IDToConversation[selectedCourse][currentConversationID] = messages;
    }
    customLog('ADDSTREAMEDMESSAGE after guidedLessonModeActive');
    state.presetPrompt = null;
    state.messageToSend = "";
  },
  addPresetPrompt: (state, action) => {
    const { message } = action.payload;
    const assistantMessage = {
      user: "Assistant",
      text: message,
    }
    state.messages.push(assistantMessage);
    state.presetPrompt = null;
  },
  addMessage: (state, action) => {
    state.messages.push(action.payload);
  },
  setMostRecentUserPrompt: (state, action) => {
    state.mostRecentUserPrompt = action.payload;
  },
  setGeneralSnackbarOpen: (state, action) => {
    customLog('setGeneralSnackbarOpen called with ' + action.payload);
    state.generalSnackbarOpen = action.payload;
  },
  setGeneralSnackbarMessage: (state, action) => {
    customLog('setGeneralSnackbarMessage called with ' + action.payload);
    state.generalSnackbarMessage = action.payload;
  },
  setIsShortTermMemory: (state, action) => {
    state.isShortTermMemory = action.payload;
  },
  setIsLongTermMemory: (state, action) => {
    state.isLongTermMemory = action.payload;
  },
  setFlashcardsScreenOpen: (state, action) => {
    state.flashcardsScreenOpen = action.payload;
    // state.selectedResource = null;
    // state.selectedResourceID = null;
  },
  setFlashcards: (state, action) => {
    customLog('SETFLASHCARDS before ' + state.flashcards);
    customLog('SETFLASHCARDS ACTION.PAYLOAD ' + JSON.stringify(action.payload));
    state.flashcards = action.payload;
    customLog('SETFLASHCARDS after ' + JSON.stringify(state.flashcards));
    // state.flashcardsScreenOpen = false;
    state.currentFlashcardQuizIndex = 0;
    // state.selectedResource = {id: 'temp', type: 'flashcard', title: 'flashcardTest', content: action.payload};
    // state.selectedResourceID = 'test';
  },
  setQuiz(state, action) {
    state.quiz = action.payload;
    // state.quizMeScreenOpen = false;
    state.currentFlashcardQuizIndex = 0;
    // state.selectedResource = { type: 'quiz' };
  },
  // addFlashcard(state, action) {
  //   state.flashcards.splice(state.currentFlashcardQuizIndex + 1, 0, action.payload);
  // },
  addFlashcard: (state, action) => {
    const flashcard = action.payload;
    if (state.currentFlashcardGroup) {
      state.currentFlashcardGroup.flashcards.push(flashcard);
    }
  },
  // appendFlashcard(state, action) {
  //   state.flashcards.push(action.payload);
  // },

  addQuizQuestion(state, action) {
    state.quiz.splice(state.currentFlashcardQuizIndex + 1, 0, action.payload);
  },
  appendQuizQuestion(state, action) {
    state.quiz.push(action.payload);
  },
  setCurrentFlashcardPDF: (state, action) => {
    state.currentFlashcardPDF = action.payload;
  },
  sendMessageToAPI: (state, action) => {
    const { message, presetPrompt } = action.payload;
    customLog('sendMessageToAPI called with message: ' + message + ' and presetPrompt: ' + presetPrompt);
    customLog('messageToSend before setting: ' + state.messageToSend);
    state.messageToSend = message;
    state.presetPrompt = presetPrompt;
    customLog('messageToSend after setting: ' + state.messageToSend);
  },
  setQuizMeScreenOpen: (state, action) => {
    state.quizMeScreenOpen = action.payload;
    //state.selectedResource = null;
    //state.selectedResourceID = null;
  },
  setTheme: (state, action) => {
    state.selectedTheme = action.payload;
    state.themeData = themes[action.payload];
  },
  setIframeDragged: (state, action) => {
    state.iframeDragged = action.payload;
  },
  setAbortion: (state, action) => {
    state.abortion = action.payload;
  },
  setIsLoggedIn: (state, action) => {
    state.isLoggedIn = action.payload;
    if (!action.payload) {
      state.username = "Guest";
      state.firstName = "Guest";
    }
  },
  setLoginSignupScreenOpen: (state, action) => {
    const {loginSignupScreenOpen, loginSignupTab } = action.payload;
    state.loginSignupScreenOpen = loginSignupScreenOpen;
    state.loginSignupTab = loginSignupTab;
    state.drawerOpen = !loginSignupScreenOpen;
  },
  setFakeLoginSignupScreenOpen: (state, action) => {
    const {fakeLoginSignupScreenOpen } = action.payload;
    state.fakeLoginSignupScreenOpen = fakeLoginSignupScreenOpen;
    // state.fakeLoginSignupTab = fakeLoginSignupTab;
  },
  setQuizPrompt: (state, action) => {
    state.quizPrompt = action.payload;
  },
  setFlashcardPrompt: (state, action) => {
    customLog('store.js setFlashcardPrompt called with ' + action.payload);
    state.flashcardPrompt = action.payload;
  },
  setFlashcardQuizObject : (state, action) => {
    state.currentFlashcardQuiz = action.payload;
  },
  changeFlashcardQuizIndex: (state, action) => {
    state.currentFlashcardQuizIndex += action.payload;
  },
  setFlashcardQuizIndex: (state, action) => {
    state.currentFlashcardQuizIndex = action.payload;
  },
  clearChatHistory: (state, action) => {
    state.currentOrderedChatIDs = [];
  },
  setCurrentFlashcardGroupID: (state, action) => {
    customLog('SETCURRENTFLASHCARDGROUPID STORE.JS with action.payload of ' + action.payload);
    state.currentFlashcardGroupID = action.payload;
  },
  setCurrentQuizID: (state, action) => {
    state.currentQuizID = action.payload;
  },
  prependFlashcardGroup: (state, action) => {
    state.allFlashcardGroups.unshift(state.currentFlashcardGroup);
  },
  prependQuizGroup: (state, action) => {
    state.allQuizGroups.unshift(state.currentQuizGroup);
  },
  appendFlashcardToGroup: (state, action) => {
    if (state.currentFlashcardGroup) {
      state.currentFlashcardGroup.flashcards.push(action.payload);
    }
  },
  editFlashcardGroupName: (state, action) => {
    if (state.currentFlashcardGroup) {
      state.currentFlashcardGroup.name = action.payload;
    }
  },
  appendQuizQuestionToGroup: (state, action) => {
    customLog()
    const {parsedData, incrementCard} = action.payload;
    if (state.currentQuizGroup) {
      state.currentQuizGroup.quiz_questions.push(parsedData);
    }

    if (incrementCard) {
      state.currentQuizIndex += 1;
    }

    if (state.contextFileLoading) {
      state.contextFileLoading = false;
    }
  },
  setAllFlashcardGroups: (state, action) => {
    state.allFlashcardGroups = action.payload;
  },
  setAllQuizGroups: (state, action) => {
    state.allQuizGroups = action.payload;
  },
  setCurrentFlashcardGroup: (state, action) => {
    state.currentFlashcardGroup = action.payload;
  },
  setCurrentQuizGroup: (state, action) => {
    state.currentQuizGroup = action.payload;
  },
  setCurrentFlashcardIndex: (state, action) => {
    state.currentFlashcardIndex = action.payload;
  },
  setCurrentQuizIndex: (state, action) => {
    state.currentQuizIndex = action.payload;
  },
  changeCurrentFlashcardIndex: (state, action) => {
    state.currentFlashcardIndex += action.payload;
  },
  changeCurrentQuizIndex: (state, action) => {
    state.currentQuizIndex += action.payload;
  },
  setSelectedResourceType: (state, action) => {
    state.selectedResourceType = action.payload;
  },
  setStreamingCards: (state, action) => {
    state.streamingCards = action.payload;
  },
  insertFlashcard: (state, action) => {
    const {flashcard } = action.payload
    if (state.currentFlashcardGroup) {
      const nextIndex = state.currentFlashcardIndex + 1;
      state.currentFlashcardGroup.flashcards.splice(nextIndex, 0, flashcard);
      state.currentFlashcardIndex = nextIndex;
    }
  },
  insertQuizQuestion: (state, action) => {
    const {quizQuestion} = action.payload;
    if (state.currentQuizGroup) {
      const nextIndex = state.currentQuizIndex + 1;
      state.currentQuizGroup.quiz_questions.splice(nextIndex, 0, quizQuestion);
      state.currentQuizIndex = nextIndex;
    }
  },
  displayInTextFile: (state, action) => {
      // const {url} = action.payload;
      // state.loading = false;
      // state.selectedResource = url;
      // state.contextResourceSelection = true;
      customLog('uploadContextFileAPI.fulfilled');
      const {fileURL} = action.payload;
      customLog('values for selectedResource and ID now are ' + state.selectedResource + ' ' + state.selectedResourceID);
      state.selectedResource = {id: 'fileID', type: 'file', title: 'Rag File', url: fileURL, locations: [0]};
      // state.resources = [{id: fileID, type: 'file', title: 'Rag File', url: fileURL, previewImageUrl: filePreview, locations: [initialPage]}]
      state.selectedResourceID = 'fileID';
      state.selectedResourceType = 'file';
      state.generalSnackbarMessage = "Talk to Plato about your file!";
      state.generalSnackbarOpen = true;
      customLog('values for selectedResource and ID after update are ' + state.selectedResource + ' ' + state.selectedResourceID);
  },
  setMemoryScreenOpen: (state, action) => {
    state.memoryScreenOpen = action.payload;
  },
  setAPIToken: (state, action) => {
    state.APIToken = action.payload;
  },
  setFirstName: (state, action) => {
    state.firstName = action.payload;
  },
  setProfilePicture: (state, action) => {
    state.profilePicture = action.payload;
  },
  setContextFileLoading: (state, action) => {
    state.contextFileLoading = action.payload;
  },
  setLessonsScreenOpen: (state, action) => {
    state.lessonsScreenOpen = action.payload;
  },
  setAllLessonGroups: (state, action) => {
    state.allLessonGroups = action.payload;
  },
  setCurrentLessonGroup: (state, action) => {
    customLog('SETCURRENTLESSONGROUP STORE.JS with action.payload of ' + JSON.stringify(action.payload));
    state.currentLessonGroup = action.payload;
    if (action.payload) {
      state.guidedLessonModeActive = true;
    }
  },
  removeCurrentLessonGroup: (state, action) => {
    state.currentLessonGroup = null;
  },
  setCurrentLessonID: (state, action) => {
    customLog('SETCURRENTLESSONID in store.js is called with ' + action.payload);
    state.currentLessonID = action.payload;
  },
  setCurrentLessonIndex: (state, action) => {
    state.currentLessonIndex = action.payload;
  },
  setCurrentTopicIndex: (state, action) => {
    state.currentTopicIndex = action.payload;
  },
  setTopicIndexOfLesson: (state, action) => {
    state.topicIndexOfLesson = action.payload;
  },
  incrementCurrentTopicIndex: (state, action) => {
    state.currentTopicIndex += 1;
  },
  setCurrentTopicScore: (state, action) => {
    state.currentTopicScore = action.payload;
  },
  incrementCurrentTopicScore: (state, action) => {
    state.currentTopicScore += action.payload;
  },
  setGuidedLessonModeActive: (state, action) => {
    state.guidedLessonModeActive = action.payload;
  },
  setCurrentLessonName: (state, action) => {
    state.currentLessonName = action.payload;
  },
  setSelectedCenterResourceType: (state, action) => {
    state.selectedCenterResourceType = action.payload;
  },
  setCenterPanelLoading: (state, action) => {
    state.centerPanelLoading = action.payload;
  },
  incrementCurrentNumberSubtopics: (state, action) => {
    customLog('INCREMENTCURRENTNUMBERSUBTOPICS BEGINNING');
    customLog('CURRENTNUMBERSUBTOPICS ' + state.currentNumberSubtopics);
    customLog('CURRENTSUBTOPICINDEX ' + state.currentSubtopicIndex);
    customLog('CURRENTTOPICINDEX ' + state.currentTopicIndex);
    // if (state.currentNumberSubtopics - 1 > state.currentSubtopicIndex) {
    //   state.currentSubtopicIndex += 1;
    // } else {
    //   state.currentSubtopicIndex = 0;
      if (state.currentLessonGroup && state.currentLessonGroup.topics[state.topicIndexOfLesson + 1]) {
        // state.currentNumberSubtopics = state.currentLessonGroup.topics[state.currentTopicIndex + 1].subtopics.length;
        state.currentLessonGroup.topics[state.topicIndexOfLesson+1].locked = false;
        // state.currentTopicIndex += 1;
        
      }
    // }
    customLog('INCREMENTCURRENTNUMBERSUBTOPICS ENDING');
    customLog('CURRENTNUMBERSUBTOPICS ' + state.currentNumberSubtopics);
    customLog('CURRENTSUBTOPICINDEX ' + state.currentSubtopicIndex);
    customLog('CURRENTTOPICINDEX ' + state.currentTopicIndex);
    
  },
  setOnHomeScreen: (state, action) => {
    state.onHomeScreen = action.payload;
  },
  setCenterPanelVisible: (state, action) => {
    state.centerPanelVisible = action.payload;
  },
  setIsStreaming: (state, action) => {
    state.isStreaming = action.payload;
  },
  setTimeLimit: (state, action) => {
    state.timeLimit = action.payload;
  },
  setLevel: (state, action) => {
    state.level = action.payload;
  },
  setResumeScreenOpen: (state, action) => {
    state.resumeScreenOpen = action.payload;
  },
  setCurrentDisplayedTopicContent: (state, action) => {
    state.currentDisplayedTopicContent = action.payload;
  },
  setLessonPlanStreaming: (state, action) => {
    state.lessonPlanStreaming = action.payload;
  },
  changeCurrentDisplayedTopicIndex: (state, action) => {
    customLog('CHANGECURRENTDISPLAYEDTOPICINDEX STORE.JS with action.payload of ' + action.payload);
    if (state.currentDisplayedTopicIndex + action.payload < Object.keys(state.currentTopicObject.conversation).length) {
      customLog('CHANGECURRENTDISPLAYEDTOPICINDEX STORE.JS with currentTopicObject of ' + JSON.stringify(state.currentTopicObject));
      state.currentDisplayedTopicContent = state.currentTopicObject.conversation[state.currentDisplayedTopicIndex + action.payload];
      state.currentDisplayedTopicIndex += action.payload;
    } else {
      state.currentDisplayedTopicIndex = 0;
      state.currentDisplayedTopicContent = {};
      if (state.currentLessonGroup && state.currentLessonGroup.topics[state.currentTopicIndex + 1]) {
        state.currentLessonGroup.topics[state.currentTopicIndex + 1].locked = false;
      }
    }
  },
  setLibraryPageActive: (state, action) => {
    state.libraryPageActive = action.payload;
  },
  setPersonalizationOpen: (state, action) => {
    state.personalizationScreenOpen = action.payload;
  },
  setPrereqTestOptionScreenOpen: (state, action) => {
    state.prereqTestOptionScreenOpen = action.payload;
  },
  appendCurrentTopicObject: (state, action) => {
    customLog('APPENDCURRENTTOPICOBJECT STORE.JS with action.payload of ' + JSON.stringify(action.payload));
    if (Object.keys(state.currentTopicObject).length === 0) {
      customLog('APPENDCURRENTTOPICOBJECT STORE.JS setting currentDisplayedTopicContent to ' + JSON.stringify(action.payload));
      state.currentTopicObject['conversation'] = [];
      state.currentDisplayedTopicContent = action.payload;
      state.currentTopicObject.conversation.push(action.payload);
    } else {
      //const nextIndex = Object.keys(state.currentTopicObject).length;
      state.currentTopicObject.conversation.push(action.payload);
    }
  },
  setPrereqTestModeActive: (state, action) => {
    state.prereqTestModeActive = action.payload;
  },
  appendPrereqTestQuestion: (state, action) => {
    customLog('APPENDPREREQTESTQUESTION STORE.JS with action.payload of ' + JSON.stringify(action.payload));
    state.prereqTestQuestions.push(action.payload);
  },
  clearPrereqTestQuestions: (state, action) => {
    state.prereqTestQuestions = [];
  },
  incrementNumberCorrectQuestions: (state, action) => {
    state.numberCorrectQuestions += 1;
  },
  incrementNumberTotalQuestions: (state, action) => {
    state.numberTotalQuestions += 1;
  }, 
  setNumberCorrectQuestions: (state, action) => {
    state.numberCorrectQuestions = action.payload;
  },
  setNumberTotalQuestions: (state, action) => {
    state.numberTotalQuestions = action.payload;
  },
  updateStarRating: (state, action) => {
    state.currentLessonGroup.topics[state.topicIndexOfLesson].stars = action.payload;
  },
  setScrollLocked: (state, action) => {
    state.scrollLocked = action.payload;
  },
  clearPreviousTopic: (state, action) => {
    state.currentTopicObject = {};
    state.currentTopicIndex = 0;
    state.currentTopicID = null;
    state.currentDisplayedTopicContent = {};
    state.currentDisplayedTopicIndex = 0;
  },
  changeCurrentDisplayedExploreIndex: (state, action) => {
    state.currentDisplayedExploreIndex += action.payload;
  },
  signOut: () => initialState,
  
},
  // Extra reducers for handling async thunks...
  extraReducers: (builder) => {
    builder
        .addCase(createCourseAPI.pending, (state, action) => {
          customLog('createCourseAPI.pending action.meta.arg.courseName: ', action.meta.arg);
          state.courses["temp id"] = action.meta.arg;
          state.selectedCourse = "temp id";
          // Update the currentOrderedChatIDs
          state.currentOrderedChatIDs = [];
      
          // Update the messages array
          state.messages = [];
          state.loading = true;
          state.creatingCourse = true;

      })
      .addCase(createCourseAPI.fulfilled, (state, action) => {
          // Use temp counter for uniqueness (TODO: Get from backend)
          //const courseId = state.courseIdCounter;

          // Extract the new course from the action payload
          const {courseName, courseID} = action.payload;

          // Check if saved courses is empty
          if (!state.allCoursesID) {
            state.allCoursesID = courseID;
          }
          
          // Add the new course to the saved courses
          state.courses[courseID] = courseName;

          //Update the selected course
          state.selectedCourse = courseID;

          // Update the currentOrderedChatIDs
          state.currentOrderedChatIDs = [];

          // Update the messages array
          state.messages = [];

          // if temp id exists in courses, update it
          if (state.courses["temp id"]) {
            delete state.courses["temp id"];
          }

          // Go ahead and creating empty ma  in IDToChatTitle for new course
          state.IDToChatTitle[courseID] = {};

          // Go ahead and create an empty array in courseIDsToRecentChatIDs for new course
          state.courseIDsToRecentChatIDs[courseID] = [];

          // Go ahead and create empty map in IDToConversation
          state.IDToConversation[courseID] = {};
          // Increment the course ID counter
          //state.courseIdCounter++;
          // Reset the loading state
          state.loading = false;
          state.creatingCourse = false;

          // Set snackbar for creatingCourse to false
          state.generalSnackbarOpen = false;
      })
      .addCase(createCourseAPI.rejected, (state, action) => {
          // Handle the rejected state
          // `action.payload` contains the error message if `rejectWithValue` was used
          customLog('createCourseAPI.rejected:', action.payload);
          // state.generalSnackbarMessage = 'Error creating course';
          // state.generalSnackbarOpen = true;
          state.loading = false;
          state.creatingCourse = false;

      })
        .addCase(loadMostRecentChat.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(loadMostRecentChat.fulfilled, (state, action) => {
          // console.log("loadMostRecentChat.fulfilled");
          const courseId = action.payload;
          const savedChats = state.IDToConversation[courseId];
          if (savedChats) {
            // const savedChats = state.savedChats[courseId];
            const mostRecentChatTitle = Object.keys(savedChats).slice(-1)[0];
            state.selectedChatTitle = mostRecentChatTitle;
            state.messages = savedChats[mostRecentChatTitle];
          } else {
            state.selectedChatTitle = "New Chat";
            state.messages = [];
            state.currentConversationID = null;
          }

        })
        .addCase(loadMostRecentChat.rejected, (state, action) => {
            console.error('Error loading most recent chat:', action.payload);
            state.loading = false;
        })
      .addCase(uploadContextFileAPI.pending, (state, action) => {
        // state.loading = true;
        state.contextFileLoading = true;
        state.selectedResourceType = 'file';
        // state.selectedResouceType = 'file';
      })
      .addCase(uploadContextFileAPI.fulfilled, (state, action) => {
        // const {url} = action.payload;
        // state.loading = false;
        // state.selectedResource = url;
        // state.contextResourceSelection = true;
        customLog('uploadContextFileAPI.fulfilled');
        const {fileID, filePreview, fileURL, initialPage} = action.payload;
        customLog('values for selectedResource and ID now are ' + state.selectedResource + ' ' + state.selectedResourceID);
        state.selectedResource = {id: fileID, type: 'file', title: 'Rag File', url: fileURL, previewImageUrl: filePreview, locations: [initialPage]};
        // state.resources = [{id: fileID, type: 'file', title: 'Rag File', url: fileURL, previewImageUrl: filePreview, locations: [initialPage]}]
        state.selectedResourceID = fileID;
        state.selectedResourceType = 'file';
        state.generalSnackbarMessage = "Talk to Plato about your file!";
        state.generalSnackbarOpen = true;
        state.contextFileLoading = false;
        customLog('values for selectedResource and ID after update are ' + state.selectedResource + ' ' + state.selectedResourceID);
      })
      .addCase(uploadContextFileAPI.rejected, (state, action) => {
        customLog('uploadContextFileAPI rejected :', action.payload);
        state.contextFileLoading = false;
        state.selectedResourceType = null;
        state.selectedResource = null;
        state.selectedResourceID = null;
      })
      .addCase(getFileURLFromUUID.pending, (state, action) => {
        // state.contextFileLoading = true;
        state.contextFileLoading = true;
        state.selectedResourceType = 'file';
        // state.selectedResouceType = 'file';
      })
      .addCase(getFileURLFromUUID.fulfilled, (state, action) => {
        // const {url} = action.payload;
        // state.loading = false;
        // state.selectedResource = url;
        // state.contextResourceSelection = true;
        customLog('getFileURLFromUUID.fulfilled');
        const {fileID, fileURL} = action.payload;
        customLog('values for selectedResource and ID now are ' + state.selectedResource + ' ' + state.selectedResourceID);
        state.selectedResource = {id: fileID, type: 'file', title: 'Rag File', url: fileURL, locations: [0]};
        // state.resources = [{id: fileID, type: 'file', title: 'Rag File', url: fileURL, previewImageUrl: filePreview, locations: [initialPage]}]
        state.contextFileLoading = false;
        state.selectedResourceID = fileID;
        state.selectedResourceType = 'file';
        state.generalSnackbarMessage = "Talk to Plato about your file!";
        state.generalSnackbarOpen = true;
        customLog('values for selectedResource and ID after update are ' + state.selectedResource + ' ' + state.selectedResourceID);
      })
      .addCase(getFileURLFromUUID.rejected, (state, action) => {
        customLog('getFileURLFromUUID rejected :', action.payload);
        state.contextFileLoading = false;
        state.selectedResourceType = null;
        state.selectedResource = null;
        state.selectedResourceID = null;
      })
      .addCase(uploadLongtermFileAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(uploadLongtermFileAPI.fulfilled, (state, action) => {
        // const {file} = action.payload;
        customLog('uploadLongtermFileAPI.fulfilled');
        state.generalSnackbarMessage = "Plato's adding it to memory!";
        state.generalSnackbarOpen = true;
        state.loading = false;
      })
      .addCase(uploadLongtermFileAPI.rejected, (state, action) => {
        customLog('uploadLongtermFileAPI.rejected: ', action.payload);
        state.loading = false;
      })
      .addCase(updateCourseAPI.pending, (state, action) => {//TODO: success assumed 
        // state.loading = true;
        const {courseName, courseId} = action.meta.arg;
        state.storedCourseName = state.courses[courseId];
        if (courseId !== state.allCoursesID) {
          state.courses[courseId] = courseName;
        }
      })
      .addCase(updateCourseAPI.fulfilled, (state, action) => {
        // const {courseName, courseId} = action.payload;

        // Update the courses object
        //state.courses[courseId] = courseName; TOOD: this used to be fulfilled

        // .addCase(editCourseName.pending, (state, action) => {
        //     state.loading = true;
        // });//TODO: add more cases, need to figure out how course to chatTitles/content mapping will look like on FE
      })
      .addCase(updateCourseAPI.rejected, (state, action) => {
          customLog('updateCourseAPI.rejected: ', action.payload);
          const {courseName, courseId} = action.meta.arg;
          state.courses[courseId] = state.storedCourseName;
          state.storedCourseName = "Error";
          state.loading = false;
      })
      .addCase(deleteCourseAPI.pending, (state, action) => {
        // state.loading = true;
        const {courseId} = action.meta.arg; //TODO: used to be fulfilled, success now assumed
        if (courseId !== state.allCoursesID) {
          delete state.courses[courseId];
        }
      })
      .addCase(deleteCourseAPI.fulfilled, (state, action) => {
        // const {courseId} = action.payload;
        // Remove the course from the courses object
          //         // Update the currentOrderedChatIDs
          // state.currentOrderedChatIDs = [];

          // // Update the messages array
          // state.messages = [];
        //delete state.courses[courseId];
      })
      .addCase(deleteCourseAPI.rejected, (state, action) => {
          customLog('deleteCourseAPI.rejected: ', action.payload);
          const {courseId} = action.meta.arg;
          state.courses[courseId] = action.meta.arg.courseName;
          state.loading = false;
      })
      .addCase(searchLongtermFilesAPI.fulfilled, (state, action) => {
        
        
        if (action.payload.found) {
          const {fileID, ragFilePreview, ragFileURL, initialPage} = action.payload;
          const newResources = [//TODO: location may be off by one
          {id: fileID, type: 'file', title: 'Rag File', url: ragFileURL, previewImageUrl: ragFilePreview, locations: [initialPage]}
          ]
          if (state.currentConversationID) {
            state.resources = newResources;
          }
          
        }


      })
      .addCase(searchLongtermFilesAPI.rejected, (state, action) => {
        console.error('Error searching files:', action.payload);
      })
      .addCase(searchLongtermFilesAPI.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(editChatTitleAPI.pending, (state, action) => {
        const {conversationID, newTitle} = action.meta.arg;
        state.storedChatName = state.IDToChatTitle[state.selectedCourse][conversationID];
        state.IDToChatTitle[state.selectedCourse][conversationID] = newTitle;
        state.loading = true;
      })
      .addCase(editChatTitleAPI.fulfilled, (state, action) => {
        // const {conversationID, newTitle} = action.payload;
        // state.IDToChatTitle[state.selectedCourse][conversationID] = newTitle;
        state.loading = false;
      })
      .addCase(editChatTitleAPI.rejected, (state, action) => {
        customLog('editChatTitleAPI.rejected:', action.payload);
        const {conversationID, newTitle} = action.meta.arg;
        state.IDToChatTitle[state.selectedCourse][conversationID] = state.storedChatName;
        state.storedChatName = "Error";
        state.loading = false;
      })
      .addCase(removeChatAPI.pending, (state, action) => {
          // Assume the chat is successfully removed
        const { conversationID } = action.meta.arg;
        
        // Temporarily store data for possible rollback
        const previousData = {
          conversation: state.IDToConversation[state.selectedCourse][conversationID],
          chatTitle: state.IDToChatTitle[state.selectedCourse][conversationID],
          currentOrderedChatIDs: [...state.currentOrderedChatIDs],
          courseIDsToRecentChatIDs: [...state.courseIDsToRecentChatIDs[state.selectedCourse]]
        };
        state.previousRemoveChatData = previousData; // Store previous data in state for rollback

        // Optimistically delete the chat
        delete state.IDToConversation[state.selectedCourse][conversationID];
        delete state.IDToChatTitle[state.selectedCourse][conversationID];
        state.currentOrderedChatIDs = state.currentOrderedChatIDs.filter(id => id !== conversationID);
        state.courseIDsToRecentChatIDs[state.selectedCourse] = state.courseIDsToRecentChatIDs[state.selectedCourse].filter(id => id !== conversationID);

        // Update selected chat if current conversation is deleted
        if (state.currentConversationID === conversationID) {
          const remainingChatTitles = Object.keys(state.IDToChatTitle[state.selectedCourse]);
          // if (remainingChatTitles.length > 0) {
          //   const newSelectedChatTitle = remainingChatTitles[0];
          //   state.selectedChatTitle = state.IDToChatTitle[state.selectedCourse][newSelectedChatTitle];
          //   state.messages = state.IDToConversation[state.selectedCourse][newSelectedChatTitle];
          //   state.currentConversationID = newSelectedChatTitle;
          // } else {
            state.selectedChatTitle = "New Chat";
            state.currentConversationID = null;
            state.messages = [];
          // }
        }
        state.loading = true;
      })
      .addCase(removeChatAPI.fulfilled, (state, action) => {
        // const { conversationID } = action.payload;
        // delete state.IDToConversation[state.selectedCourse][conversationID];
        // delete state.IDToChatTitle[state.selectedCourse][conversationID];
        // // Remove conversationID from currentOrderedChatIDs
        // state.currentOrderedChatIDs = state.currentOrderedChatIDs.filter(id => id !== conversationID);

        // // Remove conversationID from courseIDsToRecentChatIDs
        // state.courseIDsToRecentChatIDs[state.selectedCourse] = state.courseIDsToRecentChatIDs[state.selectedCourse].filter(id => id !== conversationID);
        
        // if (state.currentConversationID === conversationID) { //TODO: can combine this with handleLoadChat 
        //   // Automatically select a new chatTitle
        //   // console.log("Current chat being deleted");
        //   const remainingChatTitles = Object.keys(state.IDToChatTitle[state.selectedCourse]);
        //   // if remaining chat titles exist & currentConversationID is not null
        //   customLog('remainingChatTitles in removeChatAPI.f is ' + remainingChatTitles);
        //   customLog('state.currentConversationID is ' + state.currentConversationID);
        //   if (remainingChatTitles.length > 0) {
        //     // Assuming the first item is the newest after sorting
        //     const newSelectedChatTitle = remainingChatTitles[0];
        //     state.selectedChatTitle = state.IDToChatTitle[state.selectedCourse][newSelectedChatTitle];
        //     state.messages = state.IDToConversation[state.selectedCourse][newSelectedChatTitle];
        //     state.currentConversationID = newSelectedChatTitle;
        //   } else {
        //     state.selectedChatTitle = "New Chat";
        //     state.currentConversationID = null;
        //     state.messages = [];
        //   }
        // }
        state.previousRemoveChatData = {};
        state.loading = false;
      })
      .addCase(removeChatAPI.rejected, (state, action) => {
          // Roll back to previous state
          const { previousRemoveChatData } = state;
          const { conversationID } = action.meta.arg;
          state.IDToConversation[state.selectedCourse][conversationID] = previousRemoveChatData.conversation;
          state.IDToChatTitle[state.selectedCourse][conversationID] = previousRemoveChatData.chatTitle;
          state.currentOrderedChatIDs = previousRemoveChatData.currentOrderedChatIDs;
          state.courseIDsToRecentChatIDs[state.selectedCourse] = previousRemoveChatData.courseIDsToRecentChatIDs;

          if (state.currentConversationID === conversationID) {
            state.selectedChatTitle = previousRemoveChatData.chatTitle;
            state.messages = previousRemoveChatData.conversation;
            state.currentConversationID = conversationID;
          }

          customLog('removeChatAPI.rejected: ', action.payload);
          state.loading = false;
      })
      .addCase(loginUserAPI.pending, (state, action) => {
        state.loading = true;
        if(!action.meta.arg.signup) {
          state.generalSnackbarMessage = "Logging In"
          state.generalSnackbarOpen = true;
        }
      })
      .addCase(loginUserAPI.fulfilled, (state, action) => {
        const {userName, APIToken, refreshToken} = action.payload;
        state.generalSnackbarOpen = false;
        state.refreshToken = refreshToken;
        state.APIToken = APIToken;
        state.username = userName;
        state.isLoggedIn = true;
        if (!action.payload.signup) {
          state.generalSnackbarMessage = "Successfully Logged In!"
          state.generalSnackbarOpen = true;
        }
        state.loading = false;
      })
      .addCase(loginUserAPI.rejected, (state, action) => { //TODO: Actual rejection logic
        customLog('Error logging in user:', action.payload);
        state.loading = false;
        state.generalSnackbarOpen = false;
        state.generalSnackbarMessage = "Login Failed"
        state.generalSnackbarOpen = true;
      })
      .addCase(loginUserGoogleAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginUserGoogleAPI.fulfilled, (state, action) => {
        const { APIToken, refreshToken } = action.payload;
        state.generalSnackbarOpen = false;
        state.refreshToken = refreshToken;
        state.APIToken = APIToken;
        state.isLoggedIn = true;
        state.loading = false;
      })
      .addCase(loginUserGoogleAPI.rejected, (state, action) => {
        customLog('Error logging in user via Google:', action.payload);
        state.loading = false;
        state.generalSnackbarMessage = "Google Login Failed";
        state.generalSnackbarOpen = true;
      })      
      .addCase(getChatTitleAPI.fulfilled, (state, action) => { //TODO: Handle success
        const { chatTitle, conversation_id } = action.payload;
        state.selectedChatTitle = chatTitle;
        // console.log('conversation id in createChatTitle is ' + conversation_id);
        // Update the IDToChatTitle
        // if (!state.IDToChatTitle[state.selectedCourse]) {
        //   state.IDToChatTitle[state.selectedCourse] = {};
        // }
        state.IDToChatTitle[state.selectedCourse][conversation_id] = chatTitle;
        // Check if length of currentOrderedChatIDs is greater than 5
        if (state.currentOrderedChatIDs.length > 4) {
          state.currentOrderedChatIDs.pop();
          state.courseIDsToRecentChatIDs[state.selectedCourse].pop();
        }
        customLog('about to add conversation_id to currentOrderedChatIDs in createChatTitle.f');
        // Add conversationID to currentOrderedChatIDs
        state.currentOrderedChatIDs.unshift(conversation_id);


        // Add conversationID to courseIDsToRecentChatIDs
        // Check if courseIDsToRecentChatIDs[selectedCourse] exists, if not, initialize it as an empty array
        if (!state.courseIDsToRecentChatIDs[state.selectedCourse]) {
          state.courseIDsToRecentChatIDs[state.selectedCourse] = [];
        }
        state.courseIDsToRecentChatIDs[state.selectedCourse].unshift(conversation_id);

        state.loading = false;
      })
      .addCase(getChatTitleAPI.rejected, (state, action) => {
        console.error('Error getting chat title:', action.payload);
      })
      .addCase(getChatTitleAPI.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(getInitialUserData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getInitialUserData.fulfilled, (state, action) => {
        const {courseIDToCourseTitle, currentConversationID, currentCourse, currentMessages, 
              courseIDsToRecentChatIDs, IDToChatTitle, IDToConversation, 
              currentOrderedChatIDs, APIToken, email, flashcardGroups, quizzes,
              allRAGFiles, firstName, profilePicture
            } = action.payload;
        //return ({IDToConversation, IDToChatTitle, currentOrderedChatIDs, courseIDsToRecentChatIDs});
        // state.courses = courses;        currentConversationID: mostRecentConversationID,
        // currentCourse: mostRecentCourse,
        // currentMessages: mostRecentMessages
        state.courses = courseIDToCourseTitle;
        state.courseIDsToRecentChatIDs = courseIDsToRecentChatIDs;
        state.IDToConversation = IDToConversation;
        state.IDToChatTitle = IDToChatTitle;
        state.selectedCourse = currentCourse;
        state.APIToken = APIToken;
        state.isLoggedIn = true;
        state.username = email;
        state.firstName = firstName;
        state.profilePicture = profilePicture;
        state.allFlashcardGroups = flashcardGroups;
        state.allQuizGroups = quizzes;
        state.allRAGFiles = allRAGFiles;
        if(!currentOrderedChatIDs) {
          state.currentOrderedChatIDs = [];
        } else {
          state.currentOrderedChatIDs = currentOrderedChatIDs;
        }
        state.loading = false;
      })
      .addCase(getInitialUserData.rejected, (state, action) => {
        console.error('Error getting initial user data:', action.payload);
        state.loading = false;
        state.isLoggedIn = false;
      })
      .addCase(registerUserAPI.rejected, (state, action) => {
        console.error('Error registering user:', action.payload);
        state.generalSnackbarOpen = false;
        state.generalSnackbarMessage = "Sign Up Failed"
        state.generalSnackbarOpen = true;
        state.loading = false;
      })
      .addCase(registerUserAPI.pending, (state, action) => {
        // setGeneralSnackbarOpen: (state, action) => {
        //   state.generalSnackbarOpen = action.payload;
        // },
        // setGeneralSnackbarMessage: (state, action) => {
        //   state.generalSnackbarMessage = action.payload;
        // },
        state.generalSnackbarOpen = true;
        state.generalSnackbarMessage = "Signing Up"
        state.loading = true;
      })
      .addCase(registerUserAPI.fulfilled, (state, action) => {
        state.generalSnackbarOpen = false;
        //TODO: Figure out how to have this on screen for a sec after sign up
        // state.generalSnackbarMessage = "Welcome to Plato!"
        state.generalSnackbarMessage = "Welcome to Plato!"
        state.generalSnackbarOpen = true;
        state.loading = false;
      })
      .addCase(getFlashcardsAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFlashcardsAPI.fulfilled, (state, action) => {
        const {fileID} = action.payload;
        // state.flashcards = flashcards;
        // customLog('flashcards from getFlashcardsAPI is ' + flashcards);
        // state.flashcardsScreenOpen = false;
        // state.selectedResource = {id: 'temp', type: 'flashcard', title: 'flashcardTest', content: flashcards};
        // state.selectedResourceID = 'test';
        // customLog('selectedResource from getFlashcardsAPI is ' + JSON.stringify(state.selectedResource));
        // state.loading = false;


        customLog('store.js getFlashcardsAPI called with ' + action.payload);
        // state.flashcardPrompt = action.payload;
        const flashcards = [{"Question": "What is the capital of France?", "Answer": "Paris"}, {"Question": "What is the capital of Germany?", "Answer": "Berlin"}];
        // state.selectedResourceID = 'temp';
        // state.selectedResourceType = 'flashcard';
        // state.selectedResource = {id: 'temp', type: 'flashcard', title: 'flashcardTest', content: flashcards};
        state.flashcardPrompt = fileID;
        state.flashcards = flashcards;
        customLog('flashcards from getFlashcardsAPI is ' + flashcards);
        state.flashcardsScreenOpen = false;
        state.selectedResource = {id: 'temp', type: 'flashcard', title: 'flashcardTest', content: flashcards};
        state.selectedResourceID = 'test';
        customLog('selectedResource from getFlashcardsAPI is ' + JSON.stringify(state.selectedResource));
      })
      .addCase(getFlashcardsAPI.rejected, (state, action) => {
        console.error('Error getting flashcards:', action.payload);
        state.loading = false;
      })
      .addCase(getQuizAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getQuizAPI.fulfilled, (state, action) => {
        // const {quiz} = action.payload;
        // state.quiz = quiz;
        // customLog('quiz from getQuizAPI is ' + quiz);
        // state.quizMeScreenOpen = false;
        // state.selectedResource = {id: 'temp', type: 'quiz', title: 'quizTest', content: quiz};
        // state.selectedResourceID = 'temp';
        // customLog('selectedResource from getQuizAPI is ' + JSON.stringify(state.selectedResource));
        // state.loading = false;

        const {fileID} = action.payload;


        customLog('store.js getQuizAPI called with ' + action.payload);
        const quiz = [{Q: "What is OOP?", A: "Out Of Pants", B: "Object Oriented Pussy", C: "Object Oriented Programming", D: "Out Of Place", Correct: "B"}, {Q: "What is the capital of France?", A: "Paris", B: "London", C: "Berlin", D: "Madrid", Correct: "A"}];
        state.quizPrompt = fileID;
        state.quiz = quiz;
        customLog('quiz from getQuizAPI is ' + quiz);
        state.quizMeScreenOpen = false;
        state.selectedResource = {id: 'temp', type: 'quiz', title: 'quizTest', content:  quiz};
        state.selectedResourceID = 'test';
        customLog('selectedResource from getQuizAPI is ' + JSON.stringify(state.selectedResource));
      })
      .addCase(getQuizAPI.rejected, (state, action) => {
        console.error('Error getting quiz:', action.payload);
        state.loading = false;
      })
      .addCase(getFlashcardsLibrary.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFlashcardsLibrary.fulfilled, (state, action) => {
        state.currentFlashcardGroup = action.payload;
      })
      .addCase(getFlashcardsLibrary.rejected, (state, action) => {
        console.error('Error getting flashcards:', action.payload);
        state.loading = false;
      })
      .addCase(getAllFlashcards.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllFlashcards.fulfilled, (state, action) => {
        state.allFlashcardGroups = action.payload;
      })
      .addCase(getAllFlashcards.rejected, (state, action) => {
        console.error('Error getting all flashcards:', action.payload);
        state.loading = false;
      })
      .addCase(getAllQuizzes.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllQuizzes.fulfilled, (state, action) => {
        state.allQuizGroups = action.payload;
      })
      .addCase(getAllQuizzes.rejected, (state, action) => {
        console.error('Error getting all quizzes:', action.payload);
        state.loading = false;
      })
      .addCase(getAllFiles.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllFiles.fulfilled, (state, action) => {
        customLog('GETALLFILES FULFILLED IS NOW ' + JSON.stringify(action.payload));
        state.allRAGFiles = action.payload;
      })
      .addCase(getAllFiles.rejected, (state, action) => {
        console.error('Error getting all files:', action.payload);
        state.loading = false;
      })
      .addCase(getQuizLibrary.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getQuizLibrary.fulfilled, (state, action) => {
        // customLog('SETFLASHCARDS before ' + state.flashcards);
        // customLog('SETFLASHCARDS ACTION.PAYLOAD ' + JSON.stringify(action.payload));
        const {formattedQuiz} = action.payload;
        state.quiz = formattedQuiz;
        // customLog('SETFLASHCARDS after ' + JSON.stringify(state.flashcards));
        state.flashcardsScreenOpen = false;
        state.currentFlashcardQuizIndex = 0;
        //state.selectedResource = {id: 'temp', type: 'quiz', title: 'quizTest', content: formattedQuiz};
        state.selectedResourceID = 'test';
      })
      .addCase(getQuizLibrary.rejected, (state, action) => {
        console.error('Error getting quiz:', action.payload);
        state.loading = false;
      })
      .addCase(getAppendFlashcardToGroup.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAppendFlashcardToGroup.fulfilled, (state, action) => {
        const {flashcard, incrementCard} = action.payload;
        if (state.currentFlashcardGroup) {
          state.currentFlashcardGroup.flashcards.push(flashcard);
        }
        if (incrementCard) {
          state.currentFlashcardIndex += 1;
        }

        if (state.contextFileLoading) {
          state.contextFileLoading = false;
        }
        // state.currentFlashcardIndex += 1;
      })
      .addCase(deleteFileAPI.fulfilled, (state, action) => {
        state.allRAGFiles = state.allRAGFiles.filter(file => file.id !== action.payload);
      })
      .addCase(deleteFileAPI.rejected, (state, action) => {
        console.error('Failed to delete file:', action.payload);
      })
      .addCase(getAllLessons.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllLessons.fulfilled, (state, action) => {
        customLog('GETALLLESSONS FULFILLED IS NOW ' + JSON.stringify(action.payload));
        state.allLessonGroups = action.payload.lesson;
        
        // Extract and store the top 4 lesson groups
        state.top4LessonGroups = action.payload.lesson.slice(0, 4);

        customLog('TOP4LESSONGROUPS IS NOW ' + JSON.stringify(state.top4LessonGroups));
      })
      .addCase(getAllLessons.rejected, (state, action) => {
        console.error('Error getting all lessons:', action.payload);
        state.loading = false;
      })
      .addCase(approveLessonAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(approveLessonAPI.fulfilled, (state, action) => {
        customLog('APPROVELESSONAPI FULFILLED', action.payload);
        state.guidedLessonModeActive = true;
        state.loading = false;
        // in the object obtained from action.payload check how many objects there are in the subtopics field of the first element of the topics field of the object
        state.currentNumberSubtopics = action.payload.topics[0].subtopics.length;
        // state.generalSnackbarMessage = "Lesson approved successfully";
        // state.generalSnackbarOpen = true;
      })
      .addCase(approveLessonAPI.rejected, (state, action) => {
        customLog('APPROVELESSONAPI REJECTED', action.payload);
        state.loading = false;
        state.generalSnackbarMessage = "Failed to approve lesson";
        state.generalSnackbarOpen = true;
      })
      .addCase(uploadResumeAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(uploadResumeAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.resumeExploreRecommendations = action.payload;
      })
      .addCase(uploadResumeAPI.rejected, (state, action) => {
        console.error('Error uploading resume:', action.payload);
        state.loading = false;
      })
      .addCase(getTopicContent.pending, (state, action) => {
        //state.loading = true;
        //state.centerPanelLoading = true;
        state.topicContentLoading = true;

      })
      .addCase(getTopicContent.fulfilled, (state, action) => {
        customLog('currentTopicObject is ' + JSON.stringify(action.payload.status));
        customLog('topicIndex is ' + action.payload.currentDisplayedTopicIndex);
        //state.loading = false;
        //state.centerPanelLoading = false;
        state.currentTopicID = action.payload.topicID;
        state.currentTopicObject = action.payload.status;
        state.currentDisplayedTopicIndex = action.payload.currentDisplayedTopicIndex;
        state.currentDisplayedTopicContent = action.payload.status.conversation[action.payload.currentDisplayedTopicIndex];

        state.currentTopicIndex = action.payload.topicIndex;
        state.topicContentLoading = false;
      })
      .addCase(getTopicContent.rejected, (state, action) => {
        console.error('Error getting topic content:', action.payload);
        //state.loading = false;
        //state.centerPanelLoading = false;
        state.topicContentLoading = false;
      })
      .addCase(getExploresAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getExploresAPI.fulfilled, (state, action) => {
        state.exploresArray.push(...action.payload);
        if (action.payload.length > 0) {
          const lastExplore = action.payload[action.payload.length - 1];//
          state.lastAPIExploreIndexCalled = lastExplore.added_id;
          if (!state.currentDisplayedExploreIndex) {
            state.currentDisplayedExploreIndex = action.payload[0].added_id;
          }
        }
        state.loading = false;
      })
      .addCase(getExploresAPI.rejected, (state, action) => {
        console.error('Error getting explores:', action.payload);
        state.loading = false;
      })
    },
  });

const handleSnackbarError = (message, dispatch) => {
  dispatch(setGeneralSnackbarMessage(message));
  dispatch(setGeneralSnackbarOpen(true));
};

// Add this new asyncThunk
export const deleteFileAPI = createAsyncThunk(
  'chat/deleteFile',
  async (fileId, { getState, rejectWithValue }) => {
    try {
      const response = await fetch(`${DjangoHost}/api/file/delete/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getState().chat.APIToken}`,
        },
        body: JSON.stringify({ file_id: fileId }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete file');
      }

      return fileId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Add this new async thunk
export const getAllLessons = createAsyncThunk(
  'chat/getAllLessons',
  async (_, { dispatch, getState }) => {
    const { APIToken } = getState().chat;
    try {
      const response = await fetch(DjangoHost + "/api/lessons/all/", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${APIToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // const lessons = {
      //   "lessons": [
      //     {
      //       "name": "Applied LLMs",
      //       "id": "123e4567-e89b-12d3-a456-426614174000",
      //       "topics": [
      //         {
      //           "name": "Agents",
      //           "progress": 85,
      //           "id": "987e6543-e21b-12d3-a456-426614174111",
      //           "active_checkpoint": "234e5678-e89b-12d3-a456-426614174222",
      //           "locked": false,
      //           "conversation": {
      //             "conversation_id": "111b222c-333d-444e-555f-666677778888",
      //             "conversation_name": "Understanding Agents",
      //             "course_id": "123e4567-e89b-12d3-a456-426614174000",
      //             "owner_id": "12345678-90ab-cdef-1234-567890abcdef",
      //             "last_message_timestamp": "2024-11-11T08:22:20Z",
      //             "messages": [
      //               {
      //                 "id": "000a111b-222c-333d-444e-555f66667777",
      //                 "conversation": "111b222c-333d-444e-555f-666677778888",
      //                 "text": "What are intelligent agents?",
      //                 "timestamp": "2024-11-11T08:21:00Z",
      //                 "user": "User"
      //               },
      //               {
      //                 "id": "111a222b-333c-444d-555e-666f77778888",
      //                 "conversation": "111b222c-333d-444e-555f-666677778888",
      //                 "text": "Agents are systems that perceive their environment and take actions to maximize their chance of achieving their goals.",
      //                 "timestamp": "2024-11-11T08:21:30Z",
      //                 "user": "Assistant"
      //               }
      //             ]
      //           }
      //         },
      //         {
      //           "name": "RAG",
      //           "progress": 0,
      //           "id": "876e5432-e21b-12d3-a456-426614174333",
      //           "locked": true
      //         },
      //         {
      //           "name": "Context",
      //           "progress": 0,
      //           "id": "876e5432-e21b-12d3-a456-426614174444",
      //           "locked": true
      //         },
      //         {
      //           "name": "Conversation Length",
      //           "progress": 0,
      //           "id": "876e5432-e21b-12d3-a456-426614174555",
      //           "locked": true
      //         },
      //         {
      //           "name": "Using Documents",
      //           "progress": 0,
      //           "id": "876e5432-e21b-12d3-a456-426614174666",
      //           "locked": true
      //         },
      //         {
      //           "name": "Audio Processing",
      //           "progress": 0,
      //           "id": "876e5432-e21b-12d3-a456-426614174777",
      //           "locked": true
      //         },
      //         {
      //           "name": "Video Analysis",
      //           "progress": 0,
      //           "id": "876e5432-e21b-12d3-a456-426614174888",
      //           "locked": true
      //         },
      //         {
      //           "name": "Integration Techniques",
      //           "progress": 0,
      //           "id": "876e5432-e21b-12d3-a456-426614174999",
      //           "locked": true
      //         },
      //         {
      //           "name": "Performance Optimization",
      //           "progress": 0,
      //           "id": "876e5432-e21b-12d3-a456-426614175000",
      //           "locked": true
      //         },
      //         {
      //           "name": "Security Concerns",
      //           "progress": 0,
      //           "id": "876e5432-e21b-12d3-a456-426614175111",
      //           "locked": true
      //         }
      //       ]
      //     },
      //     {
      //       "name": "Physics",
      //       "id": "223e4567-e89b-12d3-a456-426614174444",
      //       "topics": [
      //         {
      //           "name": "Mechanics",
      //           "progress": 70,
      //           "id": "111e6543-e21b-12d3-a456-426614174555",
      //           "active_checkpoint": "123e5678-e89b-12d3-a456-426614174666",
      //           "locked": false,
      //           "conversation": {
      //             "conversation_id": "333b444c-555d-666e-777f-888899990000",
      //             "conversation_name": "Basics of Mechanics",
      //             "course_id": "223e4567-e89b-12d3-a456-426614174444",
      //             "owner_id": "22345678-90ab-cdef-1234-567890abcdef",
      //             "last_message_timestamp": "2024-11-12T12:00:00Z",
      //             "messages": [
      //               {
      //                 "id": "333a444b-555c-666d-777e-888f99990000",
      //                 "conversation": "333b444c-555d-666e-777f-888899990000",
      //                 "text": "In mechanics, we study motion and its causes, namely forces.",
      //                 "timestamp": "2024-11-12T11:59:00Z",
      //                 "user": "Assistant"
      //               }
      //             ]
      //           }
      //         }
      //       ]
      //     }
      //   ]
      // }
      const lessons = await response.json();
      customLog('GETALLLESSONS lessons is ' + JSON.stringify(lessons));
      return lessons;
    } catch (error) {
      console.error("Error in getAllLessons:", error);
    }
  }
);

// Add this new async thunk for approving lessons
export const approveLessonAPI = createAsyncThunk(
  'chat/approveLessonAPI',
  async (lessonId, { dispatch, getState }) => {
    const { APIToken } = getState().chat;
    try {
      customLog('approveLessonAPI before API call with lessonId ' + lessonId);
      const response = await fetch(DjangoHost + "/api/lessons/confirm/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getState().chat.APIToken}`,
        },
        body: JSON.stringify({ lesson_id: lessonId }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      customLog('approveLessonAPI after API call');

      const lessonResponse = await response.json();
    //   const lesson = {
    //     "lesson": {
    //         "name": "Mathematics",
    //         "id": "123e4567-e89b-12d3-a456-426614174000",
    //         "topics": [
    //             {
    //                 "name": "1. Algebra",
    //                 "progress": 0, 
    //                 "locked": false,
    //                 "id": "987e6543-e21b-12d3-a456-426614174111",
    //                 "subtopics": [
    //                     {
    //                         "name": "1.1 Linear Equations"
    //                     },
    //                     {
    //                         "name": "1.2 Quadratic Equations"
    //                     },
    //                     {
    //                         "name": "1.3Polynomials"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "name": "2. Geometry",
    //                 "progress": 0,
    //                 "locked": true,
    //                 "id": "876e5432-e21b-12d3-a456-426614174222",
    //                 "subtopics": [
    //                     {
    //                         "name": "2.1 Triangles"
    //                     },
    //                     {
    //                         "name": "2.2 Circles"
    //                     },
    //                     {
    //                         "name": "2.3 Coordinate Geometry"
    //                     } overview STLC testing met, tool
    //                 ]
    //             }
    //         ]
    //     }
    // }
    customLog('APPROVELESSONAPI lessonResponse is ' + JSON.stringify(lessonResponse));
      dispatch(setCurrentLessonGroup(lessonResponse.lesson));
      // dispatch(setGeneralSnackbarMessage("Lesson approved successfully!"));
      // dispatch(setGeneralSnackbarOpen(true));
      return lessonResponse.lesson;
    } catch (error) {
      console.error("Error in approveLesson:", error);
      dispatch(setGeneralSnackbarMessage("Failed to approve lesson"));
      dispatch(setGeneralSnackbarOpen(true));
      throw error;
    }
  }
);


// Async thunk for getting chat title from API
export const getChatTitleAPI = createAsyncThunk(
  'chat/getChatTitleAPI',
  async ({ conversation_id }, { dispatch, getState, rejectWithValue }) => {
    try {
      let chatTitle ="";
      const maxRetries = 10; // Maximum number of attempts
      const retryDelay = 3000; // Delay between attempts in milliseconds

      // Function to delay execution
      const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      customLog('getChatTitleAPI called for conversation_id ' + conversation_id);
      const attemptFetch = async (attempt) => {
        try { 
          customLog('beginning of try');
          const response = await fetch(DjangoHost+"/api/conversation/"+conversation_id+"/metadata/", {
            method: "GET",
            headers: {Authorization:  `Bearer ${getState().chat.APIToken}`}                   
          });
          customLog('after response');
          // const data = await response.json();
          // if (!response.ok) throw new Error('Request failed');
          customLog('this is attempt number ' + attempt + 'with max retries ' + maxRetries);
          customLog('conversation_id header is  ' + response.headers.get('conversation_id'));
          customLog('conversation_name header is ' + response.headers.get('conversation_name'));
          // customLog all headers from response
          // for (var pair of response.headers.entries()) {
          //   customLog(pair[0]+ ': '+ pair[1]);
          // }
          
          // let responseData = {
          //   // Extract and return necessary data from the response, e.g., headers
          //   conversationName: response.headers.get('conversation_name')
          // };
          // if (responseData.conversationName) {
          //   return responseData; // Return this data to the outer scope if conversationName is not null
          // } else {
          //   throw new Error('Conversation name not found');
          // }
          const data = await response.json();

          if(data.conversation_name) {
            chatTitle = data.conversation_name;
            return true;
          } else {
            throw new Error('Conversation name not found');
          }
          return chatTitle;
        } catch (error) {
          if (attempt < maxRetries) {
            await wait(retryDelay);
            return attemptFetch(attempt + 1);
          } else {
            customLog('else of getChatTitleAPI');
            dispatch(setGeneralSnackbarMessage('Failed to get chat title'));
            dispatch(setGeneralSnackbarOpen(true));
            throw error;
          }
        }
      };

      let fetchedData = await attemptFetch(1); // Start attempts with 1
      customLog('fetched data is ' + fetchedData);

      if (fetchedData) {
        // customLog('conversation_name from response header is ' + fetchedData.conversationName);
        // chatTitle = fetchedData.conversationName; // Example usage
        customLog('Chat title is ' + chatTitle);
        return ({chatTitle, conversation_id});
      }
      //TEMP UNTIL CHAT TITLE ENDPOINT UPDATED ON BACKEND
      // let chatTitle = "Temp Store!";

      customLog('outside fetchedData send');
      return ({chatTitle, conversation_id});
    } catch (error) {
      customLog('catch (error) of getChatTitleAPI');
      dispatch(setGeneralSnackbarMessage('Failed to get chat title'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  });

    // Async thunk for creating a course
    export const createCourseAPI = createAsyncThunk(
      'chat/createCourseAPI',
      async (courseName, { dispatch, getState, rejectWithValue }) => {
        try {

          customLog('createCourseAPI called with courseName ' + courseName);
          // Call the API endpoint to create a new course
          const response = await fetch(DjangoHost+"/api/course/", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization:  `Bearer ${getState().chat.APIToken}`,
            },
            body: JSON.stringify({ course_name: courseName }),
          });
            
          if (!response.ok) {
            customLog('!response.ok of createCourseAPI');
            dispatch(setGeneralSnackbarMessage('Failed to create course'));
            dispatch(setGeneralSnackbarOpen(true));
            throw new Error('Failed to create course');
          }
    
          if (response.status === 201) {
            const newCourse = await response.json();
            const courseID = newCourse.course_id;

            return ({courseName, courseID});  
          } else {
            customLog('response.status !== 200 of createCourse');
            dispatch(setGeneralSnackbarMessage('Failed to createCourse'));
            dispatch(setGeneralSnackbarOpen(true));
            throw new Error('Failed to create course with response status ' + response.status + ' and response text ' + response.text());
          }

        } catch (error) {
          customLog('catch (error) of createCourseAPI');
          dispatch(setGeneralSnackbarMessage('Failed to createCourse'));
          dispatch(setGeneralSnackbarOpen(true));
          return rejectWithValue(error.message);
        }
      }
    );


// Async thunk for logging in user
export const loginUserAPI = createAsyncThunk(
  'chat/loginUserAPI',
  async ({ userName, password, signup }, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('loginUserAPI called with userName ' + userName + ' and password ' + password);
      const response = await fetch(DjangoHost+"/api/token/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          
        },
        body: JSON.stringify({ username: userName, password }),
      });
        
      if (!response.ok) {
        throw new Error('Failed to log in the user');
      }

      const data = await response.json();
      const APIToken = data.access;
      const refreshToken = data.refresh;
      customLog('APIToken for this session is ' + APIToken);

      // Set the cookies for the tokens
      document.cookie = `jwt=${APIToken};path=/;secure;samesite=strict`;
      document.cookie = `refreshToken=${refreshToken};path=/;secure;samesite=strict`;

      return ({userName, APIToken, refreshToken, signup});

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for logging in user via Google OAuth
export const loginUserGoogleAPI = createAsyncThunk(
  'chat/loginUserGoogleAPI',
  async ({ authorization_code, provider }, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('LOGINUSERGOOGLEAPI called with ' + authorization_code + ' and ' + provider);
      const response = await fetch(DjangoHost + "/api/oauth/token-exchange/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ authorization_code, provider }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to log in the user via Google');
      }

      const data = await response.json();
      const APIToken = data.access;
      const refreshToken = data.refresh;
      customLog('APIToken for this session is ' + APIToken);

      // Set the cookies for the tokens
      document.cookie = `jwt=${APIToken};path=/;secure;samesite=strict`;
      document.cookie = `refreshToken=${refreshToken};path=/;secure;samesite=strict`;

      return { APIToken, refreshToken };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


// Async think for getting the flashcards associated with a file
export const getFlashcardsAPI = createAsyncThunk(
  'chat/getFlashcardsAPI',
  async (fileID, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('getFlashcardsAPI called');
      // Call the API endpoint to get the flashcards associated with a file
      //TODO: Uncomment once flashcards API is done
      // const response = await fetch(DjangoHost+"/api/flashcards/"+fileID, {
      //   method: 'GET',
      //   headers: {
      //     Authorization:  `Bearer ${getState().chat.APIToken}`,
      //   },
      // });
      // customLog('after response in getFlashcardsAPI');
      // if (!response.ok) {
      //   throw new Error('Failed to fetch flashcards');
      // }
      // customLog('before data in getFlashcardsAPI');
      // const flashcards = await response.json();
      // customLog('Flashcards data: ' + JSON.stringify(flashcards));
      //TEMP: flashcards test placeholder
      const flashcards = [{"Question": "What is the capital of France?", "Answer": "Paris"}, {"Question": "What is the capital of Germany?", "Answer": "Berlin"}];
      return ({fileID});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAppendFlashcardToGroup = createAsyncThunk(
  '/chat/getAppendFlashcardToGroup',
  async ({flashcard, incrementCard}, { dispatch, getState, rejectWithValue}) => {
    try {
      customLog('GETAPPENDFLASHCARDTOGROUP with flashcard of ' + JSON.stringify(flashcard));

      return ({flashcard, incrementCard});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)

// Async think for getting the flashcards associated with a file
export const getFlashcardsLibrary = createAsyncThunk(
  'chat/getFlashcardsLibrary',
  async (item, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('getFlashcardsLibrary called');
      // Call the API endpoint to get the flashcards associated with a file
      //TODO: Uncomment once flashcards API is done
      // const response = await fetch(DjangoHost+"/api/flashcards/"+fileID, {
      //   method: 'GET',
      //   headers: {
      //     Authorization:  `Bearer ${getState().chat.APIToken}`,
      //   },
      // });
      // customLog('after response in getFlashcardsAPI');
      // if (!response.ok) {
      //   throw new Error('Failed to fetch flashcards');
      // }
      // customLog('before data in getFlashcardsAPI');
      // const flashcards = await response.json();
      // customLog('Flashcards data: ' + JSON.stringify(flashcards));
      //TEMP: flashcards test placeholder
      // const flashcards = [{"Question": "What is the capital of France?", "Answer": "Paris"}, {"Question": "What is the capital of Germany?", "Answer": "Berlin"}];
      return ({item});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async think for getting the flashcards associated with a file
export const getQuizLibrary = createAsyncThunk(
  'chat/getQuizLibrary',
  async (formattedQuiz, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('getQuizLibrary called');
      // Call the API endpoint to get the flashcards associated with a file
      //TODO: Uncomment once flashcards API is done
      // const response = await fetch(DjangoHost+"/api/flashcards/"+fileID, {
      //   method: 'GET',
      //   headers: {
      //     Authorization:  `Bearer ${getState().chat.APIToken}`,
      //   },
      // });
      // customLog('after response in getFlashcardsAPI');
      // if (!response.ok) {
      //   throw new Error('Failed to fetch flashcards');
      // }
      // customLog('before data in getFlashcardsAPI');
      // const flashcards = await response.json();
      // customLog('Flashcards data: ' + JSON.stringify(flashcards));
      //TEMP: flashcards test placeholder
      // const flashcards = [{"Question": "What is the capital of France?", "Answer": "Paris"}, {"Question": "What is the capital of Germany?", "Answer": "Berlin"}];
      return ({formattedQuiz});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async think for getting the flashcards associated with a file
export const getQuizAPI = createAsyncThunk(
  'chat/getQuizAPI',
  async (fileID, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('getQuizAPI called');
      // Call the API endpoint to get the flashcards associated with a file
      //TODO: Uncomment once flashcards API is done
      // const response = await fetch(DjangoHost+"/api/flashcards/"+fileID, {
      //   method: 'GET',
      //   headers: {
      //     Authorization:  `Bearer ${getState().chat.APIToken}`,
      //   },
      // });
      // customLog('after response in getFlashcardsAPI');
      // if (!response.ok) {
      //   throw new Error('Failed to fetch flashcards');
      // }
      // customLog('before data in getFlashcardsAPI');
      // const flashcards = await response.json();
      // customLog('Flashcards data: ' + JSON.stringify(flashcards));
      //TEMP: flashcards test placeholder
      const quiz = [{Q: "What is OOP?", A: "Out Of Pants", B: "Object Oriented Pussy", C: "Object Oriented Programming", D: "Out Of Place", Correct: "B"}, {Q: "What is the capital of France?", A: "Paris", B: "London", C: "Berlin", D: "Madrid", Correct: "A"}];
      return ({fileID});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSimilarFlashcard = (flashcardId) => async (dispatch, getState) => {
  try {
    const response = await fetch(`/api/flashcard/similar/${flashcardId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().chat.APIToken}`,
      },
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const similarFlashcard = await response.json();
    // dispatch(addSimilarFlashcard(similarFlashcard));
  } catch (error) {
    console.error('Error fetching similar flashcard:', error);
  }
};

// Define the getCookie function to parse cookies by name
const getCookie = (name) => {
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=');
    acc[key] = value;
    return acc;
  }, {});
  return cookies[name];
};

export const getSimilarFlashcard = createAsyncThunk(
  'chat/getSimilarFlashcard',
  async ({ flashcardID }, { dispatch, getState }) => {
    const { APIToken, currentFlashcardQuizIndex } = getState().chat;
    customLog('GETSIMILARFLASHCARD API call');
    dispatch(setGeneralSnackbarMessage("Plato's making another one!"));
    dispatch(setGeneralSnackbarOpen(true));
    try {
      const response = await fetch(DjangoHost + "/api/flashcards/similar/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${APIToken}`,
        },
        body: JSON.stringify({ flashcard_id: flashcardID }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const flashcard = await response.json();
      customLog('GETSIMILARFLASHCARD inserting ' + JSON.stringify(flashcard));
      dispatch(insertFlashcard({flashcard}));
    } catch (error) {
      console.error("Error in getSimilarFlashcard:", error);
    }
  }
);

export const getSimilarQuiz = createAsyncThunk(
  'chat/getSimilarQuiz',
  async ({ quizID }, { dispatch, getState }) => {
    const { APIToken, currentFlashcardQuizIndex } = getState().chat;
    try {
      dispatch(setGeneralSnackbarMessage("Plato's making another one!"));
      dispatch(setGeneralSnackbarOpen(true));
      const response = await fetch(DjangoHost + "/api/quiz/question/similar/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${APIToken}`,
        },
        body: JSON.stringify({ quiz_question_id: quizID }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const quizQuestion = await response.json();
      dispatch(insertQuizQuestion({quizQuestion}));
    } catch (error) {
      console.error("Error in getSimilarQuiz:", error);
    }
  }
);

export const getAllFlashcards = createAsyncThunk(
  'chat/getAllFlashcards',
  async (_, { dispatch, getState }) => {
    const { APIToken } = getState().chat;
    customLog('GETALLFLASHCARDS API call');
    try {
      const response = await fetch(DjangoHost + "/api/flashcard/all/", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${APIToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const flashcards = await response.json();
      customLog('GETALLFLASHCARDS received ' + JSON.stringify(flashcards.flashcards));
      return flashcards.flashcards;
    } catch (error) {
      console.error("Error in getAllFlashcards:", error);
    }
  }
);

export const getAllQuizzes = createAsyncThunk(
  'chat/getAllQuizzes',
  async (_, { dispatch, getState }) => {
    const { APIToken } = getState().chat;
    customLog('GETALLQUIZZES API call');
    try {
      const response = await fetch(DjangoHost + "/api/quiz/all/", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${APIToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const quizzes = await response.json();
      customLog('GETALLQUIZZES received ' + JSON.stringify(quizzes.quizzes));
      return quizzes.quizzes;
    } catch (error) {
      console.error("Error in getAllQuizzes:", error);
    }
  }
);

export const getAllFiles = createAsyncThunk(
  'chat/getAllFiles',
  async (_, { dispatch, getState }) => {
    const { APIToken } = getState().chat;
    customLog('GETALLFILES API call');
    try {
      const response = await fetch(DjangoHost + "/api/file/all/", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${APIToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const files = await response.json();
      customLog('GETALLFILES received ' + JSON.stringify(files.files));
      return files.files;
    } catch (error) {
      console.error("Error in getAllFiles:", error);
    }
  }
);

// Async thunk for fetching initial user data
export const getInitialUserData = createAsyncThunk(
  'chat/getInitialUserData',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const APIToken = getCookie('jwt');
      dispatch(setIsLoggedIn(true));
      customLog('getInitialUserData called with current APIToken ' + getState().chat.APIToken);
      
      // Dispatch getAllLessons early and non-blocking
      dispatch(getAllLessons());
      
      const response = await fetch(DjangoHost+"/api/initial-user-data/", {
        method: 'GET',
        headers: {
          Authorization:  `Bearer ${APIToken}`,
        },
      });
      customLog('after response in getInitialUserData');
      if (!response.ok) {
        throw new Error('Failed to fetch initial user data');
      }
      
      customLog('before data in getInitialUserData');
      const data = await response.json();
      customLog('Initial user data: ' + JSON.stringify(data));

      const courses = data.info.courses;
      const email = data.info.email;
      customLog('EMAILIS ' + email);
      const firstName = data.info.first_name || null;
      const profilePicture = data.info.picture_url || null;
      const flashcardGroups = data.info.flashcard_groups || []; //TODO: test
      customLog('flashcardGroups received from IUD is ' + JSON.stringify(flashcardGroups));
      const quizzes = data.info.quizzes || [];
      customLog('quizzes received from IUD is ' + JSON.stringify(quizzes));
      const allRAGFiles = data.info.files || [];
      customLog('allRAGFiles is ' + JSON.stringify(allRAGFiles));
      const IDToConversation = {};
      const IDToChatTitle = {};
      let currentOrderedChatIDs = [];
      const courseIDsToRecentChatIDs = {};
      let courseIDToCourseTitle = {};

      let mostRecentConversationID = null;
      let mostRecentCourse = null;
      let mostRecentMessages = [];
      courses.forEach(course => {
        const courseID = course.course_id;
        mostRecentCourse = courseID;
        //Initialize empty objects for courses
        IDToConversation[courseID] = {};
        IDToChatTitle[courseID] = {};
        courseIDToCourseTitle[courseID] = course.course_name;
        customLog('current course name is ' + course.course_name);
        
        //Process 5 most recent conversations per course
        course.top_5_conversations.forEach(conversation => {
          // const messages = conversation.messages.map(message => ({
          //   user: message.author ? "User" : "Assistant",
          //   text: message.message,
          // }));
          const messages = conversation.messages
          // .filter(message => !message.message.startsWith("[{'role': 'system',")) //TODO: This should be server side
          .map(message => ({
            user: message.author ? "User" : "Assistant",
            text: message.message,
          }));
          messages.reverse();
          IDToConversation[courseID][conversation.conversation_id] = messages;
          IDToChatTitle[courseID][conversation.conversation_id] = conversation.conversation_name;
          customLog('conversation name is ' + conversation.conversation_name);

          // Add to courseIDsToRecentChatIDs. Create empty array if doesn't exist
          if (!courseIDsToRecentChatIDs[courseID]) {
            courseIDsToRecentChatIDs[courseID] = [];
          }
          courseIDsToRecentChatIDs[courseID].push(conversation.conversation_id);
          // Update most recent conversation details if it's the first in the list
          if (conversation === course.top_5_conversations[0]) {
            mostRecentConversationID = conversation.conversation_id;
            mostRecentCourse = courseID;
            mostRecentMessages = messages;
          }
        });
      });

      //Update currentOrderedChatIDs
      if (courses.length > 0) {
        // Assuming the first course is the currently selected one
        currentOrderedChatIDs = courseIDsToRecentChatIDs[mostRecentCourse] || [];
      }

      customLog('IDToConversation is ' + JSON.stringify(IDToConversation));
      customLog('IDToChatTitle is ' + JSON.stringify(IDToChatTitle));
      customLog('currentOrderedChatIDs is ' + JSON.stringify(currentOrderedChatIDs));
      customLog('courseIDsToRecentChatIDs is ' + JSON.stringify(courseIDsToRecentChatIDs));
      customLog('currentConversationID is ' + mostRecentConversationID);
      customLog('currentCourse is ' + mostRecentCourse);
      customLog('currentMessages is ' + JSON.stringify(mostRecentMessages));
      customLog('current courses are ' + JSON.stringify(courseIDToCourseTitle));
      return ({
        IDToConversation, 
        IDToChatTitle, 
        currentOrderedChatIDs, 
        courseIDsToRecentChatIDs,
        currentConversationID: mostRecentConversationID,
        currentCourse: mostRecentCourse,
        currentMessages: mostRecentMessages,
        courseIDToCourseTitle,
        APIToken,
        email,
        flashcardGroups,
        quizzes,
        allRAGFiles,
        firstName,
        profilePicture
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for registering a user
export const registerUserAPI = createAsyncThunk(
  'chat/registerUserAPI',
  async ({ user_name, first_name, last_name, email, password, is_student,
            is_individual }, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('registerUserAPI called');
      // console.log("entered registerUserAPI");
      // Call the API endpoint to register the user
      const response = await fetch(DjangoHost+"/api/register/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email,
                              password,
                              is_student,
                              is_individual }),
      });
      customLog('before response.ok');
        
      if (!response.ok) {
        throw new Error('Failed to register the user');
      }
      customLog('after data before response.status in registerUserAPI');
      const courseName = 'All Courses';
      // If response code is 201
      if (response.status === 201) {
        //Login user
        customLog('login called from register thunk');
        await dispatch(loginUserAPI({userName: user_name, password, signup: true}));
        dispatch(createCourseAPI(courseName));
      } else {
        customLog('response status is ' + response.status);
      } 
      dispatch(setResumeScreenOpen(true));
      return ({hi: false});
      /* NO BACKEND USE
      return courseName
      */
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

  // Async thunk for creating a course
  export const updateCourseAPI = createAsyncThunk(
    'chat/updateCourseAPI',
    async ({courseName, courseId}, { dispatch, getState, rejectWithValue }) => {
      try {
        customLog("entered updateCourseAPI with courseID " + courseId + " and course name " + courseName);
        if (courseId == getState().chat.allCoursesID) {
          return;
        }
        
        // Call the API endpoint to create a new course
        const response = await fetch(`${DjangoHost}/api/course/${courseId}/`, {
          method: 'PUT',
          headers: {
             'Content-Type': 'application/json',
            Authorization:  `Bearer ${getState().chat.APIToken}`,
          },
          body: JSON.stringify({ course_name: courseName }),
        });
          
        if (!response.ok) {
          customLog('!response.ok of updateCourseAPI');
          dispatch(setGeneralSnackbarMessage('Failed to edit course name'));
          dispatch(setGeneralSnackbarOpen(true));
          throw new Error('Failed to edit course name');
        }
  
        // const newCourse = await response.json();
        // console.log("response good");
        // Print JSON response to console
        // console.log(newCourse);
        
        // Set the created course as the selected course
        //console.log(...getState().chat.courses);
        // dispatch(setCourses([...getState().chat.courses, courseName]));
        // dispatch(setSelectedCourse(newCourse));
        // const courseID = newCourse.course_id;
        // console.log("finna send with courseID " + courseId + " and course name " + courseName);
        return ({courseName, courseId});
        /* NO BACKEND USE
        return courseName
        */
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

    // Async thunk for creating a course
    export const deleteCourseAPI = createAsyncThunk(
      'chat/deleteCourseAPI',
      async ({courseName, courseId}, { dispatch, getState, rejectWithValue }) => {
        try {
          customLog("entered deleteCourseAPI with courseID " + courseId + " and course name " + courseName);
          // Call the API endpoint to create a new course
          if (courseId == getState().chat.allCoursesID) {
            return;
          }
          const response = await fetch(`${DjangoHost}/api/course/${courseId}/`, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json',
              Authorization:  `Bearer ${getState().chat.APIToken}`,
            },
            body: JSON.stringify({ course_name: courseName }),
          });
            
          if (!response.ok) {
            customLog('!response.ok of deleteCourseAPI');
            dispatch(setGeneralSnackbarMessage('Failed to delete course'));
            dispatch(setGeneralSnackbarOpen(true));
            throw new Error('Failed to delete the course');
          }
  
          return ({courseId});
        } catch (error) {
          return rejectWithValue(error.message);
        }
      }
    );

  // // Async thunk for creating a course
  // export const uploadContextFileAPI = createAsyncThunk(
  //   'chat/uploadContextFileAPI',
  //   async (file, { dispatch, getState, rejectWithValue }) => {
  //     try {
  //       customLog("entered uploadContextFileAPI for conversation_id " + getState().chat.currentConversationID);
        
  //       // Initialize FormData object
  //       const formData = new FormData();
  //       formData.append('file', file);
        
  //       // Call the API endpoint to upload file
  //       const response = await fetch(DjangoHost+"/api/context-file/"+ getState().chat.currentConversationID + '/', {
  //         method: 'POST',
  //         headers: {
  //           // 'Content-Type': 'multipart/form-data',
  //           Authorization:  `Bearer ${getState().chat.APIToken}`,
  //         },
  //         body: formData,
  //         redirect: 'follow'
  //       });
  //       // customLog('response for uploadContextFileAPI' +  response.text());
          
  //       if (!response.ok) {
  //         customLog('UPLOADCONTEXTFILEAPI !RESPONSE.OK');
  //         const errorResponse = await response.json();

  //         const errorMessage = errorResponse.error || 'Unkown error occurred';

  //         customLog('in !response.ok of uploadContextFileAPI:', errorMessage);
  //         dispatch(setGeneralSnackbarMessage('Failed to upload short-term memory file'));
  //         dispatch(setGeneralSnackbarOpen(true));
  //         return rejectWithValue(errorMessage);
  //       }
        
  //       const status = await response.json();
  //       customLog("response good for uploadContextFileAPI");

  //       customLog("url for shortTermContextFile is " + status.url);

  //       const url = status.url;
        
  //       return ({fileURL: url, fileID: url, filePreview: ragPreview, initialPage: 0});
  //     } catch (error) {
  //       customLog('in catch block of uploadContextFileAPI');
  //       return rejectWithValue(error.message);
  //     }
  //   }
  // );

  // Async thunk for uploading context file
export const uploadContextFileAPI = createAsyncThunk(
  'chat/uploadContextFileAPI',
  async (file, { dispatch, getState, rejectWithValue }) => {
    const maxRetries = 3; // Maximum number of attempts
    const retryDelay = 2000; // Delay between attempts in milliseconds
    customLog('UPLOADCONTEXTFILEAPI');
    // Function to delay execution
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const attemptUpload = async (attempt) => {
      try {
        customLog(`Attempt ${attempt} of uploadContextFileAPI for conversation_id ${getState().chat.currentConversationID}`);
        
        // Initialize FormData object
        const formData = new FormData();
        formData.append('file', file);
        
        // Call the API endpoint to upload file
        const response = await fetch(DjangoHost+"/api/context-file/"+ getState().chat.currentConversationID + '/', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${getState().chat.APIToken}`,
          },
          body: formData,
          redirect: 'follow'
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.error || 'Unknown error occurred';
          throw new Error(errorMessage);
        }
        
        const status = await response.json();
        customLog("Response good for uploadContextFileAPI");
        customLog("URL for shortTermContextFile is " + status.url);
        customLog('UPLOADCONTEXTFILEAPI JSON STRINGIFY of status is ' + JSON.stringify(status));
        dispatch(setGeneralSnackbarMessage("Plato's reading your file!"));
        dispatch(setGeneralSnackbarOpen(true));
        return {
          fileURL: status.url, 
          fileID: status.file_id, 
          filePreview: ragPreview, 
          initialPage: 0
        };
      } catch (error) {
        if (attempt < maxRetries) {
          customLog(`Upload attempt ${attempt} failed, retrying in ${retryDelay/1000} seconds...`);
          await wait(retryDelay);
          return attemptUpload(attempt + 1);
        } else {
          throw error;
        }
      }
    };

    try {
      return await attemptUpload(1); // Start attempts with 1
    } catch (error) {
      customLog('All attempts of uploadContextFileAPI failed');
      dispatch(setGeneralSnackbarMessage('Failed to upload short-term memory file'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for uploading resume
export const uploadResumeAPI = createAsyncThunk(
  'chat/uploadResumeAPI',
  async (file, { dispatch, getState, rejectWithValue }) => {
    const maxRetries = 3; // Maximum number of attempts
    const retryDelay = 2000; // Delay between attempts in milliseconds
    customLog('UPLOADRESUMEAPI');
    // Function to delay execution
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const attemptUpload = async (attempt) => {
      try {
        customLog(`Attempt ${attempt} of uploadResumeAPI`);
        
        // Initialize FormData object
        const formData = new FormData();
        formData.append('file', file);
        
        // Call the API endpoint to upload resume
        const response = await fetch(DjangoHost+"/api/add-resume/", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${getState().chat.APIToken}`,
          },
          body: formData,
          redirect: 'follow'
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.error || 'Unknown error occurred';
          throw new Error(errorMessage);
        }
        
        const status = await response.json();
        customLog("Response good for uploadResumeAPI");
        customLog('UPLOADRESUMEAPI response: ' + JSON.stringify(status));
        // dispatch(setGeneralSnackbarMessage("Resume uploaded successfully!"));
        // dispatch(setGeneralSnackbarOpen(true));
        return status;
      } catch (error) {
        if (attempt < maxRetries) {
          customLog(`Upload attempt ${attempt} failed, retrying in ${retryDelay/1000} seconds...`);
          await wait(retryDelay);
          return attemptUpload(attempt + 1);
        } else {
          throw error;
        }
      }
    };

    try {
      return await attemptUpload(1); // Start attempts with 1
    } catch (error) {
      customLog('All attempts of uploadResumeAPI failed');
      dispatch(setGeneralSnackbarMessage('Failed to upload resume'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for getting explores
export const getExploresAPI = createAsyncThunk(
  'chat/getExploresAPI', 
  async (lastAPIExploreIndexCalled, { dispatch, getState, rejectWithValue }) => {
    customLog('GETEXPLORESAPI');

    try {
      // Construct URL with optional query parameter
      let url = DjangoHost + "/api/lessons/explore/";
      if (lastAPIExploreIndexCalled) {
        url += `?latest_added_id=${lastAPIExploreIndexCalled}`;
      }
      
      // Call the API endpoint to get explores
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getState().chat.APIToken}`,
        },
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        const errorMessage = errorResponse.error || 'Unknown error occurred';
        throw new Error(errorMessage);
      }
      
      const status = await response.json();
      customLog("Response good for getExploresAPI");
      customLog('GETEXPLORESAPI response: ' + JSON.stringify(status));
      return status.explores;

    } catch (error) {
      customLog('getExploresAPI failed');
      dispatch(setGeneralSnackbarMessage('Failed to get explores'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  }
);

// Example response from /api/topic/{id}/:
/*
"topic": {
    "complete": boolean,
    "content": [
        {
            "type": "text",
            "messages": [
                {
                    "user": "Assistant",
                    "text": "Welcome to the topic of Existential Nihilism! This philosophy explores the notion that life lacks intrinsic meaning."
                },
                {
                    "user": "User", 
                    "text": "What does intrinsic meaning mean in this context?"
                },
                {
                    "user": "Assistant",
                    "text": "Intrinsic meaning is a value or purpose that is inherent, not assigned by external sources. Existential Nihilism suggests that such meaning does not exist."
                }
            ]
        },
        {
            "type": "interactiveDemo",
            "content": {
                "title": "Understanding Intrinsic vs. Assigned Meaning",
                "description": "Drag and drop examples of 'intrinsic' and 'assigned' meanings into the correct categories to see how they differ.",
                "code": "<interactive-demo-code>"
            }
        },
        {
            "type": "text", 
            "messages": [
                {
                    "user": "Assistant",
                    "text": "Next, let's discuss some key figures in Existential Nihilism, like Friedrich Nietzsche and Jean-Paul Sartre."
                }
            ]
        },
        {
            "type": "quiz",
            "content": quizStructureOmitted
        }
    ],
}
*/

        // // Mock data instead of API call
        // const mockResponse = {
        //   topic: {
        //     complete: false,
        //     content: [
        //       {
        //         type: "text",
        //         messages: [
        //           {
        //             user: "Assistant",
        //             text: "Welcome to the topic of Existential Nihilism! This philosophy explores the notion that life lacks intrinsic meaning."
        //           },
        //           {
        //             user: "User", 
        //             text: "What does intrinsic meaning mean in this context?"
        //           },
        //           {
        //             user: "Assistant",
        //             text: "Intrinsic meaning is a value or purpose that is inherent, not assigned by external sources. Existential Nihilism suggests that such meaning does not exist."
        //           }
        //         ]
        //       },
        //       // {
        //       //   type: "interactiveDemo",
        //       //   content: {
        //       //     title: "Understanding Intrinsic vs. Assigned Meaning",
        //       //     description: "Drag and drop examples of 'intrinsic' and 'assigned' meanings into the correct categories to see how they differ.",
        //       //     code: "<interactive-demo-code>"
        //       //   }
        //       // },
        //       {
        //         type: "text", 
        //         messages: [
        //           {
        //             user: "Assistant",
        //             text: "Next, let's discuss some key figures in Existential Nihilism, like Friedrich Nietzsche and Jean-Paul Sartre."
        //           }
        //         ]
        //       },
              // {
              //   type: "quiz",
              //   content: {
              //     id: 1,  // unique quiz identifier
              //     quiz_questions: [
              //         {
              //             id: 101,  // unique identifier for the question
              //             question: "What is the capital of France?",  // the question text
              //             choices: [
              //                 { id: 1, body: "Paris", is_correct: true },
              //                 { id: 2, body: "Berlin", is_correct: false },
              //                 { id: 3, body: "Madrid", is_correct: false },
              //                 { id: 4, body: "Rome", is_correct: false }
              //             ]
              //         }
              //     ]
              // }
              // },
        //       {
        //         type: "completed",
        //       }
        //     ]
        //   }
        // };
// Async thunk for getting topic content
export const getTopicContent = createAsyncThunk(
  'chat/getTopicContent',
  async ({topicId, topicIndex, currentDisplayedTopicIndex}, { dispatch, getState, rejectWithValue }) => {
    const maxRetries = 10; // Maximum number of attempts
    const retryDelay = 2000; // Delay between attempts in milliseconds
    customLog('GETTOPICCONTENT');
    
    // Function to delay execution
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const attemptGet = async (attempt) => {
      try {
        customLog(`Attempt ${attempt} of getTopicContent`);
        
        // Call the API endpoint to get topic content
        const response = await fetch(DjangoHost+`/api/lessons/get/topic/${topicId}/`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getState().chat.APIToken}`,
          },
          redirect: 'follow'
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.error || 'Unknown error occurred';
          throw new Error(errorMessage);
        }
        
        const status = await response.json();
        customLog("Response good for getTopicContent");
        customLog('GETTOPICCONTENT response: ' + JSON.stringify(status));

        // Retry if conversation is empty or if topic matches current topic
        if (!status.topic.conversation || status.topic.conversation.length === 0 || 
            JSON.stringify(status.topic) === JSON.stringify(getState().chat.currentTopicObject)) {
          if (attempt < maxRetries) {
            customLog(`Topic conversation empty or unchanged, retrying in ${retryDelay/1000} seconds...`);
            await wait(retryDelay);
            return attemptGet(attempt + 1);
          }
        }

        return {
          topicID: topicId,
          status: status.topic,
          topicIndex: topicIndex,
          currentDisplayedTopicIndex: currentDisplayedTopicIndex
        };
      } catch (error) {
        if (attempt < maxRetries) {
          customLog(`Get attempt ${attempt} failed, retrying in ${retryDelay/1000} seconds...`);
          await wait(retryDelay);
          return attemptGet(attempt + 1);
        } else {
          throw error;
        }
      }
    };

    try {
      return await attemptGet(1); // Start attempts with 1
    } catch (error) {
      customLog('All attempts of getTopicContent failed');
      dispatch(setGeneralSnackbarMessage('Failed to get topic content'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for alerting topic done
export const alertTopicDone = createAsyncThunk(
  'chat/alertTopicDone',
  async ({topicId, stars}, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('ALERTTOPICDONE called');
      
      const response = await fetch(DjangoHost+'/api/lessons/topic/done/', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${getState().chat.APIToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          topic_id: topicId,
          stars: stars
        })
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        const errorMessage = errorResponse.error || 'Unknown error occurred';
        throw new Error(errorMessage);
      }

      customLog("Response good for alertTopicDone");
      return {
        topicId,
        stars
      };

    } catch (error) {
      customLog('alertTopicDone failed');
      dispatch(setGeneralSnackbarMessage('Failed to mark topic as done'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for getting file URL from UUID
export const getFileURLFromUUID = createAsyncThunk(
  'chat/getFileURLFromUUID',
  async ({fileUUID}, { dispatch, getState, rejectWithValue }) => {
    const maxRetries = 3;
    const retryDelay = 2000;
    customLog('GETFILEURLFROMUUID called with fileUUID of ' + fileUUID);
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const attemptGetURL = async (attempt) => {
      try {
        customLog(`Attempt ${attempt} of getFileURLFromUUID for file UUID ${fileUUID}`);
        
        const response = await fetch(DjangoHost + "/api/get/file/" + fileUUID + '/', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getState().chat.APIToken}`,
          },
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.error || 'Unknown error occurred';
          throw new Error(errorMessage);
        }
        
        const data = await response.json();
        customLog("Response good for getFileURLFromUUID");
        customLog("URL for file is " + data.url);
        
        dispatch(setGeneralSnackbarMessage("File URL retrieved successfully!"));
        dispatch(setGeneralSnackbarOpen(true));
        
        return {
          fileURL: data.url,
          fileID: fileUUID
        };
      } catch (error) {
        if (attempt < maxRetries) {
          customLog(`Get URL attempt ${attempt} failed, retrying in ${retryDelay/1000} seconds...`);
          await wait(retryDelay);
          return attemptGetURL(attempt + 1);
        } else {
          throw error;
        }
      }
    };

    try {
      return await attemptGetURL(1);
    } catch (error) {
      customLog('All attempts of getFileURLFromUUID failed');
      dispatch(setGeneralSnackbarMessage('Failed to retrieve file URL'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  }
);

    // Async thunk for creating a course
    export const uploadLongtermFileAPI = createAsyncThunk(
      'chat/uploadLongtermFileAPI',
      async (file, { dispatch, getState, rejectWithValue }) => {
        try {
          const selectedCourseID = getState().chat.selectedCourse;
          const APIToken = getState().chat.APIToken;
          const allCoursesID = getState().chat.allCoursesID;
          customLog("entered uploadLongtermFileAPI for course_id " + selectedCourseID);
          
          const uploadFile = async (courseID) => {
            // Initialize FormData object
            const formData = new FormData();
            formData.append('file', file);
            formData.append('course_id', courseID);
            
            // Call the API endpoint to upload file
            const response = await fetch(DjangoHost+"/api/upload-class-file/", {
              method: 'POST',
              headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization:  `Bearer ${APIToken}`,
              },
              body: formData,
            });
              
            if (!response.ok) {
              dispatch(setGeneralSnackbarMessage('Failed to upload long-term memory file'));
              dispatch(setGeneralSnackbarOpen(true));
              throw new Error('Failed to upload file');
            }
            return await response.json();

          }

    
          const response = await uploadFile(selectedCourseID);
          customLog(`File uploaded to course ${selectedCourseID}`);

          // if (selectedCourseID !== allCoursesID) {
          //   await uploadFile(allCoursesID);
          //   customLog(`File also uploaded to allCoursesID ${allCoursesID}`);
          // }
          
          return;
        } catch (error) {
          return rejectWithValue(error.message);
        }
      }
    );

  // Async thunk for searching longterm files
  export const searchLongtermFilesAPI = createAsyncThunk(
    'chat/searchLongtermFiles',
    async (input, { dispatch, getState, rejectWithValue }) => {
      try {
        customLog("entered searchLongtermFilesAPI for course_id " + getState().chat.selectedCourse + " and input " + input);
        
        // Call the API endpoint to search files
        const response = await fetch(DjangoHost+`/api/search-class-files/?course_id=${getState().chat.selectedCourse}&search_string=${input}`, {
          method: 'GET',
          headers: {
            Authorization:  `Bearer ${getState().chat.APIToken}`,
          }
        });
          
        if (!response.ok) {
          throw new Error('Failed to search file');
        }

        const data = await response.json();
        customLog('data is ' + JSON.stringify(data));
        if (data.matches.length > 0) {
          customLog('matches is ' + JSON.stringify(data.matches));
          const match = data.matches[0];
          const fileID = match.parentFileID;
          const initialPage = match.pageNum + 1;
          const ragFileURL = match.parent_file_path;
          const ragFilePreview = match.parent_file_thumbnail_path;
          const fileName = match.name;
          const similarityScore = match.score;

          customLog('file url is ' + ragFileURL);
          customLog('initialPage is ' + initialPage);
          customLog('similarity score is ' + similarityScore);
          
          return ({found: true, fileID, ragFileURL, ragFilePreview, initialPage, fileName, similarityScore});
        }
        customLog('no matches found in longterm files');
        return ({found: false});
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  // Async thunk for deleting a course
export const deleteCourse = createAsyncThunk(
    'chat/deleteCourse',
    async (courseId, { dispatch, rejectWithValue }) => {
      try {
        // Call the API endpoint to delete the course
        const response = await fetch('API_ENDPOINT_FOR_DELETING_COURSE', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ courseId }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to delete the course');
        }
  
        // Set the selected course to null
        dispatch(setSelectedCourse(null));
        
        return courseId;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const loadMostRecentChat = createAsyncThunk(
    'chat/loadMostRecentChat',
    async (courseId, {getState, dispatch }) => {
      return courseId;
    }
  )

// Async thunk for editing chat title
export const editChatTitleAPI = createAsyncThunk(
  'chat/editChatTitleAPI',
  async ({conversationID, newTitle}, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog("entered editChatTitleAPI with conversationID " + conversationID + " and newTitle " + newTitle);
      
      // Call the API endpoint to edit a chat title
      const response = await fetch(DjangoHost+`/api/conversaion/${conversationID}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization:  `Bearer ${getState().chat.APIToken}`,
        },
        body: JSON.stringify({ conversation_name: newTitle }),
      });
        
      if (!response.ok) {
        customLog('!response.ok of editChatTitleAPI');
        dispatch(setGeneralSnackbarMessage('Failed to edit chat title'));
        dispatch(setGeneralSnackbarOpen(true));
        throw new Error('Failed to edit chat title');
      }

      if (response.status === 200) {
        return ({conversationID, newTitle});
      } else {
        customLog('response.status !== 200 of editChatTitleAPI');
        dispatch(setGeneralSnackbarMessage('Failed to edit chat title'));
        dispatch(setGeneralSnackbarOpen(true));
        throw new Error('Failed to edit the chat title with response status ' + response.status + ' and response text ' + response.text());
      }
      
    } catch (error) {
      customLog('catch (error) of editChatTitleAPI');
      dispatch(setGeneralSnackbarMessage('Failed to edit chat title'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  }
);

    // Async thunk for removing a chat
    export const removeChatAPI = createAsyncThunk(
      'chat/removeChatAPI',
      async ({conversationID}, { dispatch, getState, rejectWithValue }) => {
        try {
          customLog("entered removeChatAPI with conversationID " + conversationID);
          // Call the API endpoint to remove a chat
          const response = await fetch(DjangoHost+`/api/conversation/${conversationID}/`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization:  `Bearer ${getState().chat.APIToken}`,
            },
          });
            
          if (!response.ok) {
            customLog('!response.ok of removeChatAPI');
            dispatch(setGeneralSnackbarMessage('Failed to remove chat'));
            dispatch(setGeneralSnackbarOpen(true));
            throw new Error('Failed to create the course');
          }
    
          if (response.status === 200) {
            return ({conversationID});
          } else {
            throw new Error('Failed to remove chat with response status ' + response.status + ' and response text ' + response.text());
          }
        } catch (error) {
          return rejectWithValue(error.message);
        }
      }
    );

// Configure the Redux store
export const store = configureStore({
  reducer: {
    chat: chatSlice.reducer,
  },
  //middleware: [thunk],
});

export const {
  setDrawerOpen,
  setIDToConversation: setSavedChats,
  setChatTitle,
  setMessages,
  setInput,
  setLoading,
  setCoursePopover,
  setUserPopover,
  handleLoadChatRedux,
  setSelectedCourse,
  setCourses,
  editCourseName,
  removeCourse,
  //setEditingChatTitle,
  setEditedChatTitleRedux,
  setConversationID,
  removeChat,
  setSelectedResource,
  handleNewChat,
  setSelectedCourseTitle,
  setSystemPrompt,
  setSettingsOpen,
  setSnackbarRecordingOpen,
  setSnackbarShareChatOpen,
  setSnackbarRecordingMessage,
  addStreamedMessage,
  addMessage,
  setMostRecentUserPrompt,
  setResources,
  setGeneralSnackbarOpen,
  setGeneralSnackbarMessage,
  removeResources,
  setIsShortTermMemory,
  setIsLongTermMemory,
  addNewChat,
  removeSelectedResource,
  setFlashcardsScreenOpen,
  setCurrentFlashcardPDF,
  sendMessageToAPI,
  setQuizMeScreenOpen,
  setTheme,
  setIframeDragged,
  setAbortion,
  setIsLoggedIn,
  setLoginSignupScreenOpen,
  setFlashcardPrompt,
  setQuizPrompt,
  setFlashcardQuizObject,
  changeFlashcardQuizIndex,
  setFlashcardQuizIndex,
  clearChatHistory,
  addPresetPrompt,
  signOut,
  setFlashcards,
  setQuiz,
  addFlashcard,
  addQuizQuestion,
  appendFlashcard,
  setCurrentFlashcardGroupID,
  appendQuizQuestion,
  setCurrentQuizID,
  setAllFlashcardGroups,
  setAllQuizGroups,
  prependFlashcardGroup,
  prependQuizGroup,
  setCurrentFlashcardGroup,
  setCurrentQuizGroup,
  setCurrentFlashcardIndex,
  setCurrentQuizIndex,
  incrementCurrentFlashcardIndex,
  incrementCurrentQuizIndex,
  setSelectedResourceType,
  setStreamingCards,
  appendFlashcardToGroup,
  appendQuizQuestionToGroup,
  changeCurrentFlashcardIndex,
  changeCurrentQuizIndex,
  insertFlashcard,
  insertQuizQuestion,
  setFakeLoginSignupScreenOpen,
  editFlashcardGroupName,
  displayInTextFile,
  setMemoryScreenOpen,
  setAPIToken,
  setFirstName,
  setProfilePicture,
  setContextFileLoading,
  setLessonsScreenOpen,
  setAllLessonGroups,
  setCurrentLessonGroup,
  setCurrentLessonID,
  setCurrentLessonIndex,
  setCurrentTopicIndex,
  incrementCurrentTopicScore,
  setCurrentTopicScore,
  setGuidedLessonModeActive,
  setCurrentLessonName,
  handleExitLesson,
  setSelectedCenterResourceType,
  setCenterPanelLoading,
  incrementCurrentNumberSubtopics,
  setOnHomeScreen,
  setCenterPanelVisible,
  setIsStreaming,
  setTimeLimit,
  setLevel,
  removeCurrentLessonGroup,
  setResumeScreenOpen,
  setCurrentDisplayedTopicContent,
  setLessonPlanStreaming,
  changeCurrentDisplayedTopicIndex,
  setLibraryPageActive,
  setPersonalizationOpen,
  setPrereqTestOptionScreenOpen,
  setPrereqTestModeActive,
  appendCurrentTopicObject,
  appendPrereqTestQuestion,
  clearPrereqTestQuestions,
  incrementNumberCorrectQuestions,
  incrementNumberTotalQuestions,
  setNumberCorrectQuestions,
  setNumberTotalQuestions,
  setTopicIndexOfLesson,
  updateStarRating,
  setScrollLocked,
  clearPreviousTopic,
  changeCurrentDisplayedExploreIndex
} = chatSlice.actions;

export default store;