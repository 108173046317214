import customLog from './customLogger';
import { 
    setCurrentLessonIndex,
    setCurrentTopicIndex, 
    setCurrentLessonID,
    setOnHomeScreen,
    setCenterPanelVisible,
    setCurrentLessonGroup,
    setGuidedLessonModeActive,
    setCurrentTopicScore,
    setStreamingCards,
    setCenterPanelLoading,
    setIsStreaming,
    setTimeLimit,
    setLevel,
    setGeneralSnackbarMessage,
    setGeneralSnackbarOpen,
    setLoginSignupScreenOpen,
    addMessage,
    removeResources,
    setConversationID,
    addStreamedMessage,
    getChatTitleAPI,
    setSelectedCenterResourceType,
    setSelectedResourceType,
    setCurrentQuizIndex,
    setCurrentQuizGroup,
    setCurrentFlashcardIndex,
    setCurrentFlashcardGroup,
    setMostRecentUserPrompt,
    appendQuizQuestionToGroup,
    getAppendFlashcardToGroup,
    editFlashcardGroupName,
    setCurrentQuizID,
    setCurrentFlashcardGroupID,
    setSelectedResource,
    approveLessonAPI,
    setCurrentDisplayedTopicContent,
    setLessonPlanStreaming,
    appendCurrentTopicObject,
    prependQuizGroup,
    changeCurrentDisplayedTopicIndex,
    getTopicContent,
    setNumberCorrectQuestions,
    setNumberTotalQuestions,
    setPrereqTestModeActive,
    updateStarRating,
    alertTopicDone,
    incrementCurrentNumberSubtopics,
    clearPreviousTopic,
    setScrollLocked
} from './store.js';

export const retrieveLesson = async ({lessonGroup, dispatch}) => {
    customLog('RETRIEVELESSON CALLED for lessonGroup ' + JSON.stringify(lessonGroup));
    dispatch(setCurrentLessonIndex(0));
    dispatch(setCurrentTopicIndex(0));
    dispatch(setCurrentLessonID(lessonGroup.id));
    dispatch(setOnHomeScreen(false));
    dispatch(setCenterPanelVisible(true));
    
    dispatch(setCurrentLessonGroup(lessonGroup));
    dispatch(setGuidedLessonModeActive(true));
    dispatch(setCurrentTopicScore(0));
};

export const handleLesson = async ({
    message, 
    file, 
    editedLessonId, 
    editedContent,
    exploreID,
    prereqResults,
    props: {   
        APIToken,
        timeLimit,
        level,
        setReactChatMessages,
        setStreamedResponse,
        DjangoHost
    },
    dispatch
}) => {
    dispatch(setStreamingCards(true));
    dispatch(setLessonPlanStreaming(true));
    let lessonId = editedLessonId;
    dispatch(setCenterPanelLoading(true));
    dispatch(setScrollLocked(true));
    dispatch(setCurrentDisplayedTopicContent(
        {
            "type": "text",
            "messages": []
        }
    ))
    
    if (!editedContent) {
        dispatch(setCurrentLessonIndex(0));
        dispatch(setCurrentLessonGroup(null));
        dispatch(setOnHomeScreen(false));
        dispatch(setCenterPanelVisible(true));
    }
    
    dispatch(setIsStreaming(true));
    setReactChatMessages([]);
    customLog('PREREQ RSULTS IS ' + JSON.stringify(prereqResults));

    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${APIToken}`);

        let raw;
        if (editedContent) {
            myHeaders.append("Content-Type", "application/json");
            raw = JSON.stringify({
                lesson_id: editedLessonId,
                user_feedback: editedContent
            });
        } else if (message) {
            myHeaders.append("Content-Type", "application/json");
            raw = JSON.stringify({
                "prompt": message,
                "time_limit": timeLimit,
                "level": level,
            });
        } else if (exploreID) {
            myHeaders.append("Content-Type", "application/json");
            raw = JSON.stringify({
                "explore_id": exploreID,
                "time_limit": timeLimit,
                "level": level,
            });
        } else if (prereqResults) {
            myHeaders.append("Content-Type", "application/json");
            raw = JSON.stringify(prereqResults);
        } else {
            raw = new FormData();
            raw.append('file', file);
        }

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        let accumulatedData = "";
        const endpoint = editedContent ? "/api/lessons/update/" : "/api/lessons/create/";
        
        fetch(DjangoHost + endpoint, requestOptions)
            .then(response => {
                const reader = response.body.getReader();
                return new ReadableStream({
                    async start(controller) {
                        while (true) {
                            const { done, value } = await reader.read();
                            if (done) {
                                //dispatch(setIsStreaming(false));
                                //dispatch(setStreamingCards(false));
                                //setStreamedResponse('');
                                dispatch(setTimeLimit('15 minutes'));
                                dispatch(setLevel(4));
                                break;
                            }
                            const decodedChunk = new TextDecoder().decode(value);
                            dispatch(setCenterPanelLoading(false));
                            
                            try {
                                const parsedData = JSON.parse(decodedChunk);
                                if (parsedData.lesson_id) {
                                    dispatch(setCurrentLessonID(parsedData.lesson_id));
                                    lessonId = parsedData.lesson_id;
                                } else {
                                    accumulatedData += decodedChunk;
                                    setStreamedResponse((prev) => prev + decodedChunk);
                                    controller.enqueue(decodedChunk);
                                }
                            } catch (error) {
                                accumulatedData += decodedChunk;
                                setStreamedResponse((prev) => prev + decodedChunk);
                                controller.enqueue(decodedChunk);
                            }
                        }
                        controller.close();
                        reader.releaseLock();
                        customLog('HANDLELESSON END for accumulatedData of ' + accumulatedData);
                        const buttonsHtml = `\n\n<ApproveButton lessonId="${lessonId}" />\n<EditButton lessonId="${lessonId}" />`;
                        setStreamedResponse((prev) => prev + buttonsHtml);
                        const newMessage = { user: "Assistant", text: accumulatedData + buttonsHtml };
                        setReactChatMessages((prevReactChatMessages) => [...prevReactChatMessages, newMessage]);
                    }
                });
            })
            .catch(error => {
                console.error(`Error in ${editedContent ? 'editLesson' : 'createLesson'}:`, error);
                dispatch(setGeneralSnackbarMessage(`Failed to ${editedContent ? 'update' : 'create'} lesson`));
                dispatch(setGeneralSnackbarOpen(true));
            })
            .finally(() => {
                //setStreamedResponse('');
                dispatch(setStreamingCards(false));
            });

    } catch (error) {
        console.error(`Error in ${editedContent ? 'editLesson' : 'createLesson'}:`, error);
        dispatch(setGeneralSnackbarMessage(`Failed to ${editedContent ? 'update' : 'create'} lesson`));
        dispatch(setGeneralSnackbarOpen(true));
        setStreamedResponse('');
        dispatch(setStreamingCards(false));
    }
};

export const createPrereqQuiz = async ({
    APIToken,
    DjangoHost,
    level,
    prompt,
    file,
    fileId,
    dispatch,
    //setStreamingCards
}) => {
    try {
        dispatch(setOnHomeScreen(false));
        dispatch(setCenterPanelVisible(true));
        dispatch(setGuidedLessonModeActive(true));

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${APIToken}`);
        customLog('CREATEPREREQQUIZ prompt is ' + prompt);
        customLog('CREATEPREREQQUIZ APIToken is ' + APIToken);
        let raw;
        if (prompt) {
            customLog('CREATEPREREQQUIZ prompt is ' + prompt);
            myHeaders.append("Content-Type", "application/json");
            raw = JSON.stringify({
                "level": level,
                "prompt": prompt
            });
        } else if (file) {
            raw = new FormData();
            raw.append('file', file);
            raw.append('level', level);
        } else if (fileId) {
            myHeaders.append("Content-Type", "application/json");
            raw = JSON.stringify({
                "level": level,
                "file_id": fileId
            });
        }

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        let quizGroup = {
            name: '',
            id: '',
            quiz_questions: [],
        };

        fetch(DjangoHost + "/api/lessons/prerequisite/", requestOptions)
            .then(response => {
                const reader = response.body.getReader();
                return new ReadableStream({
                    async start(controller) {
                        while (true) {
                            const { done, value } = await reader.read();
                            if (done) {
                                break;
                            }
                            const decodedChunk = new TextDecoder().decode(value);
                            customLog('CREATEPREREQQUIZ decodedChunk is ' + decodedChunk);
                            try {
                                const parsedData = JSON.parse(decodedChunk);
                                customLog('CREATEPREREQQUIZ parsedData is ' + JSON.stringify(parsedData));
                                if (parsedData.question && parsedData.choices) {
                                    dispatch(appendCurrentTopicObject({
                                        type: "quiz",
                                        content: parsedData
                                    }));
                                } else if (parsedData.type){
                                    // quizGroup.id = parsedData.id;
                                    // quizGroup.name = parsedData.name;
                                    dispatch(appendCurrentTopicObject(parsedData));
                                }
                            } catch (error) {
                                // Skip incomplete JSON chunks
                                continue;
                            }
                        }
                        controller.close();
                        reader.releaseLock();
                    }
                });
            })
            .catch(error => {
                console.error("Error in createPrereqQuiz:", error);
            });

    } catch (error) {
        console.error("Error in createPrereqQuiz:", error);
    }
};

export const handleSend = async ({
    event, 
    dispatch,
    createLesson,
    sendOrCreateChatMessageStreamingWrapper,
    inputMessage,
    props: {
        isLoggedIn,
        // responseRef,
        setStreamedResponse,
        guidedLessonModeActive,
        currentLessonID,
        selectedCourseId,
        setReactChatMessages
    }
}) => {
    if (event) event.preventDefault();
    if (!isLoggedIn) {
        dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen:true, loginSignupTab: 1}));
        return;
    }

    customLog('HANDLESEND inputMessage is ' + inputMessage);
    const message = inputMessage; 

    const newMessage = { user: "User", text: message };

    
    customLog('NEWMESSAGE HANDLESEND IS ' + newMessage.text);
    customLog('NEWMESSAGE HANDLESEND NEWMESSAGE IS ' + JSON.stringify(newMessage));


    // responseRef.current = '';
    setStreamedResponse('');

    try {
        customLog('HANDLESEND guidedLessonModeActive is ' + guidedLessonModeActive);
        if (guidedLessonModeActive) {
            dispatch(addMessage(newMessage));
            setReactChatMessages((prevReactChatMessages) => {
                const updatedMessages = [...prevReactChatMessages, newMessage];
                customLog('NEWMESSAGE HANDLESEND AFTER reactChatMessages is ' + JSON.stringify(updatedMessages));
                return updatedMessages;
            });
            customLog('HANDLESEND called within guidedLessonModeActive with message ' + message);
            sendOrCreateChatMessageStreamingWrapper({ lessonID: currentLessonID, selectedCourse: selectedCourseId, message: message, guidedLessonModeActive: true });
        } else {
            customLog('HANDLESEND called within else with message ' + message);
            createLesson({message: message});
        }
    } catch (e) {
        console.error("Error sending message: ", e);
    }

};

//let APIToken = useSelector((state) => state.chat.APIToken)
export const sendOrCreateChatMessageStreaming = async ({            selectedCourse,
    message, 
    presetPrompt,
    currentID,
    lessonID,
    guidedLessonModeActive,
    props: {
        APIToken,
        systemPrompt,
        selectedCourseId,
        currentConversationID,
        conversationJustCreatedRef,
        cancelStream,
        setCancelStream,
        // responseRef,
        reactChatMessages,
        setReactChatMessages,
        setStreamedResponse,
        selectedResourceType,
        currentLessonGroup,
        currentTopicIndex,
        DjangoHost,
        abortControllerRef
    },
    dispatch}) => {
    dispatch(setIsStreaming(true));
    dispatch(removeResources()); //TODO: Think if this is best user design
    // if (!presetPrompt) {
    if (!presetPrompt) {
        dispatch(setMostRecentUserPrompt(message)); //PREV was input
        //dispatch(searchLongtermFilesAPI(message));
    }

    abortControllerRef.current = new AbortController();
    let signal = abortControllerRef.current.signal;

    let conversation_id = currentConversationID;

    if (currentConversationID === null && !guidedLessonModeActive) {
        customLog('SENDORCREATECHATMESSAGESTREAMING creating conversation');
        try {
            const conversationResponse = await fetch(DjangoHost + "/api/conversation/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${APIToken}`
                },
                body: JSON.stringify({ course_id: selectedCourseId })
            });
            const data = await conversationResponse.json();
            if (conversationResponse.ok) {
                conversation_id = data.conversation_id;
                dispatch(setConversationID(conversation_id));
                conversationJustCreatedRef.current = conversation_id;
            } else {
                customLog('else of sendOrCreateChatMessageStreaming');
                dispatch(setGeneralSnackbarMessage('Failed to create conversation'));
                dispatch(setGeneralSnackbarOpen(true));
                return;
            }
        } catch (error) {
            customLog("catch (error) in sendOrCreateChatMessageStreaming creating convo", error);
            dispatch(setGeneralSnackbarMessage('Failed to create conversation'));
            dispatch(setGeneralSnackbarOpen(true));
            return;
        }
    } else {
        conversationJustCreatedRef.current = false;
    }

    try {
        customLog('SYSTEMPROMPT is ' + systemPrompt);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${APIToken}`);
        var raw;
        if (presetPrompt === 'explain' || presetPrompt === 'hint') { //TODO: CHANGE THIS TO INSTEAD HAVE MORE CASES FOR WHAT PRESETPROMPT IS
            customLog('SENDORCREATE presetPrompt mode true ');
            raw = JSON.stringify({
                "id": currentID,
                "type": selectedResourceType === 'flashcards' ? 1 : 2,
                "conversation_id": conversation_id,
            });
        } else if (presetPrompt === 'question' || presetPrompt === 'summary') {
            raw = JSON.stringify({
                "file_id": currentID,
                "conversation_id": conversation_id,
            })
        } else if (guidedLessonModeActive && !message) {
            customLog('SENDORCREATE guidedLessonModeActive mode true with lessonID ' + lessonID);
            raw = JSON.stringify({
                "lesson_id": lessonID,
            });
        } else if (guidedLessonModeActive && message) {
            customLog('SENDORCREATE CURRENTLESSONGROUP IS ' + JSON.stringify(currentLessonGroup));
            customLog('SENDORCREATE guidedLessonModeActive mode true with topicID ' + currentLessonGroup.topics[currentTopicIndex].id);
            raw = JSON.stringify({
                "topic_id": currentLessonGroup.topics[currentTopicIndex].id,
                "message": message,
            });
        } else {
            customLog('SENDORCREATE presetPrompt mode false ');
            raw = JSON.stringify({
                "conversation_id": conversation_id,
                "message": message,
                "system_prompt": systemPrompt
            });
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        let accumulatedData = "";

        let endpoint;
        let prefix = '';
        if (presetPrompt === 'hint') {
            endpoint = '/api/message/hint/';
        } else if (presetPrompt === 'explain') {
            endpoint = '/api/message/explain/';
        } else if (presetPrompt === 'question') {
            endpoint = '/api/file/question/';
        } else if (presetPrompt === 'summary') {
            endpoint = '/api/file/summarize/';
        } else if (guidedLessonModeActive && !message) {
            customLog('SENDORCREATE CONTINUE guidedLessonModeActive mode true with lessonID for ENDPOINT SET' + lessonID);
            endpoint = '/api/lessons/continue/';
        } else if (guidedLessonModeActive && message) {
            customLog('SENDORCREATE QUESTIONguidedLessonModeActive mode true with lessonID for ENDPOINT SET' + lessonID);
            endpoint = '/api/lessons/question/';
        } else {
            customLog('SENDORCREATE /api/message/');
            endpoint = '/api/message/';
        }
        fetch(DjangoHost + endpoint, 
            {
                ...requestOptions,
                signal
            }
        )
            .then(response => {
                const reader = response.body.getReader();
                return new ReadableStream({
                    async start(controller) {
                        let isPrefixAdded = false;
                        let quizGroup = {
                            name: '',
                            id: '',   
                            quiz_questions: [],
                        };
                        let flashcardGroup = {
                            name: '',
                            id: '',
                            flashcards: [],
                        };
                        let isQuizMode = false;
                        let isFlashcardMode = false;
                        while (true) {
                            let { done, value } = await reader.read();
                            // customLog('value is ' + value);
                            // customLog('DONE TOKEN IS ' + done);
                            if (done) {
                                // customLog('DONE TOKEN IS ' + done);
                                // customLog('DONE streamedResponse is ' + accumulatedData);
                                if (guidedLessonModeActive) { //TODO: Need to test this once he has endpoint ready
                                    accumulatedData += `\n\n<ContinueButton lessonId="${lessonID}" />`;
                                    setStreamedResponse((r) => r + accumulatedData);
                                    // responseRef.current = responseRef.current + accumulatedData;
                                }
                                //customLog('after guidedLessonModeActive');
                                dispatch(addStreamedMessage({ guidedLessonModeActive: guidedLessonModeActive, streamedResponse: accumulatedData }));
                                customLog('after dispatch(addStreamedMessage)');
                                dispatch(setIsStreaming(false));
                                if (reactChatMessages.length === 0 && !guidedLessonModeActive) {
                                    customLog('CONVERSATIONJUSTCREATEDREF.CURRENT calling getChatTitleAPI');
                                    dispatch(getChatTitleAPI({ conversation_id }));
                                }
                                //customLog('after reactChatMessages.length === 0');
                                const newMessage = { user: "Assistant", text: accumulatedData };
                                setStreamedResponse('');
                                //customLog('setStreamedResponse to empty string');
                                setReactChatMessages((prevReactChatMessages) => {
                                    return [...prevReactChatMessages, newMessage];
                                });
                                //customLog('RIGHT BEFORE BREAK IN WHILE LOOP FOR DONE');
                                break;
                            }
                            if (cancelStream) {
                                customLog("CANCELSTREAM is true");
                                reader.cancel();
                                break;
                                setCancelStream(false);
                            }

                            value = new TextDecoder().decode(value);
                            //customLog('sendOrCreateChatMessageStreaming value is ' + value);
                            
                            if (value.includes('<quiz>')) {
                                isQuizMode = true;
                                value = value.replace('<quiz>', '');
                                // dispatch(setGeneralSnackbarMessage("Plato's making your quiz!"));
                                // dispatch(setGeneralSnackbarOpen(true));
                                dispatch(setCenterPanelLoading(true));
                                dispatch(setStreamingCards(true));

                                if (guidedLessonModeActive) {
                                    dispatch(setCenterPanelVisible(true)); 
                                    dispatch(setSelectedCenterResourceType('quiz'));   
                                } else {
                                    dispatch(setSelectedResourceType('quiz'));
                                }
                    
                                dispatch(setCurrentQuizIndex(0));
                                dispatch(setCurrentQuizGroup(null));
                                
                            }
                            
                            if (value.includes('<flashcards>')) {
                                isFlashcardMode = true;
                                value = value.replace('<flashcards>', '');
                                dispatch(setGeneralSnackbarMessage("Plato's making your flashcards!"));
                                dispatch(setGeneralSnackbarOpen(true));
                                dispatch(setStreamingCards(true));
                    
                                dispatch(setCurrentFlashcardIndex(0));
                                dispatch(setCurrentFlashcardGroup(null));
                                dispatch(setSelectedResourceType('flashcards'));
                            }
                            
                            if (isQuizMode) {
                                if (value.includes('</quiz>')) {
                                    isQuizMode = false;
                                    value = value.replace('</quiz>', '');
                                    dispatch(setStreamingCards(false));
                                }
                                
                                // Process quiz data
                                try {
                                    
                                    const parsedData = JSON.parse(value);
                                    
                                    processQuizData(parsedData, quizGroup, dispatch);
                                    dispatch(setCenterPanelLoading(false));
                                    
                                } catch (error) {
                                    // If parsing fails, it might be an incomplete JSON object
                                    // We'll wait for more data
                                }
                            } else if (isFlashcardMode) {
                                if (value.includes('</flashcards>')) {
                                    isFlashcardMode = false;
                                    value = value.replace('</flashcards>', '');
                                    dispatch(setStreamingCards(false));
                                }
                                
                                // Process flashcard data
                                try {
                                    const parsedData = JSON.parse(value);
                                    processFlashcardData(parsedData, flashcardGroup, dispatch);
                                } catch (error) {
                                    // If parsing fails, it might be an incomplete JSON object
                                    // We'll wait for more data
                                }
                            } else {
                                accumulatedData += value;
                                setStreamedResponse((r) => r + value);
                                // responseRef.current = responseRef.current + value;
                            }

                            controller.enqueue(value);
                        }
                        customLog('OUTSIDE THE WHILE LOOP');
                        controller.close();
                        reader.releaseLock();
                    }
                })
            })
            .catch(error => console.log('error', error));
    } catch (error) {
        console.error("Error in sendOrCreateChatMessageStreaming:", error);
    }
};



export const processQuizData = (parsedData, quizGroup, dispatch) => {
    customLog('QUIZSTREAM parsedData is ' + JSON.stringify(parsedData));
    
    if (parsedData.question && parsedData.choices) {
        customLog('QUIZSTREAM make formatted question parsedData is ' + JSON.stringify(parsedData));
        dispatch(appendQuizQuestionToGroup({parsedData, incrementQuestion: false}));
    } else {
        dispatch(setCurrentQuizID(parsedData.id));
        quizGroup.id = parsedData.id;
        quizGroup.name = parsedData.name;
        
        dispatch(setCurrentQuizGroup(quizGroup));
    }
};

export const processFlashcardData = (parsedData, flashcardGroup, dispatch) => {
    customLog('FLASHCARDSTREAM parsedData is ' + JSON.stringify(parsedData));
    
    if (parsedData.question) {
        customLog('FLASHCARDSTREAM make formatted flashcard');
        dispatch(getAppendFlashcardToGroup({flashcard: parsedData, incrementCard: false}));
    } else if (parsedData.id) {
        dispatch(setCurrentFlashcardGroupID(parsedData.id));
        flashcardGroup.id = parsedData.id;
        flashcardGroup.name = parsedData.name || 'Placeholder';
        
        dispatch(setCurrentFlashcardGroup(flashcardGroup));
        dispatch(setSelectedResource({id: 'flashcard', type: 'flashcards', title: 'flashy', url: 'na'}));
    } else if (parsedData.name) {
        dispatch(editFlashcardGroupName(parsedData.name));
    }
};

// Core approve lesson function that takes setReactChatMessages as a parameter
export const approveLesson = async ({setReactChatMessages, dispatch, currentLessonID, setStreamedResponse}) => {
        try {
            setReactChatMessages([]);
            dispatch(setIsStreaming(false));
            setStreamedResponse('');
            dispatch(setScrollLocked(false));
            dispatch(setCurrentDisplayedTopicContent({}));
            await dispatch(approveLessonAPI(currentLessonID));
            //setIsLessonApproved(true);
            //dispatch(setCurrentLessonIndex(lessonGroup.index));
            dispatch(setCurrentTopicIndex(0));
            //dispatch(setCurrentLessonGroup(lessonGroup));
            //dispatch(setGuidedLessonModeActive(true));
            dispatch(setCurrentTopicScore(0));
            
            //sendOrCreateChatMessageStreaming({ lessonID: currentLessonID, selectedCourse: selectedCourseId, message: '', guidedLessonModeActive: true });
        } catch (error) {
            console.error("Error in approveLesson:", error);
        }
    };

export const continueLesson = async ({
    currentTopicObject,
    currentDisplayedTopicIndex,
    prereqTestModeActive,
    currentTopicID, 
    currentTopicIndex,
    dispatch
} = {}) => {
    try {
        if (prereqTestModeActive) {
            const maxRetries = 10;
            const retryDelay = 250; // 1/4 second in milliseconds
            
            const attemptNext = async (attempt) => {
                customLog('CONTINUELESSONWRAPPER CURRENTTOPICOBJECT is ' + JSON.stringify(currentTopicObject));
                if (currentTopicObject.conversation[currentDisplayedTopicIndex + 1]) {
                    dispatch(changeCurrentDisplayedTopicIndex(1));
                    return;
                }
                
                if (attempt < maxRetries) {
                    await new Promise(resolve => setTimeout(resolve, retryDelay));
                    return attemptNext(attempt + 1);
                }
            };
            
            await attemptNext(0);
        } else {
            // let increaseTopicIndexWithAPICall = true;
            customLog('CONTINUELESSON WRAPPER LAST TYPE IS ' + currentTopicObject.conversation[currentTopicObject.conversation.length - 1].type)
            if (currentTopicObject?.conversation?.[currentTopicObject.conversation.length - 1]?.type === "completed") {
                // if (currentDisplayedTopicContent?.type === "completed") {
                // }
                dispatch(changeCurrentDisplayedTopicIndex(1));
            } else {
                // if (currentTopicObject?.conversation?.[currentDisplayedTopicIndex + 1]) {
                //     dispatch(changeCurrentDisplayedTopicIndex(1));
                //     increaseTopicIndexWithAPICall = false;
                // } 
                dispatch(getTopicContent({topicId: currentTopicID, topicIndex: currentTopicIndex, currentDisplayedTopicIndex: currentDisplayedTopicIndex + 1}));
                
            }
        }
    } catch (error) {
        console.error("Error in continueLesson:", error);
    }
};

export const handleTopicSubmit = ({
    currentTopicID,
    dispatch,
    numberCorrectQuestions,
    numberTotalQuestions, 
}) => {
    customLog('HANDLETOPICSUBMIT PASSEDSCREEN CALLED');
        dispatch(incrementCurrentNumberSubtopics());
        const ratio = numberCorrectQuestions / numberTotalQuestions;
        let stars = 0;
        if (ratio >= 0.33 && ratio < 0.66) {
            stars = 1;
        } else if (ratio >= 0.66 && ratio < 1) {
            stars = 2;
        } else if (ratio === 1) {
            stars = 3;
        }
        dispatch(updateStarRating(stars));
        dispatch(alertTopicDone({topicId: currentTopicID, stars: stars}));
        dispatch(setNumberCorrectQuestions(0));
        dispatch(setNumberTotalQuestions(0));
        dispatch(clearPreviousTopic());
    
};