import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setPersonalizationOpen, uploadResumeAPI } from '../../store.js';

const PersonalizationPopup = () => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.chat.personalizationScreenOpen);
    const themeData = useSelector((state) => state.chat.themeData);

    const handleClose = () => {
        dispatch(setPersonalizationOpen(false));
    };

    const handleResumeUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            dispatch(uploadResumeAPI(file));
            handleClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    backgroundColor: themeData.color9,
                    borderRadius: '20px',
                    color: themeData.color5,
                    minWidth: '400px',
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>
                Personalization
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: themeData.color5
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '15px',
                    marginBottom: '20px'
                }}>
                    <Typography variant="h6" style={{ color: themeData.color5 }}>
                        Update Resume
                    </Typography>
                    <input
                        type="file"
                        id="resumeUpload"
                        style={{ display: 'none' }}
                        onChange={handleResumeUpload}
                    />
                    <Button
                        variant="contained"
                        onClick={() => document.getElementById('resumeUpload').click()}
                        style={{
                            backgroundColor: themeData.color1,
                            color: themeData.color9,
                            textTransform: 'none',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}
                    >
                        <FileUploadIcon />
                        Upload
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default PersonalizationPopup; 