import { Drawer, Typography, IconButton, Tooltip, Button, CircularProgress } from "@mui/material";
import platoImage from '../../../images/newPlatoNoBG.png';
import './topResource.css'
import { useSelector, useDispatch } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu'; // Example icon for the button
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import customLog from '../../../customLogger.js';
import { setSelectedResourceType, setQuiz, setFlashcards, setDrawerOpen, removeSelectedResource, sendMessageToAPI, fetchSimilarFlashcard, getSimilarFlashcard, getSimilarQuiz } from '../../../store.js';
import {useEffect} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SummarizeIcon from '@mui/icons-material/Summarize';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import ChatIcon from '@mui/icons-material/Chat';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const TopResourcePanel = ({sendOrCreateChatMessageStreaming}) => {
    // Initialize dispatch
    const dispatch = useDispatch();

    // Selector for getting theme data
    const themeData = useSelector((state) => state.chat.themeData);

    //Selector for getting the type of resource
    const resource = useSelector((state) => state.chat.selectedResource);

    //Selector for currentFlashcardQuiz
    const currentFlashcardQuiz = useSelector((state) => state.chat.currentFlashcardQuiz);

    const selectedResourceType = useSelector((state) => state.chat.selectedResourceType);
    const currentFlashcardIndex = useSelector((state) => state.chat.currentFlashcardIndex);
    const currentFlashcardGroup = useSelector((state) => state.chat.currentFlashcardGroup);

    const currentQuizIndex = useSelector((state) => state.chat.currentQuizIndex);
    const currentQuizGroup = useSelector((state) => state.chat.currentQuizGroup);


    //Select for streamingCards
    const streamingCards = useSelector((state) => state.chat.streamingCards);

    useEffect(() => {
        customLog('useEffect of resource.type says it is: ' + selectedResourceType);
    }, [resource]);
    const buttonStyle = {
        flex: 1,
        margin: '0 5px',
        color: themeData.color5,
        backgroundColor: 'transparent',
        padding: '6px 12px',
        textTransform: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: "ellipsis",
        borderRadius: '18px',
        background: 'linear-gradient(to top, #F3F5EF, #F4F6F2)',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const closeButtonStyle = {
        color: themeData.color5,
        backgroundColor: 'transparent',
        padding: '6px 12px',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: 'linear-gradient(to top, #F3F5EF, #F4F6F2)',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const iconStyle = {
        marginRight: '8px', // Add margin to the right of the icon
    };

    // Configuration object for different resource types
    const resourceConfig = {
        file: [
        { title: 'Summarize', tooltip: 'Summarize the document', queryType: 'Summary' },
        { title: 'Question', tooltip: 'Get asked a single question about the document', queryType: 'Question' },
        { title: 'Teach', tooltip: 'Coming soon!', queryType: 'Teach', disabledAlways: true },
        ],
        flashcards: [
        { title: 'Similar', tooltip: 'Get asked a similar flashcard', queryType: 'similarFlashcard' },
        { title: 'Explain', tooltip: 'Get an explanation to the answer', queryType: 'explainFlashcard' },
        { title: 'Hint', tooltip: 'Get a hint', queryType: 'flashcardHint' },
        ],
        quiz: [
        { title: 'Similar', tooltip: 'Get asked a similar question', queryType: 'similarQuiz' },
        { title: 'Explain', tooltip: 'Get an explanation to the answer', queryType: 'explainQuiz' },
        { title: 'Hint', tooltip: 'Get a hint', queryType: 'quizHint' },
        ],
        // Add more resource types as needed
    };

    // Helper function to handle sending specific requests based on button clicks
    const handleQuery = async (type) => {
        customLog(`handleQuery called with type ${type}`);
        let message = '';
        let presetPrompt = '';
        switch(type) {
            case 'Summary':
                presetPrompt = "summary";
                sendOrCreateChatMessageStreaming({presetPrompt, currentID: resource.id})
                break;
            case 'Question':
                presetPrompt = "question";
                sendOrCreateChatMessageStreaming({presetPrompt, currentID: resource.id})
                break;
            case 'Teach':
                message = `Teach me about the document, a single topic at a time. ` +
                `Ask me questions about topics one at a time, then correct any misunderstandings ` +
                `I have and fill in any gaps until all the content in the document has been gone through.`;
                presetPrompt = "teach";
                dispatch(sendMessageToAPI({ message, presetPrompt }));
                break;
            case 'similarFlashcard':
                customLog('GETSIMILARFLASHCARD CALLED');
                dispatch(getSimilarFlashcard({ flashcardID: currentFlashcardGroup.flashcards[currentFlashcardIndex].id }));
                return;
            case 'explainFlashcard':
                sendOrCreateChatMessageStreaming({currentID: currentFlashcardGroup.flashcards[currentFlashcardIndex].id, presetPrompt: 'explain'});
                break;        
            case 'flashcardHint':
                sendOrCreateChatMessageStreaming({currentID: currentFlashcardGroup.flashcards[currentFlashcardIndex].id, presetPrompt: 'hint'});
                break;
            case 'similarQuiz':
                dispatch(getSimilarQuiz({ quizID: currentQuizGroup.quiz_questions[currentQuizIndex].id }));
                return;
            case 'explainQuiz':
                sendOrCreateChatMessageStreaming({currentID: currentQuizGroup.quiz_questions[currentQuizIndex].id, presetPrompt: 'explain'});
                break;        
            case 'quizHint':
                sendOrCreateChatMessageStreaming({currentID: currentQuizGroup.quiz_questions[currentQuizIndex].id, presetPrompt: 'hint'});
                break;
            default:
                message = '';
        }

        customLog('updatedMessage outside switch is ' + message + ' and presetPrompt is ' + presetPrompt);
    };

    const renderButtonIcon = (button) => {
        if (streamingCards && (selectedResourceType === 'quiz' || selectedResourceType === 'flashcards')) {
            return <CircularProgress size={20} style={iconStyle} />;
        }

        switch (button.title) {
            case 'Summarize':
                return <SummarizeIcon style={iconStyle} />;
            case 'Question':
                return <QuestionAnswerIcon style={iconStyle} />;
            case 'Similar':
                return <FolderCopyIcon style={iconStyle} />;
            case 'Explain':
                return <ChatIcon style={iconStyle} />;
            case 'Hint':
                return <LightbulbIcon style={iconStyle} />;
            default:
                return null;
        }
    };

    return (
        <Drawer
        anchor="right"
        open={true}
        variant="persistent"
        PaperProps= {{
            className: 'topResourceDrawer',
            style: {
                backgroundColor: 'transparent',
                zIndex: 2500 
            }
        }}
        >
            <div className="resourceDrawerContent" style={{color: themeData.color5}}>
            <div className="buttonContainer">
                {resourceConfig[selectedResourceType]?.map((button, index) => (
                    <Tooltip key={index} title={button.disabledAlways ? button.tooltip : (streamingCards && (selectedResourceType === 'quiz' || selectedResourceType === 'flashcards') ? "Processing..." : button.tooltip)}>
                        
                        <Button 
                            variant="contained" 
                            style={{
                                ...buttonStyle,
                                textOverflow: 'clip',
                                ...(streamingCards && {
                                    paddingLeft: '25px',  // Adjust this value to shift the content left
                                }),
                                
                                // paddingLeft: '45px',
                            }}
                            onClick={() => handleQuery(button.queryType)}
                            disabled={streamingCards && (selectedResourceType === 'quiz' || selectedResourceType === 'flashcards') || button.disabledAlways}
                        >
                            {renderButtonIcon(button)}
                            {button.title}
                        </Button>
                        
                    </Tooltip>
                ))}
                    <Tooltip title="Close">
                        <IconButton 
                            onClick={() => {
                                dispatch(removeSelectedResource());
                                dispatch(setQuiz([]));
                                dispatch(setFlashcards([]));
                                dispatch(setSelectedResourceType(null));
                            }}
                            style={closeButtonStyle}
                            size="small"
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </Drawer>
    );
}

export default TopResourcePanel;