// ResourcePanel.js
import React, { useState, useRef, useEffect } from 'react';
import { IconButton, CircularProgress, Card, CardContent, Typography, Tooltip } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { changeCurrentFlashcardIndex, removeSelectedResource, sendMessageToAPI, setFlashcardQuizObject, changeFlashcardQuizIndex, setCurrentQuizID } from '../../store.js';
import { useDispatch, useSelector } from 'react-redux';
import customLog from '../../customLogger.js';
import Quiz from '../resources/Quiz';

const ResourcePanel = ({ 
    resource, 
    extendQuiz, 
    extendFlashcards, 
    quiz, 
    currentFlashcardQuizIndex, 
    currentFlashcardQuiz, 
    continueLesson 
}) => {
    customLog('RESOURCEPANEL RENDERED with all props of quiz ' + JSON.stringify(quiz) + ' and then currentFlashcardQuizIndex ' + currentFlashcardQuizIndex);
    customLog('RESOURCEPANEL RENDERED with resource ' + JSON.stringify(resource));
    const dispatch = useDispatch();
    const [numPages, setNumPages] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);
    const pdfViewerRef = useRef();
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isAnswerChecked, setIsAnswerChecked] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    const [userAnswers, setUserAnswers] = useState([]);

    const flashcards = useSelector((state) => state.chat.flashcards);
    const selectedResource = useSelector((state) => state.chat.selectedResource);
    const currentFlashcardGroup = useSelector((state) => state.chat.currentFlashcardGroup);
    const currentFlashcardIndex = useSelector((state) => state.chat.currentFlashcardIndex);
    const selectedResourceType = useSelector((state) => state.chat.selectedResourceType);
    const APIToken = useSelector((state) => state.chat.APIToken);
    const currentLessonID = useSelector((state) => state.chat.currentLessonID);

    const currentQuizGroup = useSelector((state) => state.chat.currentQuizGroup);
    const currentQuizIndex = useSelector((state) => state.chat.currentQuizIndex);

    const guidedLessonModeActive = useSelector((state) => state.chat.guidedLessonModeActive);

    const [bullshitUseState, setBullshitUseState] = useState(null);

    useEffect(() => {
        customLog('USEEFFECT CURRENTFLASHCARDGROUP forcing rerender');
        customLog(JSON.stringify('RESOURCE IS ' + resource));
        setBullshitUseState(currentFlashcardGroup);
    }, [currentFlashcardGroup]);

    useEffect(() => {
        customLog('RESOURCE PANEL CURRENTFLASHCARDGROUP IS ' + JSON.stringify(currentFlashcardGroup));
        customLog('RESOURCE PANEL CURRENTFLASHCARDGROUP BOOLEAN IS ' + (currentFlashcardGroup && currentFlashcardGroup.flashcards && currentFlashcardGroup.flashcards.length > 0));
    }, [currentFlashcardGroup]);

    const streamingCards = useSelector((state) => state.chat.streamingCards);

    useEffect(() => {
        customLog('currentFlashcardIndex is ' + currentFlashcardQuizIndex);
        setShowAnswer(false);
        setSelectedAnswer(null);
        setIsCorrect(null); 
        if (flashcards && flashcards.length > 0) {
            customLog('USEEFFECT CURRENTFLASHCARDQUIZINDEX flash length more than 0');
            dispatch(setFlashcardQuizObject(flashcards[currentFlashcardQuizIndex]));
        } else if (quiz && quiz.length > 0) {
            customLog('USEEFFECT CURRENTFLASHCARDQUIZINDEX quiz length more than 0');
            dispatch(setFlashcardQuizObject(quiz[currentFlashcardQuizIndex]));
        }
    }, [currentFlashcardQuizIndex, flashcards, quiz, dispatch ]);

    const themeData = useSelector((state) => state.chat.themeData);
    const iframeDragged = useSelector((state) => state.chat.iframeDragged);

    const [buttonState, setButtonState] = useState('check');

    const handleAnswer = (answerIndex) => {
        if (!isAnswerChecked) {
            setSelectedAnswer(answerIndex);
        }
    };

    // const checkAnswer = () => {
    //     if (selectedAnswer !== null && buttonState === 'check') {
    //         const currentQuestion = currentQuizGroup.quiz_questions[currentQuizIndex];
    //         const selectedChoice = currentQuestion.choices[selectedAnswer];
    //         setIsAnswerChecked(true);
    //         setIsCorrect(selectedChoice.is_correct);
            
    //         const correctAnswerIndex = currentQuestion.choices.findIndex(choice => choice.is_correct);
    //         setCorrectAnswer(correctAnswerIndex);

    //         setButtonState(selectedChoice.is_correct ? 'correct' : 'incorrect');
    //         if (guidedLessonModeActive) {
    //             dispatch(incrementCurrentTopicScore(5));
    //             setUserAnswers(prevAnswers => [
    //                 ...prevAnswers,
    //                 {
    //                     question_id: currentQuestion.id,
    //                     answer_choice_id: selectedChoice.id
    //                 }
    //             ]);
    //         }
    //     } else if (buttonState === 'correct' || buttonState === 'incorrect') {
    //         navigateQuiz(1);
    //     }
    // };

    const resetQuizState = () => {
        setSelectedAnswer(null);
        setIsAnswerChecked(false);
        setCorrectAnswer(null);
        setButtonState('check');
        setIsCorrect(null);
    };

    // const navigateQuiz = async (direction) => {
    //     const newIndex = currentQuizIndex + direction;
    //     if (newIndex >= 0 && newIndex < currentQuizGroup.quiz_questions.length) {
    //         dispatch(changeCurrentQuizIndex(direction));
    //         resetQuizState();
    //     } else if (newIndex === currentQuizGroup.quiz_questions.length && guidedLessonModeActive) {
    //         resetQuizState();
    //         const testData = {
    //             test_id: currentQuizGroup.id,
    //             test_type: 1,
    //             questions: userAnswers,
    //             lesson_id: currentLessonID
    //         };
    //         try {
    //             const response = await fetch(DjangoHost + "/api/lessons/test/grade/", {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Authorization: `Bearer ${APIToken}`,
    //                 },
    //                 body: JSON.stringify(testData),
    //             });

    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }

    //             const result = await response.json();
    //             const passed = result.passed;
    //             customLog('NAVIGATEQUIZ CONTINUELESSON PASSED IS ' + passed);
    //             continueLesson();
    //             setUserAnswers([]);
    //         } catch (error) {
    //             console.error("Error grading quiz:", error);
    //         }
    //     }
    // };

    const iconButtonStyle = {
        margin: '0 5px',
        color: themeData.color5,
        backgroundColor: themeData.color2,
        padding: '6px',
        minWidth: '36px',
        width: '36px',
        height: '36px',
    };

    const flashcardButtonStyle = {
        backgroundColor: themeData.color3,
        color: themeData.color5,
        margin: '1px',
        padding: '5px 10px',
        borderRadius: '10px'
    };

    const answerButtonsContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '90%',
        marginTop: '10px',
        marginLeft: '5%'
    };

    const answerButtonStyle = (answer) => ({
        width: '100%',
        minHeight: '75px',
        background: isAnswerChecked
            ? (selectedAnswer === answer && isCorrect) || correctAnswer === answer
                ? 'linear-gradient(to top, #45a049, #4CAF50)'
                : selectedAnswer === answer
                    ? 'linear-gradient(to top, #e64a19, #FF6347)'
                    : 'linear-gradient(to top, #F3F5EF, #F4F6F2)'
            : selectedAnswer === answer
                ? 'linear-gradient(to top, #E8EAE3, #EAECE5)'
                : 'linear-gradient(to top, #F3F5EF, #F4F6F2)',
        color: themeData.color5,
        margin: '5px 0',
        borderRadius: '18px',
        padding: '12px',
        textAlign: 'left',
        cursor: 'pointer',
        overflowY: "auto",
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    });

    const flashcardContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        background: 'transparent',
        border: 'none',
        borderRadius: '12px',
        overflow: 'hidden',
        paddingTop: '0px'
    };

    const fileContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '90%',
        background: 'transparent',
        borderRadius: '12px',
        border: '1px solid #000',
        overflow: 'hidden',
        paddingTop: '0px',
        margin: '0 auto'
    };

    const flashcardStyle = {
        cursor: 'pointer',
        width: '90%',
        minHeight: '200px',
        background: 'linear-gradient(to top, #F3F5EF, #F4F6F2)',
        borderRadius: '18px',
        marginBottom: '20px',
        marginTop: '20px',
        overflowY: 'auto',
        padding: '16px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        alignSelf: 'right',
        marginLeft: '5%',
    };

    const cardContentStyle = {
        overflowY: 'auto',
    };

    const DjangoHost = process.env.REACT_APP_DJANGO_HOST;

    const navigateFlashcard = (direction) => {
        customLog('NAVIGATEFLASHCARD CALLED WITH DIRECTION ' + direction);
        customLog('NAVIGATEFLASHCARD CALLED WITH CURRENTINDEX ' + currentFlashcardQuizIndex);
        const newIndex = currentFlashcardIndex + direction;
        if (
            (currentFlashcardGroup && newIndex >= 0 && newIndex < currentFlashcardGroup.flashcards.length)
          ) {
            dispatch(changeCurrentFlashcardIndex(direction));
            setShowAnswer(false);
          }
    };

    const navigateLocation = (direction) => {
        customLog(`navigateLocation called with direction ${direction}`);
        customLog(`currentLocation is ${currentLocation}`);
        customLog(`resource locations is ${resource.locations}`);
        
        const newIndex = currentLocation + direction;
        const isValidIndex = newIndex >= 0 && (
            (resource.type === 'flashcard' || resource.type === 'quiz') ? newIndex < resource.content.length : newIndex < resource.locations.length
        );
        setSelectedAnswer(null);

        if (isValidIndex) {
            setCurrentLocation(newIndex);
            setShowAnswer(false);
            if (resource.type === 'file') {
                scrollToPage(resource.locations[newIndex]);
            }
        }
    };

    const scrollToPage = (pageNumber) => {
        if (pdfViewerRef.current) {
            const pageToScrollTo = pdfViewerRef.current.querySelector(`.react-pdf__Page[data-page-number='${pageNumber}']`);
            if (pageToScrollTo) {
                pageToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const toggleShowAnswer = () => {
        setShowAnswer(!showAnswer);
    };

    const contextFileLoading = useSelector((state) => state.chat.contextFileLoading);

    const checkButtonStyle = {
        backgroundColor: 'transparent',
        color: themeData.color5,
        padding: '10px',
        borderRadius: '18px',
        cursor: selectedAnswer === null && buttonState === 'check' ? 'not-allowed' : 'pointer',
        opacity: selectedAnswer === null && buttonState === 'check' ? 0.5 : 1,
        border: '0.5px solid #737164',
        background: buttonState === 'check' 
            ? `linear-gradient(to top, ${themeData.color18}, ${themeData.color19})` 
            : buttonState === 'correct'
                ? 'linear-gradient(to top, #45a049, #4CAF50)'
                : buttonState === 'incorrect'
                    ? 'linear-gradient(to top, #e64a19, #FF6347)'
                    : `linear-gradient(to top, ${themeData.color18}, ${themeData.color19})`,
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        minWidth: '120px',
    };

    return (
        <div className={iframeDragged ? 'disable-pointer-events' : ''} style={{ 
            height: 'calc(100% - 78px)',
            marginTop: '8px', 
            marginBottom: '8px',
            backgroundColor: 'transparent',
        }}>

            <div ref={pdfViewerRef} style={{ height: 'calc(100%)', overflowY: 'auto', marginTop: '0px', marginBottom: '0px' }}>
                {contextFileLoading && (
                    <div style={{...fileContainerStyle, border: 'none'}}>
                        <CircularProgress />
                    </div>
                )}
                <div style={ selectedResourceType === 'file' ? fileContainerStyle : flashcardContainerStyle}>
                    {selectedResourceType === 'file' && (
                        contextFileLoading ? (
                            <CircularProgress />
                        ) : (
                            <iframe
                                src={`${resource.url}#page=${resource.locations[currentLocation]}&view=Fit&cacheBuster=${new Date().getTime()}`}
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                allowFullScreen
                                borderRadius='12px'
                                >
                            </iframe>
                        )
                    )}
                    {resource && resource.type === 'video' && (
                        <div style={flashcardStyle}>
                            <video 
                                src={`${resource.url}#t=${resource.locations[currentLocation]}`}  
                                controls
                                style={{
                                    width: '200px',
                                    height: '300px',
                                    objectFit: 'contain',
                                    borderRadius: 'inherit'
                                }}
                            ></video>
                        </div>
                    )} 
                
                    {selectedResourceType === "flashcards" && currentFlashcardGroup && currentFlashcardGroup.flashcards && currentFlashcardGroup.flashcards.length > 0 && (
                        <>
                        <Card onClick={toggleShowAnswer} style={flashcardStyle}>
                            <CardContent style={cardContentStyle}>
                                <Typography  variant="h5" component="div" style={{ color: themeData.color5, minHeight: '150px' }}>
                                    {showAnswer ? currentFlashcardGroup.flashcards[currentFlashcardIndex].answer : currentFlashcardGroup.flashcards[currentFlashcardIndex].question}
                                </Typography>
                            </CardContent>
                        </Card>
                         <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '-10px' }}>
                            <Tooltip title="Previous">
                                <span>
                                    <IconButton 
                                        onClick={() => navigateFlashcard(-1)} 
                                        disabled={currentFlashcardIndex === 0}
                                        style={iconButtonStyle}
                                        size="small"
                                    >
                                        <ArrowLeftIcon fontSize="small" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <span style={{ display: 'flex', alignItems: 'center', margin: '0 10px', color: 'black' }}>
                                {currentFlashcardIndex + 1} / {currentFlashcardGroup.flashcards.length}
                            </span>
                            {currentFlashcardIndex === currentFlashcardGroup.flashcards.length - 1 && !streamingCards ? (
                                <Tooltip title="Generate 10 more cards">
                                    <span>
                                        <IconButton 
                                            onClick={() => extendFlashcards({flashcardGroupID: currentFlashcardGroup.id})}
                                            style={iconButtonStyle}
                                            size="small"
                                        >
                                            <span style={{ fontSize: '1.5em' }}>+</span> 
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Next">
                                    <span>
                                        <IconButton 
                                            onClick={() => navigateFlashcard(1)} 
                                            disabled={currentFlashcardIndex === currentFlashcardGroup.flashcards.length - 1}
                                            style={iconButtonStyle}
                                            size="small"
                                        >
                                            <ArrowRightIcon fontSize="small" />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        </div> 
                    </>
                    )}
                {selectedResourceType === "quiz" && currentQuizGroup && currentQuizGroup.quiz_questions && currentQuizGroup.quiz_questions.length > 0 && (
                    <Quiz 
                        quiz={currentQuizGroup}
                        currentQuizIndex={currentQuizIndex}
                        extendQuiz={extendQuiz}
                        continueLesson={continueLesson}
                        streamingCards={streamingCards}
                        themeData={themeData}
                    />
                )}
                {resource && resource.type === 'link' && (
                    <iframe
                        src={resource.url}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none'
                        }}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                )}
                </div>
            </div>
        </div>
    );
};

export default ResourcePanel;
