import React, { useState, useRef, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, Button, Divider, Menu, MenuItem, Link, Card, CardContent } from '@mui/material';

import InputForm from './components/InputForm/inputForm.js';
import { useSelector, useDispatch } from 'react-redux';
import { getExploresAPI,changeCurrentDisplayedExploreIndex, setPrereqTestModeActive, setOnHomeScreen, setCenterPanelVisible, setPrereqTestOptionScreenOpen, getTopicContent, getAllLessons, setSettingsOpen,changeCurrentDisplayedTopicIndex, setCurrentLessonGroup, setCurrentLessonIndex, setGuidedLessonModeActive } from './store.js';
import AddIcon from '@mui/icons-material/Add';
import SchoolIcon from '@mui/icons-material/School';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExploreIcon from '@mui/icons-material/Explore';
import platoImage from './images/plato.png';
import { ChevronRight } from '@mui/icons-material';
import {handleTopicSubmit, createPrereqQuiz, continueLesson, approveLesson, retrieveLesson, handleSend, handleLesson, sendOrCreateChatMessageStreaming } from './chatUtils';
import customLog from './customLogger';
import CenteredResourceView from './components/CenteredResourceView';
import TopAppBar from './components/AppBar';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
// import TinderCard from 'react-tinder-card';
import stickman from './images/noProfilePhotoNoBG.png';
import SettingsPopup from './components/SettingsPopup/SettingsPopup.js';    
import PersonalizationPopup from './components/PersonalizationPopup/PersonalizationPopup.js';
import LoginSignupDialogComponent from './components/LoginSignupDialog/LoginSignupDialogComponent.js';
import SortIcon from '@mui/icons-material/Sort';
import ExploreCardsView from './components/mobile/ExploreCardsView.js';
const DjangoHost = process.env.REACT_APP_DJANGO_HOST;

const MobilePage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const dispatch = useDispatch();
  const themeData = useSelector((state) => state.chat.themeData);
  const top4LessonGroups = useSelector((state) => state.chat.top4LessonGroups);
  const currentLessonGroup = useSelector((state) => state.chat.currentLessonGroup);
  const centerPanelVisible = useSelector((state) => state.chat.centerPanelVisible);
  const onHomeScreen = useSelector((state) => state.chat.onHomeScreen);
  const APIToken = useSelector((state) => state.chat.APIToken);
  const timeLimit = useSelector((state) => state.chat.timeLimit);
  const level = useSelector((state) => state.chat.level);
  const [reactChatMessages, setReactChatMessages] = useState([]);
  const [streamedResponse, setStreamedResponse] = useState("");
  const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);
  const guidedLessonModeActive = useSelector((state) => state.chat.guidedLessonModeActive);
  const currentLessonID = useSelector((state) => state.chat.currentLessonID);
  const selectedCourseId = useSelector((state) => state.chat.selectedCourse);
  const systemPrompt = useSelector((state) => state.chat.systemPrompt);
  const currentConversationID = useSelector((state) => state.chat.currentConversationID);
  const conversationJustCreatedRef = useRef(null);
  const [cancelStream, setCancelStream] = useState(false);
  const selectedResourceType = useSelector((state) => state.chat.selectedResourceType);
  let currentTopicIndex = useSelector((state) => state.chat.currentTopicIndex);
  const abortControllerRef = useRef(null);
  let firstName = useSelector((state) => state.chat.firstName);
  let username = useSelector((state) => state.chat.username);
  const [likedCourses, setLikedCourses] = useState([]);
  let profilePicture = useSelector((state) => state.chat.profilePicture);
  const isStreaming = useSelector((state) => state.chat.isStreaming);
  let userEmail = useSelector((state) => state.chat.email);

  const settingsOpen = useSelector((state) => state.chat.settingsOpen);
  const prereqTestModeActive = useSelector((state) => state.chat.prereqTestModeActive);

  // Centered Resource View redux variables
  const selectedResourceRedux = useSelector((state) => state.chat.selectedResource);
  const allLessonGroups = useSelector((state) => state.chat.allLessonGroups);
  const currentTopicID = useSelector((state) => state.chat.currentTopicID);
  const currentDisplayedTopicIndex = useSelector((state) => state.chat.currentDisplayedTopicIndex);
  const currentDisplayedTopicContent = useSelector((state) => state.chat.currentDisplayedTopicContent);
  const prereqTestQuestions = useSelector((state) => state.chat.prereqTestQuestions);
  const numberCorrectQuestions = useSelector((state) => state.chat.numberCorrectQuestions);
  const numberTotalQuestions = useSelector((state) => state.chat.numberTotalQuestions);
  const currentDisplayedExploreIndex = useSelector((state) => state.chat.currentDisplayedExploreIndex);
  const exploresArray = useSelector((state) => state.chat.exploresArray);
  const lastAPIExploreIndexCalled = useSelector((state) => state.chat.lastAPIExploreIndexCalled);


  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState('recent'); // 'recent' or 'alphabetical'

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortChange = (sortType) => {
    setSortBy(sortType);
    handleSortClose();
  };

  const getSortedLessonGroups = () => {
    if (sortBy === 'alphabetical') {
      return [...allLessonGroups].sort((a, b) => 
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
    }
    return allLessonGroups; // Default 'recent' sorting
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 1) {
      dispatch(getAllLessons());
    }
  };

  const retrieveLessonMobile = async ({lessonGroup}) => {
    customLog('RETRIEVELESSONMOBILE CALLED for lessonGroup ' + JSON.stringify(lessonGroup));
    await retrieveLesson({lessonGroup, dispatch});
  }

  const onSwipe = (direction, course) => {
    if (direction === 'right') {
      setLikedCourses(prev => [...prev, course]);
    } else if (direction === 'up') {
      retrieveLessonMobile({lessonGroup: course});
    }
    setCurrentCardIndex(prev => prev + 1);
  }

  const handleTopicSelect = (topic) => {
    if (!topic.locked) {
      console.log(`Starting topic: ${topic.name}`);
    }
  };

  // Initial load
  useEffect(() => {
    customLog('INITIALLOAD EXPLORESCALL');
    if (exploresArray.length === 0) {
      dispatch(getExploresAPI(lastAPIExploreIndexCalled));
    }
  }, []);

  // Load more explores when getting close to end
  useEffect(() => {
    customLog('LASTAPIEXPLOREINDEXCALLED IS ' + lastAPIExploreIndexCalled + ' and CURRENT DISPLAYED EXPLORE INDEX IS ' + currentDisplayedExploreIndex);
    if (currentDisplayedExploreIndex - lastAPIExploreIndexCalled === 5) {
      dispatch(getExploresAPI(lastAPIExploreIndexCalled));
    }
  }, [lastAPIExploreIndexCalled, currentDisplayedExploreIndex]);

  const createLesson = ({message, file, exploreID, prereqResults}) => handleLessonWrapper({message, file, exploreID, prereqResults});
  const editLesson = (lessonId, editContent) => handleLessonWrapper({editedLessonId: lessonId, editedContent: editContent});
  
  const handleLessonWrapper = ({message, file, editedLessonId, editedContent, exploreID, prereqResults}) => {
    return handleLesson({
      message,
      file, 
      editedLessonId,
      editedContent,
      exploreID,
      prereqResults,
      props: {
        APIToken,
        timeLimit,
        level,
        setReactChatMessages,
        setStreamedResponse,
        DjangoHost
      },
      dispatch
    });
  };
  const [lessonCreationContent, setLessonCreationContent] = useState(null);
  const handleSendWrapper = async (event, inputMessage) => {
    customLog('HANDLESENDWRAPPER MOBILE PAGE CALLED with inputMessage of ' + inputMessage);
    // setLessonCreationContent(inputMessage);
    // dispatch(setOnHomeScreen(false));
    // dispatch(setCenterPanelVisible(true));
    // dispatch(setPrereqTestOptionScreenOpen(true));
    await handleSend({
      event,
      dispatch,
      createLesson,
      sendOrCreateChatMessageStreaming, 
      inputMessage,
      props: {
        isLoggedIn,
        setStreamedResponse,
        guidedLessonModeActive,
        currentLessonID,
        selectedCourseId,
        setReactChatMessages
      }
    });
  }

  const approveLessonWrapper = () => {
    return approveLesson({setReactChatMessages, dispatch, currentLessonID, setStreamedResponse});
  };
  const currentTopicObject = useSelector((state) => state.chat.currentTopicObject);
    // Wrapper function that provides required params to continueLesson
    const continueLessonWrapper = async () => {
      customLog('CONTINUELESSONWRAPPER CALLED with prereqTestModeActive of ' + prereqTestModeActive);
      if (currentTopicObject.conversation.length === currentDisplayedTopicIndex + 1) {
          handleTopicSubmitWrapper();
      } else {
          await continueLesson({
              currentTopicObject,
              currentDisplayedTopicIndex,
              prereqTestModeActive,
              currentTopicID,
              currentTopicIndex,
              dispatch
          });
      }
  };

  const previousPageWrapper = () => {
    if (currentDisplayedTopicIndex > 0) {
        dispatch(changeCurrentDisplayedTopicIndex(-1));
    }
};

  const handleTopicSubmitWrapper = () => {
    if (prereqTestModeActive) {
        dispatch(setPrereqTestModeActive(false));
        customLog('HANDLETOPICSUBMIT CURRENTDISPLAYEDTOPICCONTENT IS ' + JSON.stringify(currentDisplayedTopicContent)); 
        createLesson({prereqResults: {
            prerequisite_assessment: {
                id: currentDisplayedTopicContent.test_id,
                questions: prereqTestQuestions,
            },
            time_limit: 15,
            level: 4
        }});
    } else {
        handleTopicSubmit({
            currentTopicID,
            dispatch,
            numberCorrectQuestions,
            numberTotalQuestions,
        });
    }
  }

  const sendOrCreateChatMessageStreamingWrapper = ({selectedCourse, message, presetPrompt, currentID, lessonID, guidedLessonModeActive}) => {
    return sendOrCreateChatMessageStreaming({
      selectedCourse,
      message, 
      presetPrompt,
      currentID,
      lessonID,
      guidedLessonModeActive,
      props: {
        APIToken,
        systemPrompt,
        selectedCourseId,
        currentConversationID,
        conversationJustCreatedRef,
        cancelStream,
        setCancelStream,
        reactChatMessages,
        setReactChatMessages,
        setStreamedResponse,
        selectedResourceType,
        currentLessonGroup,
        currentTopicIndex,
        DjangoHost,
        abortControllerRef
      },
      dispatch
    });
  };

  const handleLessonCreation = (content) => {
    setLessonCreationContent(content);
    dispatch(setOnHomeScreen(false));
    dispatch(setCenterPanelVisible(true));
    dispatch(setPrereqTestOptionScreenOpen(true));
}

  const ExploreCard = ({ course }) => (
    <div style={{
      width: '100%',
      height: '100%',
      backgroundColor: themeData.color9,
      borderRadius: '20px',
      padding: '20px',
      //display: 'flex',
      //flexDirection: 'column',
      //justifyContent: 'space-between',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      //position: 'absolute'
    }}>
      <Typography variant="h5" sx={{ color: themeData.color1, textAlign: 'center', marginBottom: 2 }}>
        {course.name}
      </Typography>
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <SchoolIcon sx={{ fontSize: 80, color: themeData.color5 }} />
      </Box>
      <Typography variant="body2" sx={{ color: themeData.color5, textAlign: 'center' }}>
        Swipe right to like, up to start, or left to skip
      </Typography>
    </div>
  );

  if (centerPanelVisible) {
    return (
      <div className="App" style={{
        background: `linear-gradient(to bottom, ${themeData.color8}, ${themeData.color7})`,
        minHeight: '100dvh'
      }}>
        <TopAppBar isMobile={true} />
        <CenteredResourceView
          sendOrCreateChatMessageStreaming={sendOrCreateChatMessageStreamingWrapper}
          createLesson={createLesson}
          createPrereqQuiz={createPrereqQuiz}
          lessonCreationContent={lessonCreationContent}
          messages={reactChatMessages}
          streamedResponse={streamedResponse}
          username={firstName || username}
          handleSend={handleSendWrapper}
          isMobile={true}
          onLessonApproved={approveLessonWrapper}
          onLessonEdited={editLesson}
          onLessonContinue={continueLessonWrapper}
          continueLesson={continueLessonWrapper}
          previousPage={previousPageWrapper}
          platoImage={platoImage}
          stickman={profilePicture || stickman}
          isStreaming={isStreaming}
        />
      </div>
    );
  }

  return (
    <div className="App" style={{ 
      width: '100%', 
      height: '100dvh', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', // Changed from center to space-between
      alignItems: 'center',
      backgroundColor: themeData.color7,
      // position: 'fixed', // Added fixed positioning
      // top: 0,
      // left: 0,
      // right: 0,
      // bottom: 0,
      overflow: 'hidden' // Prevent scrolling
    }}>
      <TopAppBar isMobile={true} />
      <SettingsPopup 
                open={settingsOpen} 
                onClose={() => dispatch(setSettingsOpen(false))}
                themeData={themeData}
                userEmail={userEmail} 
            />
      <PersonalizationPopup />
      <LoginSignupDialogComponent/>
      <Box sx={{ 
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden', // Prevent scrolling
        //marginTop: '70px',
      }}>

        {activeTab === 1 && (
          <Box sx={{ padding: 2, width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <div className="centered-plato-container">
              <div style={{
                fontSize: '31.415px',
                fontWeight: '400',
                color: themeData.color1,
                //marginTop: '15px',
               //marginBottom: '25px',
                textAlign: 'center',
                fontFamily: "'Roboto', sans-serif",
                lineHeight: '1.3',
                background: 'transparent',
                padding: '8px',
                borderRadius: '10px',
                transition: 'all 0.3s ease',
              }}>
                Learn anything fast
              </div>
              <img src={platoImage} alt="Plato" className="centered-plato" style={{ marginBottom: '-20px' }} />
              <div className="form-style">
                <InputForm handleSend={handleLessonCreation} />
              </div>
            </div>
          </Box>
        )}
        {activeTab === 2 && (
          <Box sx={{ 
            padding: 1, 
            width: '100%',
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '60px'
          }}>
            <Box sx={{
                display: 'flex', 
                alignItems: 'left', 
                mb: 0,
                px: 0,
                padding: 1
               }}>
              <Button
                onClick={handleSortClick}
                sx={{ 
                  color: themeData.color1,
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  display: 'flex',
                  gap: '8px',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <SortIcon sx={{ marginLeft: '-10px' }} />
                {sortBy === 'recent' ? 'Most Recent' : 'Alphabetical'}
              </Button>
              <Menu
                anchorEl={sortAnchorEl}
                open={Boolean(sortAnchorEl)}
                onClose={handleSortClose}
                PaperProps={{
                  sx: {
                    backgroundColor: themeData.color9,
                    color: themeData.color1,
                  }
                }}
              >
                <MenuItem 
                  onClick={() => handleSortChange('recent')}
                  sx={{ 
                    color: themeData.color1,
                    backgroundColor: sortBy === 'recent' ? `${themeData.color8} !important` : 'transparent'
                  }}
                >
                  Most Recent
                </MenuItem>
                <MenuItem 
                  onClick={() => handleSortChange('alphabetical')}
                  sx={{ 
                    color: themeData.color1,
                    backgroundColor: sortBy === 'alphabetical' ? `${themeData.color8} !important` : 'transparent'
                  }}
                >
                  Alphabetical
                </MenuItem>
            </Menu>
            </Box>
            <Box>
              {allLessonGroups.length === 0 ? (
                <Typography variant="body1" sx={{ color: themeData.color5, textAlign: 'center' }}>
                  Library is empty, create a lesson
                </Typography>
              ) : (
                getSortedLessonGroups().map((lessonGroup, index) => (
                  <React.Fragment key={lessonGroup.id}>
                    <Box 
                      onClick={() => retrieveLessonMobile({lessonGroup})} 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        marginLeft: '8px',
                        padding: 0,
                        backgroundColor: 'transparent', 
                        borderRadius: 1, 
                        cursor: 'pointer',
                        minHeight: '70px',
                        '&:hover': {
                          backgroundColor: themeData.color8,
                        }
                      }}
                    >
                      {lessonGroup.image_url ? (
                        <img 
                          src={lessonGroup.image_url}
                          alt=""
                          style={{
                            width: '48px',
                            height: '48px',
                            marginRight: '16px',
                            objectFit: 'cover',
                            borderRadius: '8px'
                          }}
                        />
                      ) : (
                        index % 3 === 0 ? (
                          <HistoryEduIcon sx={{ color: themeData.color5, marginRight: '16px', fontSize: '2rem' }} />
                        ) : index % 3 === 1 ? (
                          <SquareFootIcon sx={{ color: themeData.color5, marginRight: 2, fontSize: '2rem' }} />
                        ) : (
                          <SchoolIcon sx={{ color: themeData.color5, marginRight: 2, fontSize: '2rem' }} />
                        )
                      )}
                      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            color: themeData.color1, 
                            fontSize: '1rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%'
                          }}
                        >
                          {lessonGroup.name}
                        </Typography>
                        <Typography variant="caption" sx={{ color: themeData.color5, display: 'flex', alignItems: 'center', gap: '4px' }}>
                           Micro-course • {firstName || username}
                        </Typography>
                      </Box>
                    </Box>
                    {index < allLessonGroups.length - 1 && <Divider />}
                  </React.Fragment>
                ))
              )}
            </Box>
          </Box>
        )}
        {activeTab === 0 && (
          <Box sx={{ 
            padding: 0, 
            width: '100%', 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            {/* {currentCardIndex >= top4LessonGroups.length && (
              <Typography variant="h6" sx={{ color: themeData.color1 }}>
                No more courses to explore!
              </Typography>
            )} */}
            <ExploreCardsView 
              createLesson={createLesson}
              //exploresArray={exploresArray}
            />
          </Box>
        )}
      </Box>
      <Tabs 
        value={activeTab} 
        onChange={handleTabChange} 
        variant="fullWidth" 
        sx={{ borderTop: '1px solid rgba(128, 128, 128, 0.1)', backgroundColor: themeData.color7, width: '100%' }}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab 
          icon={<ExploreIcon sx={{ color: activeTab === 0 ? themeData.color1 : themeData.color5, fontSize: '1.2rem' }} />}
          label={<Typography variant="body2" sx={{ color: activeTab === 2 ? themeData.color1 : themeData.color5, textTransform: 'none', fontSize: '9.17px' }}>Explore</Typography>}
          sx={{ width: '33.33%', '&.Mui-selected': { borderBottom: 'none' } }}
        />
        <Tab 
          icon={<AddIcon sx={{ color: activeTab === 1 ? themeData.color1 : themeData.color5, fontSize: '1.2rem' }} />} 
          label={<Typography variant="body2" sx={{ color: activeTab === 0 ? themeData.color1 : themeData.color5, textTransform: 'none', fontSize: '9.17px' }}>Create</Typography>} 
          sx={{ width: '33.33%', '&.Mui-selected': { borderBottom: 'none' } }}
        />
        <Tab 
          icon={<LibraryBooksIcon sx={{ color: activeTab === 2 ? themeData.color1 : themeData.color5, fontSize: '1.2rem' }} />} 
          label={<Typography variant="body2" sx={{ color: activeTab === 1 ? themeData.color1 : themeData.color5, textTransform: 'none', fontSize: '9.17px' }}>Library</Typography>} 
          sx={{ width: '33.33%', '&.Mui-selected': { borderBottom: 'none' } }}
        />

      </Tabs>
    </div>
  );
};

export default MobilePage;
