import React from 'react';
import { Typography, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { incrementCurrentNumberSubtopics, alertTopicDone } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import customLog from '../../../customLogger';

const PassedScreen = ({ onContinue, themeData }) => {
    const dispatch = useDispatch();
    const currentTopicID = useSelector((state) => state.chat.currentTopicID);
    const buttonStyle = {
        width: '120px',
        height: '36px',
        borderRadius: '20px',
        textTransform: 'none',
        marginRight: '10px',
    };

    const handleContinue = () => {
        customLog('HANDLECONTINUE PASSEDSCREEN CALLED');
        dispatch(incrementCurrentNumberSubtopics());
        dispatch(alertTopicDone({topicId: currentTopicID, stars: 3}));
        onContinue();
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            backgroundColor: 'transparent',
            borderRadius: '20px',
            padding: '20px',
        }}>
            <Typography variant="h4" style={{ color: themeData.color5, marginBottom: '20px' }}>
                Quiz Passed!
            </Typography>
            <Button
                variant="outlined"
                style={{
                    ...buttonStyle,
                    color: '#4CAF50',
                    borderColor: '#4CAF50',
                }}
                startIcon={<PlayArrowIcon />}
                onClick={handleContinue}
            >
                Continue
            </Button>
        </div>
    );
};

export default PassedScreen;
